import React, {useEffect} from "react"
import Slider from "react-slick";

let $ = window.$;
let settings = {
    dots: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    centerMode: true,
    centerPadding: "0px",
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ]
};

const Reviews = (props) => {
    let {size = 'lg'} = props;
    useEffect(() => {
        const initializeSlick = () => {
            try {
                $('.review-slide').not('.slick-initialized').slick({
                    dots: false,
                    speed: 300,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 1400,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
            } catch (e) {

            }
        };
        setTimeout(() => {
            initializeSlick();
        }, 10)
        return () => {
            if ($('.review-slide').hasClass('slick-initialized')) {
                $('.review-slide').slick('unslick'); // Destroy Slick instance
            }
        };
    }, []);

    return (
        <>
            <section className="review">
                <div className="container">
                    <div className={`col-lg-${size == "sm" ? '10' : '12'} col-12 mx-auto`}>
                        <div className="row row-gap-3 align-items-center">
                            <div className="col-lg-4 col-12">
                                <div className="star-box mb-lg-4 mb-3">
                                    <ul>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                    </ul>
                                </div>
                                <div className="head text-start">
                                    <h3 className="mb-2">Client Reviews</h3>
                                    <p className="m-0">We take pride in delivering exceptional service and memorable
                                        travel experiences. Here's what our clients have to say about their journeys
                                        with us:</p>
                                </div>
                            </div>
                            <div className="col-lg-8 col-12">
                                <div className="review-slide">
                                    <div>
                                        <div className="review-box">
                                              <span className="mb-3">
                                                <ul>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                </ul>
                                              </span>
                                            <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                nobis.</p>
                                            <a href="#">Read more...</a>
                                            <h3 className={'text-white'}>Arun K.</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="review-box">
                                              <span className="mb-3">
                                                <ul>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                </ul>
                                              </span>
                                            <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                nobis.</p>
                                            <a href="#">Read more...</a>
                                            <h3 className={'text-white'}>Arun K.</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="review-box">
                                          <span className="mb-3">
                                            <ul>
                                              <li><img src="../images/star.png" alt="star"/></li>
                                              <li><img src="../images/star.png" alt="star"/></li>
                                              <li><img src="../images/star.png" alt="star"/></li>
                                              <li><img src="../images/star.png" alt="star"/></li>
                                              <li><img src="../images/star.png" alt="star"/></li>
                                            </ul>
                                          </span>
                                            <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                nobis.</p>
                                            <a href="#">Read more...</a>
                                            <h3 className={'text-white'}>Arun K.</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="review-box">
                                              <span className="mb-3">
                                                <ul>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                  <li><img src="../images/star.png" alt="star"/></li>
                                                </ul>
                                              </span>
                                            <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                nobis.</p>
                                            <a href="#">Read more...</a>
                                            <h3 className={'text-white'}>Arun K.</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="review-box">
                                          <span className="mb-3">
                                            <ul>
                                              <li><img src="../images/star.png" alt="star"/></li>
                                              <li><img src="../images/star.png" alt="star"/></li>
                                              <li><img src="../images/star.png" alt="star"/></li>
                                              <li><img src="../images/star.png" alt="star"/></li>
                                              <li><img src="../images/star.png" alt="star"/></li>
                                            </ul>
                                          </span>
                                            <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                nobis.</p>
                                            <a href="#">Read more...</a>
                                            <h3 className={'text-white'}>Arun K.</h3>
                                        </div>
                                    </div>

                                </div>
                                <div className="text-end" style={{marginTop: '19px'}}>
                                    <a href="#" className="btn text-decoration-none text-white fw-500 btn-primary">View
                                        All</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Reviews
