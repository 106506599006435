import React from "react";
const Footer = () => {
    return (
        <>
            <footer className="footer mt-auto bg-white">
                <div className="container">

                    <div className="social-box py-lg-4 py-3">
                        <ul>
                            <li><a href="#"><span><img src="/images/facebook.png" alt="Facebook" /></span> Facebook</a></li>
                            <li><a href="#"><span><img src="/images/instagram.png" alt="Instagram" /></span> Instagram</a></li>
                            <li><a href="#"><span><img src="/images/linkedin.png" alt="Linkedin" /></span> Linkedin</a></li>
                        </ul>
                    </div>

                    <div className="py-lg-4 py-3">
                        <div className="row row-gap-3">
                            <div className="col-lg-3 col-md-6 col-12">
                                <h5>Quick Links</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0">Book Flights</a></li>
                                    <li className="nav-item mb-2"><a href="/booking.html" className="nav-link p-0">My Bookings</a></li>
                                    <li className="nav-item mb-2"><a href="/login.html" className="nav-link p-0">Login</a></li>
                                    <li className="nav-item mb-2"><a href="/register.html" className="nav-link p-0">Register</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 col-12">
                                <h5>Useful Links</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0">Terms & Conditions</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0">Privacy Policy</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0">Refund Policy</a></li>
                                    <li className="nav-item mb-2"><a href="#" className="nav-link p-0">Support 24 x 7</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 col-12">
                                <h5>Contact Us</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2">Email us at</li>
                                    <li className="nav-item mb-2">help@xtravelworld.com</li>
                                    <li className="nav-item mb-2">Helpline Number</li>
                                    <li className="nav-item mb-2">8888 6356 5654</li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 col-6 mx-auto">
                                <img src="/images/logo-black.png" alt="XTravelWorld Logo" className="footer-logo"/>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between py-4 my-4 border-top align-items-center">
                        <p className="m-0">© 2024 XTravelWorld, Inc. All rights reserved.</p>
                        <img src="/images/payment-mode.jpg" alt="payment-mode" style={{ maxHeight: '35px' }} />
                    </div>

                </div>
            </footer>
        </>
    );
};

export default Footer;
