import _ from "lodash";
import {Input, notification, PhoneNo} from "../../../components/Elements";
import React, {useEffect, useState} from "react";
import {daysArr, genderArr, monthArr, nationalityArr, titleArr, yearArr} from "../../../components/utils/appUtils";
import {CustomFormFields} from "../../../components/utils/customFormUtils";
import ArrowUp from '../../../assets/img/arrowUp.png'
import AncillaryServiceForm from "./addAncillaryForm";
import {useLocation} from "react-router-dom";
import {ticketBySessionIdFxn} from "../../flightBooking/actions";
import {useDispatch} from "react-redux";
import moment from "moment";

let initState = {
    title: undefined,
    firstName: "",
    gender: undefined,
    day: undefined,
    month: undefined,
    year: undefined,
    Nationality: "",
    PassportNo: "",
    PassportExpiry: "",
    ancillaryService: {}
}

let initGstState = {
    "gstCompanyAddress": "",
    "gstCompanyContactNumber": "",
    "gstCompanyName": "",
    "gstNumber": "",
    "gstCompanyEmail": ""
}

const contactInitState = {
    email: "",
    contactNo: ""
}
const SinglePassengerComponent = (props) => {
    let {data = initState, index, passengerType, onChange, fareObject} = props;
    let [state, setState] = useState({});
    useEffect(() => {
        setState((prevState) => {
            return {
                ...prevState,
                ...data
            }
        });
    }, []);

    const passengerField = [
        {
            key: "title",
            type: "select",
            placeholder: "Choose Title *",
            required: true,
            options: titleArr,
            span: "col-md-2",
            keyAccessor: (option) => option.name,
            valueAccessor: (option) => option.name,
        },
        {
            key: "firstName",
            placeholder: "First Name *",
            required: true,
            span: "col-md-5",
        },
        {
            key: "lastName",
            placeholder: "Last Name *",
            required: true,
            span: "col-md-5",
        },
        {
            key: "gender",
            type: "select",
            placeholder: "Gender *",
            required: true,
            options: genderArr,
            span: "col-md-6",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.name,
        },
        {
            key: "day",
            type: "select",
            placeholder: "Day *",
            required: true,
            span: "col-md-2",
            showSearch: true,
            options: daysArr(),
        },
        {
            key: "month",
            type: "select",
            placeholder: "Month *",
            required: true,
            span: "col-md-2",
            showSearch: false,
            options: monthArr,
            keyAccessor: (option) => option.name,
            valueAccessor: (option) => option.value,
        },
        {
            key: "year",
            type: "select",
            placeholder: "Year *",
            required: true,
            showSearch: true,
            span: "col-md-2",
            options: yearArr(passengerType),
        },
        {
            key: "Nationality",
            placeholder: "Nationality",
            type: "select",
            required: true,
            span: "col-md-6",
            options: nationalityArr,
            showSearch: true,
            keyAccessor: (option) => option.nationality,
            valueAccessor: (option) => option.nationality,
            hidden: !(fareObject && fareObject['IsPassportRequiredAtTicket'])
        },
        {
            key: "PassportNo",
            placeholder: "Passport Number",
            type: "text",
            required: true,
            span: "col-md-6",
            hidden: !(fareObject && fareObject['IsPassportRequiredAtTicket'])
        },
        {
            key: "PassportExpiry",
            type: "dateOfBirth",
            placeholder: "Expiry Date",
            required: true,
            span: "col-md-6",
            hidden: !(fareObject && fareObject['IsPassportRequiredAtTicket'])
            // format: "YYYY-MM-DD",
        }
    ];

    const passEvents = {
        _updateState: (obj) => {
            setState((prevState) => ({
                ...prevState,
                ...obj,
            }));
        },
        onChange: (dataObj, fieldName) => {
            if (fieldName === "title") {
                let findGender = _.find(titleArr, (item) => item.name === dataObj["title"]);
                if (findGender) {
                    dataObj["gender"] = findGender.gender;
                }
                passEvents._updateState(dataObj);
            } else {
                passEvents._updateState(dataObj);
            }
        },
    };

    useEffect(() => {
        onChange(state);
    }, [state]);

    let customId = `${passengerType}-${index + 1}`;
    return (
        <div className="accordion-item" key={customId}>
            <h2 className="accordion-header" id={`${customId}-headingOne`}>
                <ul>
                    <li className="p-3 head-accordion">
                        <h6 className={"text-capitalize"}>
                            {passengerType} {index + 1}
                        </h6>
                        <a
                            href="#"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${customId}-collapseOne`}
                            aria-expanded="true"
                            aria-controls={`${customId}-collapseOne`}
                            className="btn p-0 collapsed"
                        >
                            <img src={ArrowUp} alt="arrow-up" width={28}/>
                        </a>
                    </li>
                </ul>
            </h2>
            <div
                id={`${customId}-collapseOne`}
                className="accordion-collapse collapse show"
                aria-labelledby={`${customId}-headingOne`}
            >
                <div className="accordion-body">
                    <div className="row row-gap-3">
                        {passengerField.map((item) => {
                            let id = `${item.key}-${index}-${passengerType}`;
                            return (
                                !item.hidden ? <div className={item.span} key={id}>
                                    <CustomFormFields
                                        item={{
                                            size: 'small',
                                            ...item, passengerType: passengerType,
                                            required: item.required,
                                            id: id, name: id,
                                            value: state && state[item.key] ? state[item.key] : "",
                                            onChange: (value) => {
                                                passEvents.onChange({[item.key]: value}, item.key)
                                            }
                                        }}/>
                                </div> : null
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

const Passengers = (props) => {
    let {flightObj, fareObject, callbackState, traceId, selectedFlight, ssrResponse} = props;
    const [childPassengers, setChildPassengers] = useState([]);
    const [adultPassengers, setAdultPassengers] = useState([]);
    const [infantPassengers, setInfantPassengers] = useState([]);
    const [state, setState] = useState(contactInitState);
    let [gstState, setGstState] = useState(initGstState);
    const [ancillaryData, setAncillaryData] = useState(null);
    const location = useLocation();

    const stateParams = location.state?.params;

    const dispatch = useDispatch();

    useEffect(() => {
        events.loadInvoiceData()
    }, [stateParams])

    const gstFields = [
        {
            key: "gstNumber",
            placeholder: "GST Number",
            required: true,
            span: "col-md-6",
        },
        {
            key: "gstCompanyName",
            placeholder: "Company Name",
            required: true,
            span: "col-md-6",
        },
        {
            key: "gstCompanyAddress",
            placeholder: " Company Address",
            required: true,
            span: "col-md-6",
        },
        {
            key: "gstCompanyContactNumber",
            placeholder: "Company Contact Number",
            required: true,
            span: "col-md-6",
        },
        {
            key: "gstCompanyEmail",
            placeholder: "Company Email",
            required: true,
            span: "col-md-6",
        },
    ]

    const events = {
        updateAdultPassenger: (data, index) => {
            let cloneD = _.clone(adultPassengers);
            cloneD[index] = {...cloneD[index], ...data}
            setAdultPassengers(cloneD)
        },
        updateChildPassenger: (data, index) => {
            let cloneD = _.clone(childPassengers);
            cloneD[index] = {...cloneD[index], ...data}
            setChildPassengers(cloneD)
        },
        updateInfantPassengers: (data, index) => {
            let cloneD = _.clone(infantPassengers);
            cloneD[index] = {...cloneD[index], ...data}
            setInfantPassengers(cloneD)
        },
        updateGstState: (data) => {
            setGstState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        sendForReview: (e) => {
            e.preventDefault();

            let allPassenger = [...adultPassengers, ...childPassengers, ...infantPassengers];

            let findE = _.find(allPassenger, (item) => {
                return !item.title && !item.firstName && !item.lastName && !item.gender && !item.day && !item.month && !item.year;
            });

            if (findE) {
                notification.error({message: "Please enter all required fields."});
                return;
            }
            allPassenger.forEach((passenger, index) => {
                if (!passenger.firstName || passenger.firstName.length < 2) {
                    notification.error({ message: `First Name for Passenger ${index + 1} must be at least 2 characters long.` });
                    return;
                }
                if (!passenger.lastName || passenger.lastName.length < 2) {
                    notification.error({ message: `Last Name for Passenger ${index + 1} must be at least 2 characters long.` });
                    return;
                }
            });
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!state.email || !emailPattern.test(state.email)) {
                notification.error({ message: "Please enter a valid email address." });
                return;
            }

            if (!state.contactNo) {
                notification.error({message: "Please enter contact no."});
                return;
            }

            allPassenger.forEach((passenger, index) => {
                const ancillaryService = ancillaryData?.find((ancillary) => {
                    return ancillary.firstName === passenger.firstName && ancillary.lastName === passenger.lastName;
                });

                if (ancillaryService) {
                    passenger.ancillaryService = {
                        seat: ancillaryService.ancillaryService?.seat,
                        baggage: ancillaryService.ancillaryService?.baggage,
                        meal: ancillaryService.ancillaryService?.meal,
                        specialServices: ancillaryService.ancillaryService?.specialServices || [],
                    };
                }
            });

            setAdultPassengers(allPassenger.filter((passenger) => passenger.gender === 2));
            setChildPassengers(allPassenger.filter((passenger) => passenger.gender === 1));
            setInfantPassengers(allPassenger.filter((passenger) => passenger.gender === 0));

            callbackState({
                childPassengers,
                adultPassengers,
                infantPassengers,
                state: state,
                gstState,

            });
        },
        loadInvoiceData: async () => {
            if (stateParams && stateParams.sessionId) {
                let {success, data} = await dispatch(ticketBySessionIdFxn({sessionId: stateParams.sessionId}));
                if (success) {
                    let passengers = data['Passengers'];
                    let contactInfo = {}
                    let adultPass = []
                    let childPass = []
                    let infantPass = []
                    _.each(passengers, (item) => {
                        let obj = {}
                        obj.title = item['Title']
                        obj.firstName = item['FirstName']
                        obj.lastName = item['LastName']
                        obj.gender = item['Gender']
                        if (item['DateOfBirth']) {
                            obj.day = moment(item['DateOfBirth']).day();
                            obj.month = moment(item['DateOfBirth']).month();
                            obj.year = moment(item['DateOfBirth']).year();
                        }
                        if (!contactInfo.email) {
                            contactInfo['email'] = item['Email']
                        }
                        if (!contactInfo.contactNo) {
                            contactInfo['contactNo'] = item['ContactNo']
                        }
                        if (item['PaxType'] == 1) {
                            adultPass.push(obj);
                        }
                        if (item['PaxType'] == 2) {
                            childPass.push(obj);
                        }
                        if (item['PaxType'] == 3) {
                            infantPass.push(obj);
                        }
                    })

                    console.log(adultPass, 'adultPass');
                    setAdultPassengers(adultPass)
                    setChildPassengers(childPass)
                    setInfantPassengers(infantPass)
                }
            }
        }
    }

    return (
        <>
            <form onSubmit={events.sendForReview}>
                <div className="passanger-details">
                    <div className={"track-flights"} style={{overFlow: 'hidden'}}>
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div key={adultPassengers}>
                                {_.times(flightObj.adultCount, (adItem) => {
                                    return (
                                        <>
                                            <SinglePassengerComponent
                                                data={adultPassengers[adItem]}
                                                fareObject={fareObject}
                                                index={adItem}
                                                onChange={(data) => {
                                                    events.updateAdultPassenger(data, adItem)
                                                }}
                                                passengerType={'adult'} key={`adult-${adItem}`}/>
                                        </>
                                    )
                                })}
                            </div>
                            {_.times(flightObj.childCount, (adItem) => {
                                return (
                                    <>
                                        <SinglePassengerComponent
                                            data={childPassengers[adItem]}
                                            fareObject={fareObject}
                                            index={adItem}
                                            onChange={(data) => {
                                                events.updateChildPassenger(data, adItem)
                                            }}
                                            passengerType={'child'} key={`child-${adItem}`}/>
                                    </>
                                )
                            })}
                            {_.times(flightObj.infantCount, (adItem) => {
                                return (
                                    <>
                                        <SinglePassengerComponent
                                            data={infantPassengers[adItem]}
                                            index={adItem}
                                            onChange={(data) => {
                                                events.updateInfantPassengers(data, adItem)
                                            }}
                                            passengerType={'infant'} key={`infant-${adItem}`}/>
                                    </>
                                )
                            })}

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                    <ul>
                                        <li className="p-3 head-accordion">
                                            <h6>Contact Details</h6>
                                            <div className="d-flex gap-3">

                                                <a
                                                    href=""
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                    className="btn p-0 collapsed"
                                                >
                                                    <img src={ArrowUp}
                                                         alt="arrow-up" width={28}/>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseTwo"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="panelsStayOpen-headingTwo">
                                    <div className="accordion-body">

                                        <div className="row row-gap-3">
                                            <div className="col-lg-6">
                                                <PhoneNo
                                                    type={'number'}
                                                    size={'small'}
                                                    placeholder={'Contact No'}
                                                    required={true}
                                                    value={state.contactNo}
                                                    onChange={(value) => {
                                                        events._updateState({contactNo: value})
                                                    }}
                                                />
                                            </div>

                                            <div className="col-lg-6">
                                                <Input
                                                    type={'text'}
                                                    placeholder={'Email address'}
                                                    value={state.email}
                                                    size={'small'}
                                                    required={true}
                                                    onChange={(value) => {
                                                        events._updateState({email: value})
                                                    }}
                                                />

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                    <ul>
                                        <li className="p-3 head-accordion">
                                            <h6>Ancillary Services</h6>
                                            <div className="d-flex gap-3">

                                                <a
                                                    href=""
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                    className="btn p-0 collapsed"
                                                >
                                                    <img src={ArrowUp}
                                                         alt="arrow-up" width={28}/>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseThree"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="panelsStayOpen-headingThree">
                                    <div className="accordion-body">

                                        <AncillaryServiceForm
                                            fareObj={fareObject}
                                            ssrResponse={ssrResponse}
                                            adultPassengers={adultPassengers}
                                            childPassengers={childPassengers}
                                            infantPassengers={infantPassengers}
                                            selectedFlight={selectedFlight}
                                            updateAncillaryServices={(resp) => {
                                                setAncillaryData(resp)
                                            }}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                    <ul>
                                        <li className="p-3 head-accordion">
                                            <h6>GST Number for Business Travel (Optional)</h6>
                                            <div className="d-flex gap-3">
                                                <button className="btn bg-transparent p-0 active">
                                                    Save GST Details
                                                </button>
                                                <a
                                                    href=""
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                    className="btn p-0 collapsed"
                                                >
                                                    <img src={ArrowUp} alt="arrow-up" width={28}/>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseThree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="panelsStayOpen-headingThree"
                                >
                                    <div className="accordion-body">
                                        <form>
                                            <div className="row row-gap-3">
                                                {gstFields.map((item) => {
                                                    return (
                                                        !item.hidden ? <div className={item.span} key={item.key}>
                                                            <CustomFormFields
                                                                item={{
                                                                    size: 'small',
                                                                    ...item,
                                                                    value: gstState && gstState[item.key] ? gstState[item.key] : "",
                                                                    onChange: (value) => {
                                                                        events.updateGstState({[item.key]: value})
                                                                    }
                                                                }}/>
                                                        </div> : null
                                                    );
                                                })}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <button className="btn btn-primary w-100 mt-3">PROCEED TO REVIEW</button>
            </form>
        </>
    )
}
export default Passengers
