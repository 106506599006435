import React from "react"

const Card = (props) => {
    let {
        title,
        children,
        footer,
        bodyClass = "",
        headerClass = "",
        footerClass = "",
        round = 5,
        extra = null,
        className = ''
    } = props;
    return (
        <div className={`card ${className}`} style={{borderRadius: round}}>
            {title || extra ? <div className={`card-header d-flex justify-content-between ${headerClass}`}>
                {title ? <div>{title}</div> : ""}
                {extra ? <div>{extra}</div> : ""}
            </div> : null}
            <div className={`card-body ${bodyClass}`}>
                {children}
            </div>
            {footer ?
                <div className={`card-footer ${footerClass}`}>
                    {children}
                </div> : null}
        </div>
    )
}
export default Card
