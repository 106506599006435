import React, {useEffect} from "react"

let $ = window.$;

const FlightSlider = () => {
    useEffect(() => {
        const initializeSlick = () => {
            try {
                $('.clients-box-slide').not('.slick-initialized').slick({
                    dots: false,
                    speed: 300,
                    slidesToShow: 10,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false,
                    responsive: [
                        {
                            breakpoint: 1400,
                            settings: {
                                slidesToShow: 10,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
            } catch (e) {

            }
        };

        setTimeout(() => {
            initializeSlick();
        }, 10)


        return () => {
            if ($('.clients-box-slide').hasClass('slick-initialized')) {
                $('.clients-box-slide').slick('unslick'); // Destroy Slick instance
            }
        };
    }, []);


    return (
        <>

            <section className="clients pt-4">
                <div className="container">
                    <div className="clients-box-slide p-4">
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                        <div>
                            <img
                                src="images2/Qatar_Airways_Logo.png"
                                alt="Qatar_Airways_Logo"
                            />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default FlightSlider
