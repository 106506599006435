import React from "react";
import UserIcon from '../../../assets/img/user-icon.png'

const SinglePassCard = (props) => {
    let {title, index, data, state} = props;

    return (
        <>
            <ul className={'pass-grid'}>
                <li>
                    <img src={UserIcon} alt={'icon'} style={{ height: 16, marginRight: 5 }}/>  <strong>{title} {index + 1}</strong>
                </li>
                <li>
                    <b>Name</b> : {data && data.firstName && data.lastName ? `${data.firstName} ${data.lastName}` : ""}<br/>
                    <b>Gender</b> : {data && data.gender == 2 ? "Female" : "Male"}<br/>
                    <b>Contact No</b> : +{state && state.contactNo ? state.contactNo : ""}
                </li>
                <li>
                    <b>D.O.B</b> : {data && data.day && data.month && data.year ? `${data.day}-${data.month}-${data.year}` : ""}<br/>
                    <b>Email ID</b> : {state && state.email ? state.email : ""}<br/>
                </li>
            </ul><hr/>
        </>

    )
}

export default SinglePassCard
