import React from "react"
import {Modal} from "../../components/Elements";

const ChangePrice = (props) => {
    let {visible, onClose, oldFare, newFare, quoteFare, onSubmit} = props;
    return (
        <Modal visible={visible} width={'35%'} onClose={onClose}
               maskClosable={false}
               closable={false}
               title={'Flight details have been changed, Please verify the changed Price before proceed to booking. '}>
            <table className={'table table-border'}>
                <tr>
                    <th></th>
                    <th>Published Fare</th>
                    <th>Offered Fare</th>
                </tr>
                <tr>
                    <td>New Price</td>
                    <td>{newFare['PublishedFare']}</td>
                    <td>{newFare['OfferedFare']}</td>
                </tr>
                <tr>
                    <td>Old Price</td>
                    <td>{oldFare['PublishedFare']}</td>
                    <td>{oldFare['OfferedFare']}</td>
                </tr>

                <tr>
                    <td>(* TDS Included)</td>
                    <td></td>
                    <td></td>
                </tr>
            </table>
            <br/>
            <div>Do you want to proceed further?</div>
            <div className={'align-center mt-3'}>
                <a className={'btn btn-primary-theme'} onClick={() => onSubmit(quoteFare)}>Accept</a>
                <a className={'btn btn-primary-theme m-lg-2'}>Choose Author Fare</a>
            </div>
        </Modal>
    )
}
export default ChangePrice
