import React, {useEffect} from "react";
import Slider from "react-slick";
import Reviews from "../components/pageComponents/reviews";
import DownloadApplications from "../components/pageComponents/downloadApplications";
import RegisterBanner from "../components/pageComponents/registerBanner";
import {useLocation} from 'react-router-dom';

const Home = (props) => {

    return (
        <>


            <section className="why-choose spacing-60">
                {/*<img src="../images/why-choose-banner.png" alt="Why Choose Banner"/>*/}
                <div className="container position-relative">
                    <div className="head text-center mb-5">
                        <h3>Why Choose X Travel World?</h3>
                    </div>
                    <div className="col-lg-9 col-12 mx-auto">
                        <div className="row row-gap-4">
                            <div className="col-lg-4 col-12">
                                <div className="content-box">
                                        <span><img src="../images/why-choose-icon-1.png"
                                                   alt="Exclusive Deals Icon"/></span>
                                    <h4>Exclusive Deals</h4>
                                    <p className="m-0">Access to exclusive wholesale flight deals</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="content-box">
                                        <span><img src="../images/why-choose-icon-2.png"
                                                   alt="Real-Time Booking Icon"/></span>
                                    <h4>Real-Time Booking</h4>
                                    <p className="m-0">Book flights in real-time with instant
                                        confirmation</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="content-box">
                                        <span><img src="../images/why-choose-icon-3.png"
                                                   alt="24/7 Support Icon"/></span>
                                    <h4>24/7 Support</h4>
                                    <p className="m-0">Dedicated customer support to assist you anytime</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="note-banner pt-lg-4 pt-3">
                <div className="container">
                    <h3 className="m-0">Trusted by thousands. Your Journey Starts Here!</h3>
                </div>
            </section>

            <section className="agent-with-us spacing-60 pb-0">
                <div className="container">
                    <div className="head text-center mb-5">
                        <h3>How to Register as an Agent with us?</h3>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="grid">
                                <div className="box p-lg-4 p-3 g-col-12 mb-3">
                                    <span><img src="../images/agent-icon-1.png" alt="Signup Icon"/></span>
                                    <div className="content-box">
                                        <h4>Signup as a Travel Agent</h4>
                                        <p>Locate the "Sign Up" button at the top right corner of the page and click
                                            on it.</p>
                                    </div>
                                </div>
                                <div className="box p-lg-4 p-3 g-col-12 mb-3">
                                        <span><img src="../images/agent-icon-2.png"
                                                   alt="Registration Form Icon"/></span>
                                    <div className="content-box">
                                        <h4>Fill Out the Registration Form</h4>
                                        <p>Complete the registration form with your details.</p>
                                    </div>
                                </div>
                                <div className="box p-lg-4 p-3 g-col-12 mb-3">
                                        <span><img src="../images/agent-icon-3.png"
                                                   alt="Submit Application Icon"/></span>
                                    <div className="content-box">
                                        <h4>Submit Your Application</h4>
                                        <p>Click the "Submit" button to send your registration form.</p>
                                    </div>
                                </div>
                                <div className="box p-lg-4 p-3 g-col-12">
                                    <span><img src="../images/agent-icon-4.png" alt="Start Booking Icon"/></span>
                                    <div className="content-box">
                                        <h4>Start Booking</h4>
                                        <p>Use your credentials to log in, access exclusive deals, and start booking
                                            flights instantly!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 position-sticky" style={{top: "150px"}}>
                            <div id="container" className="text-center">
                                <div className="item">
                                    <img src="../images/logo-blue.png" alt="Logo"/>
                                </div>
                                <div className="circle"></div>
                                <div className="circle" style={{animationDelay: "0s"}}></div>
                                <div className="circle" style={{animationDelay: "1s"}}></div>
                                <div className="circle" style={{animationDelay: "2s"}}></div>
                                <div className="circle" style={{animationDelay: "3s"}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <DownloadApplications/>

            <Reviews size={'sm'}/>
            <RegisterBanner/>
        </>
    );
};

export default Home;
