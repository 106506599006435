import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {singleTicketFxn} from "../flightBooking/actions";
import {useLocation, useNavigate} from 'react-router-dom'
import SuccessPage from "./components/success";
import ErrorPage from "./components/error";
import moment from "moment";

const flightDate = (date) => {
    return moment(date).format("dddd, MMMM, Do, YYYY");
};

const TicketResponse = () => {
    const location = useLocation();
    const { params, data, passengerState } = location.state || {};
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("sessionId");

    let [response, setResponse] = useState({})
    let [passengers, setPassengers] = useState({})
    const {flightObj, traceId, selectedFlight, fareQuoteObj} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj,
        traceId: state.flightsReducer.traceId,
        selectedFlight: state.flightsReducer.selectedFlight,
        // fareQuoteObj: state.flightsReducer.fareQuoteObj,
    }))
    let navigate = useNavigate()
    // console.log(passenger, "00000")
    let ticketId = window.location.pathname.split('/').pop();

    const dispatch = useDispatch();
    useEffect(() => {
        if (!data) {
            loadData(); // Only load data if it isn't already passed via location.state
        }
    }, []);

    const loadData = async () => {
        let {data, success} = await dispatch(singleTicketFxn({ticketId}))
        if (data && data.response) {
            setResponse(data.response);
            setPassengers(data['Passengers'])
        } else if (data.bookingResponse) {
            setResponse(data.bookingResponse)
        }
    };

    return (
        <>
            {data ? (
                <SuccessPage response={data.response} ticketId={ticketId} sessionId={sessionId} />
            ) : response['ResponseStatus'] === 1 ? (
                <SuccessPage response={response} passengers={passengers} ticketId={ticketId} sessionId={sessionId} />
            ) : (
                <ErrorPage response={response} ticketId={ticketId} />
            )}
        </>
    );
};

export default TicketResponse
