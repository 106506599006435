import React, {useEffect, useState, useCallback} from "react";
import {useDispatch} from "react-redux";
import {getUser} from "../authications/action";
import moment from "moment";
import {passengerTypeObject} from "../../components/utils/appUtils";
import {singleTicketFxn} from "../flightBooking/actions";

const ETicket = () => {
    let dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [passengerDetails, setPassengerDetails] = useState(null);
    const [pnr, setPnr] = useState();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            const storedUser = localStorage.getItem("user");
            if (storedUser) {
                const user = JSON.parse(storedUser);
                const userData = await dispatch(getUser(user._id));
                if (userData) {
                    setUser(userData);
                }
            }
        };
        fetchUserData();
    }, [dispatch]);


    const loadData = async (params) => {
        const queryParams = new URLSearchParams(window.location.search);
        const ticketId = queryParams.get('ticketId');
        let {data} = await dispatch(singleTicketFxn({ticketId}))
        if (data && data.response) {
            setData(data.response.Response.FlightItinerary);
            setPnr(data.response.Response.PNR);
            const passengers = data.response.Response.FlightItinerary.Passenger.length > 0 && data.response.Response.FlightItinerary.Passenger;
            const passenger = passengers && passengers.find(p =>
                (p.FirstName).toLowerCase() == (params.FirstName).toLowerCase() &&
                (p.LastName).toLowerCase() == (params.LastName).toLowerCase()
            );
            if (passenger) {
                setPassengerDetails(passenger)
            }
        }
    }

    useEffect(() => {
        const params = {};
        const urlParams = new URLSearchParams(window.location.search);
        params.bookingId = urlParams.get("bookingId");
        params.PNR = urlParams.get("pnr");
        params.FirstName = urlParams.get("firstName")
        params.LastName = urlParams.get("lastName")
        params.traceId = urlParams.get("traceId");
        // if (params.PNR) {
        //     setPnr(params.PNR);
        // }
        // if (params) {
        //     fetchBookingDetails(params);
        // }

        loadData(params)
    }, []);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
            }}
        >
            <div>
                <title>E-Ticket</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=2.0"
                />
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html: ``,
                    }}
                />
                <form method="post" action="FlightEmailSend.aspx" id="form1">
                    <div className="aspNetHidden">
                        <input
                            type="hidden"
                            name="__VIEWSTATE"
                            id="__VIEWSTATE"
                            defaultValue="demo_viewstate"
                        />
                    </div>
                    <div className="aspNetHidden">
                        <input
                            type="hidden"
                            name="__VIEWSTATEGENERATOR"
                            id="__VIEWSTATEGENERATOR"
                            defaultValue="demo_generator"
                        />
                    </div>
                    <input
                        type="hidden"
                        id="switchToNewEticketPage"
                        name="switchToNewEticketPage"
                        defaultValue="True"
                    />
                    <table
                        cellPadding={0}
                        cellSpacing={0}
                        border={0}
                        bgcolor="#cccccc"
                        style={{width: "100%"}}
                    >
                        <tbody>
                        <tr className="printborder">
                            <td style={{padding: "20px 20px 15px"}}>
                                <table
                                    cellPadding={0}
                                    cellSpacing={0}
                                    border={0}
                                    align="center"
                                    bgcolor="#ffffff"
                                    style={{
                                        width: "1000px",
                                        fontFamily: "Arial, Verdana, Helvetica, sans-serif",
                                        fontSize: "14px",
                                        color: "#000",
                                        border: 0,
                                        background: "#fff",
                                        borderCollapse: "collapse",
                                    }}
                                >
                                    <tbody>
                                    <tr className="printborder">
                                        <td style={{padding: "25px"}}>
                                            <table
                                                cellPadding={0}
                                                cellSpacing={0}
                                                border={0}
                                                style={{width: "100%"}}
                                            >
                                                <tbody>
                                                <tr className="printborder">
                                                    <td
                                                        style={{
                                                            border: "1px solid #dbdada",
                                                            padding: "20px",
                                                            paddingBottom: 0,
                                                        }}
                                                    >
                                                        <table
                                                            cellPadding={0}
                                                            cellSpacing={0}
                                                            border={0}
                                                            style={{width: "100%"}}
                                                        >
                                                            <tbody>
                                                            <tr className="printborder">
                                                                <td width="40%"></td>
                                                                <td
                                                                    width="60%"
                                                                    align="left"
                                                                    style={{
                                                                        verticalAlign: "baseline",
                                                                        fontSize: "28px",
                                                                    }}
                                                                >
                                                                    <b>E-Ticket</b>
                                                                </td>
                                                            </tr>
                                                            <tr className="printborder">
                                                                <td
                                                                    colSpan={3}
                                                                    style={{height: "10px"}}
                                                                />
                                                            </tr>
                                                            <tr className="printborder">
                                                                <td>
                                                                    <p
                                                                        style={{
                                                                            lineHeight: "23px",
                                                                            margin: 0,
                                                                        }}
                                                                    >
                                                                        <b style={{
                                                                            fontSize: "14px",
                                                                            fontWeight: 'bold'
                                                                        }}>
                                                                            X Travel World
                                                                        </b>
                                                                        <br/>
                                                                        <span>Chandigarh </span>
                                                                        <br/>
                                                                        Chandigarh, India
                                                                        <br/>
                                                                        <span>Contact No: 1800-102-0340</span>
                                                                    </p>
                                                                </td>
                                                                <td
                                                                    align="right"
                                                                    style={{verticalAlign: "bottom"}}
                                                                >
                                          <span>
                                            Issued Date:
                                              {data &&
                                              data.Invoice.length > 0 &&
                                              moment(data.Invoice[0].InvoiceCreatedOn).format('DD-MM-YYYY hh:mm a, ddd')}
                                          </span>
                                                                    <br/>
                                                                    <span>
                                            Airline Toll Free No: 1800-102-0340
                                          </span>
                                                                </td>
                                                            </tr>
                                                            <tr className="printborder">
                                                                <td colSpan="2">
                                                                    <table
                                                                        cellPadding="0"
                                                                        cellSpacing="0"
                                                                        border="0"
                                                                        align="center"
                                                                        bgcolor="#ffffff"
                                                                        style={{
                                                                            width: "100%",
                                                                            fontFamily:
                                                                                "Arial, Verdana, Helvetica, sans-serif",
                                                                            fontSize: "14px",
                                                                            color: "#000",
                                                                            background: "#ffffff",
                                                                            borderCollapse: "collapse",
                                                                            borderRadius: "6px",
                                                                            marginTop: "16px",
                                                                            border: "1px solid #dbdada",
                                                                        }}
                                                                    >
                                                                        <tbody>
                                                                        <tr className="printborder">
                                                                            <td>
                                                                                <table
                                                                                    cellPadding="0"
                                                                                    cellSpacing="0"
                                                                                    border="0"
                                                                                    align="left"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        fontFamily:
                                                                                            "Arial, Verdana, Helvetica, sans-serif",
                                                                                        fontSize: "14px",
                                                                                        color: "#000",
                                                                                        border: "0",
                                                                                        background: "#ffffff",
                                                                                        borderCollapse:
                                                                                            "collapse",
                                                                                    }}
                                                                                >
                                                                                    <tbody>
                                                                                    <tr className="printborder solidBorder themeBgColor">
                                                                                        <th
                                                                                            width="15%"
                                                                                            align="left"
                                                                                            style={{
                                                                                                backgroundColor: "#F0D39D#F0D39D",
                                                                                                padding: "8px",
                                                                                            }}
                                                                                        >
                                                                                            &nbsp;
                                                                                        </th>
                                                                                        <th
                                                                                            align="left"
                                                                                            className={
                                                                                                "solidBorder"
                                                                                            }
                                                                                        >
                                                                                            PNR No.
                                                                                        </th>
                                                                                        <th
                                                                                            width="15%"
                                                                                            align="left"
                                                                                        >
                                                                                            &nbsp;
                                                                                        </th>
                                                                                        <th
                                                                                            align="left"
                                                                                            className={
                                                                                                "solidBorder"
                                                                                            }
                                                                                        >
                                                                                            Departure
                                                                                        </th>
                                                                                        <th
                                                                                            align="left"
                                                                                            className={
                                                                                                "solidBorder"
                                                                                            }
                                                                                        >
                                                                                            Arrival
                                                                                        </th>
                                                                                    </tr>
                                                                                    {data &&
                                                                                    data.Segments.length > 0 &&
                                                                                    <tr
                                                                                        className="printborder"
                                                                                    >
                                                                                        <td
                                                                                            width="15%"
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                                padding: "8px",
                                                                                                paddingLeft: "10px",
                                                                                                whiteSpace: "nowrap",
                                                                                                color: "#575757",
                                                                                                fontSize: "13px",
                                                                                            }}
                                                                                        >
                                                                                            <div>
                                                                                                <img
                                                                                                    className="airlineLogoSize"
                                                                                                    src={`../airlineLogos/${
                                                                                                        data &&
                                                                                                        data.Segments &&
                                                                                                        data.Segments.length > 0 &&
                                                                                                        data.Segments[0].Airline &&
                                                                                                        data.Segments[0].Airline.AirlineCode &&
                                                                                                        data.Segments[0].Airline.AirlineCode
                                                                                                    }.gif`} alt=""/>
                                                                                            </div>
                                                                                            <div
                                                                                                className={'bold-text'}>
                                                                                                {data.Origin} - {data.Destination}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding:
                                                                                                    "8px",
                                                                                                color:
                                                                                                    "#575757",
                                                                                                fontSize:
                                                                                                    "13px",
                                                                                            }}
                                                                                        >
                                                                                            <b className={'fw-bold'}>
                                                                                                {data && data.ValidatingAirlineCode && `${data.ValidatingAirlineCode} -`}
                                                                                                {data && data.PNR && ` ${data.PNR}`}
                                                                                            </b>
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: "12px",
                                                                                                color: "#575757",
                                                                                                fontSize: "13px",
                                                                                            }}
                                                                                        >
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: "8px",
                                                                                                color: "#575757",
                                                                                                fontSize: "13px",
                                                                                            }}
                                                                                        >
                                                                                            {moment(data.Segments[0].Origin.DepTime).format("DD-MMM-YYYY hh:mm a, ddd")}
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding:
                                                                                                    "6px",
                                                                                                color:
                                                                                                    "#575757",
                                                                                                fontSize:
                                                                                                    "13px",
                                                                                            }}
                                                                                        >
                                                                                            {moment(data.Segments[0].Destination.ArrTime).format("DD-MMM-YYYY hh:mm a, ddd")}

                                                                                        </td>
                                                                                    </tr>
                                                                                    }

                                                                                    <tr className="printborder">
                                                                                        <td
                                                                                            colSpan="5"
                                                                                            style={{
                                                                                                lineHeight: "0",
                                                                                                paddingInline:
                                                                                                    "10px",
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    height: "1px",
                                                                                                    background:
                                                                                                        "#D9E2F0",
                                                                                                    margin: "0",
                                                                                                }}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr className="printborder">
                                                                <td colSpan="2">
                                                                    <table
                                                                        cellPadding="0"
                                                                        cellSpacing="0"
                                                                        border="0"
                                                                        align="center"
                                                                        bgcolor="#ffffff"
                                                                        style={{
                                                                            width: "100%",
                                                                            fontFamily:
                                                                                "Arial, Verdana, Helvetica, sans-serif",
                                                                            fontSize: "14px",
                                                                            color: "#000",
                                                                            background: "#ffffff",
                                                                            borderCollapse: "collapse",
                                                                            borderRadius: "6px",
                                                                            marginTop: "16px",
                                                                            border: "1px solid #dbdada",
                                                                        }}
                                                                    >
                                                                        <tbody>
                                                                        <tr className="printborder">
                                                                            <td>
                                                                                <table
                                                                                    cellPadding="0"
                                                                                    cellSpacing="0"
                                                                                    border="0"
                                                                                    align="left"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        fontFamily:
                                                                                            "Arial, Verdana, Helvetica, sans-serif",
                                                                                        fontSize: "14px",
                                                                                        color: "#000",
                                                                                        border: "0",
                                                                                        background: "#ffffff",
                                                                                        borderCollapse:
                                                                                            "collapse",
                                                                                    }}
                                                                                >
                                                                                    <tbody>
                                                                                    <tr className="printborder solidBorder themeBgColor">
                                                                                        <th
                                                                                            align="left"
                                                                                            className={'solidBorder'}
                                                                                        >
                                                                                            Passenger Name
                                                                                        </th>
                                                                                        <th
                                                                                            align="left"
                                                                                            className={'solidBorder'}
                                                                                        >
                                                                                            Passenger Type
                                                                                        </th>
                                                                                        <th
                                                                                            align="left"
                                                                                            className={'solidBorder'}
                                                                                            className={'solidBorder'}
                                                                                        >
                                                                                            E-Ticket Number
                                                                                        </th>
                                                                                        <th
                                                                                            align="left"
                                                                                            className={'solidBorder'}
                                                                                        >
                                                                                            Frequent Flyer No.
                                                                                        </th>
                                                                                        <th
                                                                                            align="left"
                                                                                            className={'solidBorder'}
                                                                                        >
                                                                                            GST No.
                                                                                        </th>
                                                                                    </tr>
                                                                                    {passengerDetails &&
                                                                                    <tr
                                                                                        className="printborder"
                                                                                    >
                                                                                        <td
                                                                                            style={{
                                                                                                padding:
                                                                                                    "8px",
                                                                                                color:
                                                                                                    "#575757",
                                                                                                fontSize:
                                                                                                    "13px",
                                                                                            }}
                                                                                        >
                                                                                            <b className={'fw-bold'}>
                                                                                                {passengerDetails && passengerDetails.Title}&nbsp;
                                                                                                {passengerDetails && passengerDetails.FirstName}&nbsp;
                                                                                                {passengerDetails && passengerDetails.LastName}
                                                                                            </b>
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: "8px",
                                                                                                color: "#575757",
                                                                                                fontSize: "13px",
                                                                                            }}
                                                                                        >
                                                                                            {passengerDetails && passengerTypeObject[passengerDetails.PaxType] || 'Unknown'}
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding:
                                                                                                    "6px",
                                                                                                color:
                                                                                                    "#575757",
                                                                                                fontSize:
                                                                                                    "13px",
                                                                                            }}
                                                                                        >
                                                                                            {passengerDetails && passengerDetails.Ticket.ValidatingAirline}
                                                                                            {passengerDetails && passengerDetails.Ticket.TicketNumber}
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: "6px",
                                                                                                color: "#575757",
                                                                                                fontSize: "13px",
                                                                                            }}
                                                                                        >
                                                                                            {passengerDetails && passengerDetails.Ticket.FFNumber ?
                                                                                                passengerDetails.Ticket.FFNumber : '--'}
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: "6px",
                                                                                                color: "#575757",
                                                                                                fontSize: "13px",
                                                                                            }}
                                                                                        >
                                                                                            {passengerDetails && passengerDetails.Ticket.GSTNumber ?
                                                                                                passengerDetails.Ticket.GSTNumber : '--'}
                                                                                        </td>
                                                                                    </tr>
                                                                                    }
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <FlightDetailsTable data={data}
                                                                                passengerDetails={passengerDetails}/>
                                                            <FlightBaggageDetailsTable data={data}
                                                                                       passengerDetails={passengerDetails}/>

                                                            <TicketDetails passengerDetails={passengerDetails}/>
                                                            {/* ... other table rows ... */}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input
                        type="hidden"
                        id="hdnBookingId"
                        name="hdnBookingId"
                        defaultValue={1234567}
                    />
                    <input type="hidden" id="hPNR" name="hPNR" defaultValue="DEMO123"/>
                    <input
                        type="hidden"
                        id="hSource"
                        name="hSource"
                        defaultValue="DemoAirline"
                    />
                    <input type="hidden" id="hFName" name="hFName" defaultValue="John"/>
                    <input type="hidden" id="hLName" name="hLName" defaultValue="Doe"/>
                    <input
                        type="hidden"
                        id="hAgencyId"
                        name="hAgencyId"
                        defaultValue={12345}
                    />
                    <input
                        type="hidden"
                        id="traceId"
                        name="traceId"
                        defaultValue="demo-trace-id"
                    />
                    <input
                        type="hidden"
                        id="hdnallairlineid"
                        name="hdnallairlineid"
                        defaultValue="DEMO123"
                    />
                    <input
                        type="hidden"
                        id="hdnbookingrefid"
                        name="hdnbookingrefid"
                        defaultValue="PNR (DEMO123)"
                    />
                    <input
                        type="hidden"
                        id="hdnDepDateTime"
                        name="hdnDepDateTime"
                        defaultValue="01 Jan 2025 at 10:00 AM"
                    />
                    <input
                        type="hidden"
                        id="hdnAllSectors"
                        name="hdnAllSectors"
                        defaultValue="CITY-COUNTRY"
                    />
                    <input
                        type="hidden"
                        id="hdnleadpassengername"
                        name="hdnleadpassengername"
                        defaultValue="Mr John Doe"
                    />
                    <input
                        type="hidden"
                        id="hdntripcompletiontimeinmin"
                        name="hdntripcompletiontimeinmin"
                        defaultValue="120"
                    />
                    <div className="fadeBg" style={{display: "none"}}/>
                    <div id="emailBlock" style={{display: "none"}}>
                        <div className="emailPopup">
                            <div className="closebtn">
                                <svg
                                    id="emailClose"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={15}
                                    height={15}
                                    viewBox="0 0 15 15"
                                    fill="none"
                                >
                                    <path
                                        d="M5 4L11.25 10.25M5 10.25L11.25 4"
                                        stroke="black"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                            <div className="d-flex w-100">
                                <span id="emailMsg"/>
                            </div>
                            <div className="d-flex w-100">
                                <span className="input_label"> Enter email address :</span>
                            </div>
                            <div className="d-flex w-100">
                                <input
                                    className="input_value w-100"
                                    id="addressBox"
                                    name="addressBox"
                                    type="text"
                                />
                            </div>
                            <div className="d-flex w-100 align-items-center gap-8 justify-content-end">
                                <input
                                    id="emailCancel"
                                    type="button"
                                    defaultValue="Cancel"
                                    className="btn"
                                />
                                <input
                                    id="sendEmail"
                                    type="button"
                                    defaultValue="Send mail"
                                    className="btn mainbtn"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        id="whatsappblock"
                        className="display-none"
                        style={{display: "none"}}
                    >
                        <div className="emailPopup">
                            <div className="closebtn">
                                <svg
                                    id="whatsappclose"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={15}
                                    height={15}
                                    viewBox="0 0 15 15"
                                    fill="none"
                                >
                                    <path
                                        d="M5 4L11.25 10.25M5 10.25L11.25 4"
                                        stroke="black"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                            <div className="d-flex w-100">
                                <span id="whatsappmsg"/>
                            </div>
                            <div>
                <span className="input_label">
                  {" "}
                    Enter WhatsApp Mobile Number
                </span>
                            </div>
                            <div className="d-flex w-100 gap-8">
                                <input
                                    name="countryCode"
                                    type="text"
                                    defaultValue={+91}
                                    readOnly="readonly"
                                    className="input_value"
                                    style={{width: "32px"}}
                                />
                                <input
                                    id="whatsappaddressbox"
                                    name="whatsappaddressbox"
                                    type="text"
                                    className="input_value w-100"
                                />
                            </div>
                            <div className="d-flex w-100 align-items-center gap-8 justify-content-end">
                                <input
                                    id="whatsappcancel"
                                    type="button"
                                    defaultValue="Cancel"
                                    className="btn"
                                />
                                <input
                                    id="sendwhatsapp"
                                    type="button"
                                    defaultValue="Send"
                                    className="btn mainbtn"
                                />
                            </div>
                            <div
                                className="d-flex w-100"
                                style={{
                                    paddingTop: "10px",
                                    color: "red",
                                    fontFamily: "roboto, sans-serif",
                                    fontSize: "11px",
                                }}
                            >
                                <strong>Disclaimer:</strong> WhatsApp messages will be sent from
                                a Demo Business account.
                            </div>
                        </div>
                    </div>
                </form>
                <form id="frmTA" action="TAPDF.aspx" method="post">
                    <input
                        type="hidden"
                        name="DestinationPDF"
                        id="DestinationPDF"
                        defaultValue
                    />
                    <input type="hidden" name="cityID" id="cityID" defaultValue="CITY"/>
                    <input type="hidden" name="cityName" id="cityName" defaultValue/>
                </form>
            </div>
        </div>
    );
};

const FlightDetail = ({flight}) => (
    <tr className="printborder">
        <td style={{padding: "10px 8px", verticalAlign: "baseline"}}>
            <table
                cellpadding="0"
                cellspacing="0"
                border="0"
                align="left"
                style={{
                    width: "100%",
                    fontFamily: "Arial, Verdana, Helvetica, sans-serif",
                    fontSize: "14px",
                    color: "#000",
                    border: 0,
                    borderCollapse: "collapse",
                }}
            >
                <tbody>
                <tr>
                    <td width="40px" style={{verticalAlign: "baseline"}}>
                        <div>
                            <img
                                className="airlineLogoSize"
                                src={`../airlineLogos/${
                                    flight &&
                                    flight.Airline &&
                                    flight.Airline.AirlineCode &&
                                    flight.Airline.AirlineCode
                                }.gif`} alt=""/>
                        </div>
                    </td>
                    <td style={{
                        whiteSpace: "nowrap", color: "#000",
                    }}>
                        <b className={'fw-bold'}>{`${flight.Airline.AirlineName} ${flight.Airline.AirlineCode} ${flight.Airline.FlightNumber}`}</b>
                        <p
                            style={{
                                marginBottom: "0",
                                marginTop: "6px",
                                fontSize: "13px",
                                lineHeight: "21px",
                                color: "#575757",
                            }}
                        >
                            <span>Class: {flight.Airline.FareClass}</span>
                            <br/>
                            <span>Aircraft: {flight.Craft}</span>
                            <br/>
                            {
                                flight && flight.operatingCarrier &&
                                <>
                                    <span>Operating Carrier: {flight.operatingCarrier}</span>
                                    <br/>
                                </>
                            }
                            <span>Cabin: {flight.CabinClass}</span>
                            <br/>
                            <br/>
                            <span>Airline PNR: {flight.pnr}</span>
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
        </td>
        <td style={{padding: "10px 8px", color: '#000'}}>
            <b className={'fw-bold'}>{flight.Origin.Airport.AirportCode}</b>
            <br/>
            <p
                style={{
                    marginBottom: "0",
                    marginTop: "6px",
                    fontSize: "13px",
                    lineHeight: "21px",
                    color: "#000",
                }}
            >

               <span>
                    ({flight.Origin.Airport.AirportName}, {flight.Origin.Airport.CityName})
                </span>
                <br/>
                {flight.Origin.Airport.Terminal && <span>Terminal:{flight.Origin.Airport.Terminal}<br/></span>}
                <b style={{color: "#000"}} className={'fw-bold'}>
                    {flight.Origin.DepTime && moment(flight.Origin.DepTime).format('DD-MMM-YYYY hh:mm a, ddd')}
                </b>
            </p>
        </td>
        <td
            style={{
                padding: "10px 8px",
                whiteSpace: "nowrap",
                fontSize: "13px",
                textAlign: "center",
            }}
        >
      <span
          style={{
              textAlign: "center",
              display: "block",
              marginTop: "9px",
          }}
      >
        <img
            src="https://tbolite.tektravels.com/images/planeIcon.png"
            alt="planeIcon"
            style={{zIndex: "99", position: "relative"}}
        />
        <p
            style={{
                width: "100%",
                borderTop: "1px dotted #898989",
                height: "1px",
                margin: "0",
                marginTop: "2px",
                position: "relative",
                bottom: "12px",
            }}
        />
      </span>
        </td>
        <td style={{padding: "10px 8px", color: '#000'}}>
            <b className={'fw-bold'}>{flight.Destination.Airport.AirportCode}</b>
            <br/>
            <p
                style={{
                    marginBottom: "0",
                    marginTop: "6px",
                    fontSize: "13px",
                    lineHeight: "21px",
                    color: "#000",
                }}
            >
                <span>
                    ({flight.Destination.Airport.AirportName}, {flight.Destination.Airport.CityName})
                </span>
                <br/>
                {flight.Destination.Airport.Terminal &&
                (<span>Terminal:{flight.Destination.Airport.Terminal}<br/></span>)}
                <br/>
                <b style={{color: "#000"}} className={'fw-bold'}>
                    {flight.Destination.ArrTime && moment(flight.Destination.ArrTime).format('DD-MMM-YYYY hh:mm a, ddd')}
                </b>
            </p>
        </td>
        <td style={{color: "#000", paddingLeft: "10px"}}>
            <b className={'fw-bold'}>{flight.FlightStatus}</b>
        </td>
    </tr>
);

const FlightDetailsTable = ({data}) => {
    return (
        <tr className="printborder">
            <td colSpan="2">
                <table
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                    align="center"
                    bgcolor="#ffffff"
                    style={{
                        width: "100%",
                        fontFamily: "Arial, Verdana, Helvetica, sans-serif",
                        fontSize: "14px",
                        color: "#000",
                        background: "#ffffff",
                        borderCollapse: "collapse",
                        borderRadius: "6px",
                        marginTop: "16px",
                        border: "1px solid #dbdada",
                    }}
                >
                    <tbody>
                    <tr className="printborder">
                        <td>
                            <table
                                cellpadding="0"
                                cellspacing="0"
                                border="0"
                                align="left"
                                style={{
                                    width: "100%",
                                    fontFamily: "Arial, Verdana, Helvetica, sans-serif",
                                    fontSize: "14px",
                                    color: "#000",
                                    border: 0,
                                    borderCollapse: "collapse",
                                }}
                            >
                                <tbody>
                                <tr className="printborder solidBorder">
                                    <th
                                        align="left"
                                        style={{
                                            backgroundColor: "#F0D39D",
                                            padding: "8px",
                                            width: "25%",
                                        }}
                                    >
                                        Flight Details
                                    </th>
                                    <th
                                        align="left"
                                        style={{
                                            backgroundColor: "#F0D39D",
                                            padding: "8px",
                                        }}
                                    >
                                        Departure
                                    </th>
                                    <th
                                        align="left"
                                        style={{
                                            backgroundColor: "#F0D39D",
                                            padding: "8px",
                                            width: "15%",
                                        }}
                                    />
                                    <th
                                        align="left"
                                        style={{
                                            backgroundColor: "#F0D39D",
                                            padding: "8px",
                                        }}
                                    >
                                        Arrival
                                    </th>
                                    <th
                                        align="left"
                                        style={{
                                            backgroundColor: "#F0D39D",
                                            paddingLeft: "15px",
                                            width: "15%",
                                        }}
                                    >
                                        Status
                                    </th>
                                </tr>
                                <tr className="printborder">
                                    <td
                                        colSpan="5"
                                        className={
                                            "solidBorder themeLightBgColor"
                                        }
                                    >
                                        <table
                                            cellPadding="0"
                                            cellSpacing="0"
                                            border="0"
                                            align="left"
                                            style={{
                                                width: "100%",
                                                fontFamily:
                                                    "Arial, Verdana, Helvetica, sans-serif",
                                                fontSize: "14px",
                                                color: "#000",
                                                borderCollapse:
                                                    "collapse",
                                            }}
                                        >
                                            <tbody>
                                            <tr>
                                                <th align="left">
                                                    Departure Flight
                                                </th>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                {data && data.Segments.map((segment, index) => (
                                    <FlightDetail key={index} flight={{...segment, pnr: data.PNR}}/>
                                ))}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    );
};


const FlightBagageDetails = ({flight}) => (
    <tr className="printborder">
        <td
            style={{
                padding: "10px 8px",
                color: "#575757",
                fontSize: "13px",
                textAlign: "center",
            }}
        >
            <b className={'fw-bold'}>{flight.Origin.Airport.AirportCode} - {flight.Destination.Airport.AirportCode}</b>
            <br/>
            {flight && flight.Airline && flight.Airline.AirlineCode && `${flight.Airline.AirlineCode} `}
            {flight && flight.Airline && flight.Airline.FlightNumber && flight.Airline.FlightNumber}
        </td>
        <td
            colSpan="3"
            style={{
                padding: "10px 0px",
                borderLeft:
                    "1px solid #D9E2F0",
            }}
        >
            <table
                cellPadding="0"
                cellSpacing="0"
                border="0"
                align="left"
                style={{
                    width: "100%",
                    fontFamily:
                        "Verdana, Helvetica, sans-serif",
                    fontSize: "13px",
                    color: "#575757",
                    borderCollapse:
                        "collapse",
                }}
            >
                <tbody>
                <tr>
                    <td
                        width="37%"
                        style={{
                            paddingLeft:
                                "10px",
                        }}
                    >
                        {flight.passengerDetails && flight.passengerDetails.Title && `${flight.passengerDetails.Title} `}
                        {flight.passengerDetails && flight.passengerDetails.FirstName && `${flight.passengerDetails.FirstName} `}
                        {flight.passengerDetails && flight.passengerDetails.LastName && `${flight.passengerDetails.LastName} `}
                    </td>
                    <td width="30%">
                        <p
                            style={{margin: "0", lineHeight: "21px",}}
                        >
                            <span>
                                <img
                                    src="https://tbolite.tektravels.com/Images/seat.png"
                                    alt="seaticon"
                                    height="16"
                                />{" "}
                                Seat: {flight.passengerDetails && flight.passengerDetails.SegmentAdditionalInfo && flight.passengerDetails.SegmentAdditionalInfo.length > 0 && flight.passengerDetails.SegmentAdditionalInfo[flight.index].Seat ? flight.passengerDetails.SegmentAdditionalInfo[flight.index].Seat : '--'}
                            </span>
                            <br/>
                            <span style={{display: "block"}}>
                                <img
                                    src="https://tbolite.tektravels.com/Images/bagIcon.png"
                                    alt="bagicon"
                                    height="16"
                                />{" "}
                                Baggage: {flight.passengerDetails && flight.passengerDetails.SegmentAdditionalInfo && flight.passengerDetails.SegmentAdditionalInfo.length > 0 && flight.passengerDetails.SegmentAdditionalInfo[flight.index].Baggage ? flight.passengerDetails.SegmentAdditionalInfo[flight.index].Baggage : '--'}
                                <br/>
                            </span>
                            <span>
                                <img
                                    src="https://tbolite.tektravels.com/Images/bagIcon.png"
                                    alt="bagicon"
                                    height="16"
                                />{" "}
                                Excess Baggage: 0KG
                            </span>
                            <br/>
                            <span>
                                <img
                                    src="https://tbolite.tektravels.com/Images/restaurant.png"
                                    alt="mealicon"
                                />{" "}
                                Meal: {flight.passengerDetails && flight.passengerDetails.SegmentAdditionalInfo && flight.passengerDetails.SegmentAdditionalInfo.length > 0 && flight.passengerDetails.SegmentAdditionalInfo[flight.index].Meal ? flight.passengerDetails.SegmentAdditionalInfo[flight.index].Meal : '--'}
                            </span>
                            <br/>
                            <span style={{display: "inline-flex",}}>
                                <img
                                    src="https://tbolite.tektravels.com/Images/star1.png"
                                    alt="sssricon"
                                    height="16"
                                    style={{marginRight: "4px", paddingTop: "3px",}}
                                />{" "}
                            </span>
                            Special
                            Service: {flight.passengerDetails && flight.passengerDetails.SegmentAdditionalInfo && flight.passengerDetails.SegmentAdditionalInfo.length > 0 && flight.passengerDetails.SegmentAdditionalInfo[flight.index].SpecialService ? flight.passengerDetails.SegmentAdditionalInfo[flight.index].SpecialService : '--'}
                            <br/>
                        </p>
                    </td>
                    <td width="30%">
                        <span style={{marginLeft: "80px"}}>

                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </td>
    </tr>
);


const FlightBaggageDetailsTable = ({data, passengerDetails}) => {
    return (
        <tr className="printborder">
            <td colSpan="2">
                <table
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                    align="center"
                    bgcolor="#ffffff"
                    style={{
                        width: "100%",
                        fontFamily: "Arial, Verdana, Helvetica, sans-serif",
                        fontSize: "14px",
                        color: "#000",
                        background: "#ffffff",
                        borderCollapse: "collapse",
                        borderRadius: "6px",
                        marginTop: "16px",
                        border: "1px solid #dbdada",
                    }}
                >
                    <tbody>
                    <tr className="printborder">
                        <td>
                            <table
                                cellpadding="0"
                                border="0"
                                align="left"
                                style={{
                                    width: "100%",
                                    fontFamily: "Arial, Verdana, Helvetica, sans-serif",
                                    fontSize: "14px",
                                    color: "#000",
                                    border: 0,
                                    borderCollapse: "collapse",
                                }}
                            >
                                <tbody>
                                <tr className="printborder themeBgColor">
                                    <th
                                        align="left"
                                        className={'solidBorder'}
                                    >
                                        Flight
                                    </th>
                                    <th
                                        align="left"
                                        className={'solidBorder'}

                                    >
                                        Passenger Name
                                    </th>
                                    <th
                                        align="left"
                                        className={'solidBorder'}

                                    >
                                        Ancillary details
                                    </th>
                                    <th
                                        style={{
                                            padding: "8px",
                                            width: "30%",
                                        }}
                                    >
                                        Barcode
                                    </th>
                                </tr>
                                <tr className="printborder">
                                    <td
                                        colSpan="5"
                                        className={
                                            "solidBorder themeLightBgColor"
                                        }
                                    >
                                        <table
                                            cellPadding="0"
                                            cellSpacing="0"
                                            border="0"
                                            align="left"
                                            style={{
                                                width: "100%",
                                                fontFamily:
                                                    "Arial, Verdana, Helvetica, sans-serif",
                                                fontSize: "14px",
                                                color: "#000",
                                                borderCollapse:
                                                    "collapse",
                                            }}
                                        >
                                            <tbody>
                                            <tr>
                                                <th align="left">
                                                    Departure Flight
                                                </th>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                {data && data.Segments.map((segment, index) => (
                                    <FlightBagageDetails key={index} flight={{
                                        ...segment,
                                        pnr: data.PNR,
                                        passengerDetails: passengerDetails,
                                        index: index
                                    }}/>
                                ))}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    );
};

const TicketDetails = ({passengerDetails}) => {
    return (
        <tr className="printborder">
            <td colSpan="2">
                <td
                    colSpan="1"
                >
                    <div className={'mt10'}>
                        <b>
                            <span style={{color: "#E30101"}}>Important:</span> This is an
                            Electronic Ticket. Passengers must carry a valid photo ID for
                            check-in at the airport.
                        </b>
                        <p style={{fontSize: "13px", marginTop: "35px", marginBottom: "25px"}}>
                            Carriage and other services provided by the carrier are subject
                            to conditions of carriage which hereby incorporated by
                            reference. These conditions may be obtained from the issuing
                            carrier. If the passenger's journey involves an ultimate
                            destination or stop in a country other than the country of
                            departure, the Warsaw convention may be applicable and the
                            convention governs and in most cases limits the liability of
                            carriers for death or personal injury and in respect of loss of
                            or damage to baggage.
                        </p>
                        <p>
                            <b style={{color: "#E30101"}}>Note:</b> We recommend purchasing
                            travel insurance for your trip. Please contact your travel
                            advisor to purchase travel insurance.
                        </p>
                    </div>
                </td>
                <td
                    id="paymentDetails"
                    width="30%"
                    style={{verticalAlign: "baseline"}}
                    colSpan="1"
                >
                    <div className={'mt10'}>
                        <table
                            style={{
                                width: "100%",
                                fontFamily: "Arial, Verdana, Helvetica, sans-serif",
                                fontSize: "13px",
                                color: "#000",
                                border: "0",
                                backgroundColor: "#ffffff",
                                borderCollapse: "collapse",
                                borderRadius: "6px",
                            }}
                        >
                            <tbody>
                            <tr className="printborder themeBgColor">
                                <td
                                    colSpan="2"
                                    style={{
                                        padding: "8px 20px",
                                    }}
                                >
                                    <b>Payment Details</b>
                                </td>
                            </tr>

                            <tr className="printborder">
                                <td style={{paddingInline: "18px"}}>
                                    <table
                                        style={{
                                            width: "100%",
                                            fontFamily: "Arial, Verdana, Helvetica, sans-serif",
                                            fontSize: "13px",
                                            color: "#575757",
                                            border: "0",
                                            backgroundColor: "#ffffff",
                                            borderCollapse: "collapse",
                                            borderRadius: "6px",
                                            marginBottom: "9px",
                                            marginTop: "7px",
                                        }}
                                    >
                                        <tbody>
                                        <tr className="printborder">
                                            <td style={{padding: "6px 4px"}}>Fare:</td>
                                            <td style={{padding: "6px 4px"}}>
                                                <b>₹ {passengerDetails && passengerDetails.Fare && passengerDetails.Fare.BaseFare && passengerDetails.Fare.BaseFare}</b>
                                            </td>
                                        </tr>
                                        <tr className="printborder">
                                            <td style={{padding: "6px 4px"}}>K3/GST:</td>
                                            <td style={{padding: "6px 4px"}}>
                                                ₹ {passengerDetails && passengerDetails.Fare && passengerDetails.Fare.TaxBreakup.find(item => item.key === 'K3')?.value || 0}
                                            </td>
                                        </tr>
                                        <tr className="printborder">
                                            <td style={{padding: "6px 4px"}}>
                                                Fee &amp; Surcharge:
                                            </td>
                                            <td style={{padding: "6px 4px"}}>
                                                <b>
                                                    ₹ {passengerDetails && passengerDetails.Fare && (
                                                    passengerDetails.Fare.Tax && passengerDetails.Fare.OtherCharges
                                                        ? passengerDetails.Fare.Tax + passengerDetails.Fare.OtherCharges
                                                        : passengerDetails.Fare.Tax || passengerDetails.Fare.OtherCharges
                                                )}
                                                </b>
                                            </td>
                                        </tr>

                                        {/* Optional Fields */}
                                        <tr
                                            className="printborder"
                                            id="divEditTxnFeeDiv"
                                            style={{display: "none"}}
                                        >
                                            <td style={{padding: "6px 4px"}}>Txn Fee:</td>
                                            <td style={{padding: "6px 4px"}}>
                                                ₹{" "}
                                                <input
                                                    type="text"
                                                    style={{width: "80px"}}
                                                    id="CurrentTxnFee"
                                                    name="CurrentTxnFee"
                                                    value="0"
                                                />
                                            </td>
                                        </tr>

                                        <tr
                                            className="printborder"
                                            id="divEditDisplayFee"
                                            style={{display: "none"}}
                                        >
                                            <td>Display Txn Fee:</td>
                                            <td>
                                                <select
                                                    name="displayServiceFee"
                                                    id="displayServiceFee"
                                                    style={{marginLeft: "10px"}}
                                                >
                                                    <option value="ShowInTax">Show In Tax</option>
                                                    <option value="ShowSeparately">
                                                        Show Separately
                                                    </option>
                                                </select>
                                            </td>
                                        </tr>

                                        <tr
                                            className="printborder"
                                            id="divApplyDiscount"
                                            style={{display: "none"}}
                                        >
                                            <td>Discount(-):</td>
                                            <td>
                                                <span>₹</span>
                                                <input
                                                    type="text"
                                                    style={{width: "80px", marginTop: "5px"}}
                                                    id="CurrentDiscount"
                                                    name="CurrentDiscount"
                                                    value="0"
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                            <tr className="printborder">
                                <td
                                    style={{
                                        padding: "8px 20px",
                                        backgroundColor: "#F6F6F6",
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            backgroundColor: "#F6F6F6",
                                            fontFamily: "Arial, Verdana, Helvetica, sans-serif",
                                            fontSize: "13px",
                                            color: "#575757",
                                            border: "0",
                                            borderCollapse: "collapse",
                                            borderRadius: "6px",
                                        }}
                                    >
                                        <tbody>
                                        <tr>
                                            <td>
                                                <b>Total Amount:</b>
                                            </td>
                                            <td style={{paddingLeft: "40px"}}>
                                                <b>₹ {passengerDetails && passengerDetails.Fare && passengerDetails.Fare.PublishedFare && passengerDetails.Fare.PublishedFare}</b>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
            </td>
        </tr>
    );
};

export default ETicket;
