import React from "react";

const ManageBookingComponent = () => {
    return (
        <>
            <div className="tab-head">
                <h6 className="m-0">My Booking</h6>
            </div>
            <form>
                <div className="row row-gap-3">
                    <div className="col-lg-3 col-12">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="PNR Number"
                        />
                    </div>
                    <div className="col-lg-3 col-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Destination"
                        />
                    </div>
                    <div className="col-lg-3 col-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Passenger Name"
                        />
                    </div>
                    <div className="col-lg-3 col-12">
                        <button type="submit" className="btn btn-primary w-100">
                            Search
                        </button>
                    </div>
                </div>
            </form>
            <div className="track-flights">
                <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion-item">
                        <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingOne"
                        >
                            <ul>
                                <li className="p-3 head-accordion">
                                    <h6 className="pnr">PNR</h6>
                                    <div className="d-flex align-items-center">
                                        <h6
                                            style={{
                                                justifyContent: "center",
                                                textAlign: "center"
                                            }}
                                        >
                                            NEW DELHI{" "}
                                            <small className="d-block">Terminal 2</small>
                                        </h6>
                                        <span>
                            <img src="images2/plane.png" alt="plane"/>
                          </span>
                                        <h6
                                            style={{
                                                justifyContent: "center",
                                                textAlign: "center"
                                            }}
                                        >
                                            BOMBAY <small className="d-block">Terminal 3</small>
                                        </h6>
                                    </div>
                                    <div>
                                        <a
                                            href="#"
                                            className="btn btn-primary min-width-btn me-2"
                                        >
                                            Details
                                        </a>
                                        <a
                                            href=""
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseOne"
                                            aria-expanded="true"
                                            aria-controls="panelsStayOpen-collapseOne"
                                            className="btn p-0"
                                        >
                                            <img
                                                src="images2/arrow-up.png"
                                                alt="arrow-up"
                                                width={28}
                                            />
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="panelsStayOpen-headingOne"
                        >
                            <div className="accordion-body">
                                <ul>
                                    <li className="p-0">
                                        <h6>
                                            02 <small className="d-block">Passanges</small>
                                        </h6>
                                        <h6>
                                            21 Sept, 24{" "}
                                            <small className="d-block">Departure</small>
                                        </h6>
                                        <h6>
                                            Departure Time{" "}
                                            <small className="d-block">04:45 PM</small>
                                        </h6>
                                        <h6>
                                            23 Sept, 24{" "}
                                            <small className="d-block">Arrival</small>
                                        </h6>
                                        <h6>
                                            Arrival Time{" "}
                                            <small className="d-block">07:55 PM</small>
                                        </h6>
                                        <h6>
                                            Class: <small className="d-block">Business</small>
                                        </h6>
                                        <h6>
                                            Journey Time:{" "}
                                            <small className="d-block">3H 15m</small>
                                        </h6>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingTwo"
                        >
                            <ul>
                                <li className="p-3 head-accordion">
                                    <h6 className="pnr">PNR</h6>
                                    <div className="d-flex align-items-center">
                                        <h6
                                            style={{
                                                justifyContent: "center",
                                                textAlign: "center"
                                            }}
                                        >
                                            NEW DELHI{" "}
                                            <small className="d-block">Terminal 2</small>
                                        </h6>
                                        <span>
                            <img src="images2/plane.png" alt="plane"/>
                          </span>
                                        <h6
                                            style={{
                                                justifyContent: "center",
                                                textAlign: "center"
                                            }}
                                        >
                                            BOMBAY <small className="d-block">Terminal 3</small>
                                        </h6>
                                    </div>
                                    <div>
                                        <a
                                            href="#"
                                            className="btn btn-primary min-width-btn me-2"
                                        >
                                            Details
                                        </a>
                                        <a
                                            href=""
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseTwo"
                                            aria-expanded="true"
                                            aria-controls="panelsStayOpen-collapseTwo"
                                            className="btn p-0"
                                        >
                                            <img
                                                src="images2/arrow-up.png"
                                                alt="arrow-up"
                                                width={28}
                                            />
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingTwo"
                        >
                            <div className="accordion-body">
                                <ul>
                                    <li className="p-0">
                                        <h6>
                                            02 <small className="d-block">Passanges</small>
                                        </h6>
                                        <h6>
                                            21 Sept, 24{" "}
                                            <small className="d-block">Departure</small>
                                        </h6>
                                        <h6>
                                            Departure Time{" "}
                                            <small className="d-block">04:45 PM</small>
                                        </h6>
                                        <h6>
                                            23 Sept, 24{" "}
                                            <small className="d-block">Arrival</small>
                                        </h6>
                                        <h6>
                                            Arrival Time{" "}
                                            <small className="d-block">07:55 PM</small>
                                        </h6>
                                        <h6>
                                            Class: <small className="d-block">Business</small>
                                        </h6>
                                        <h6>
                                            Journey Time:{" "}
                                            <small className="d-block">3H 15m</small>
                                        </h6>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ManageBookingComponent
