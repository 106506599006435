import React from "react"
import {Card} from "../../../components/Elements";

const Error = (props) => {
    let {response} = props;
    return (
        <section className="agent-with-us">
            <div className="container mt-5 mb-5">
                <div className="card mt-4 mb-3">
                    <div className="card-body">
                        <div className={'align-center'}>
                            <img src={'../images2/delete.png'} className={'mt-3 mb-3'}/>
                            <div className={'mt-4'}>
                                <h4 className={'color-red'}>{response && response.Error && response.Error.ErrorMessage ? response.Error.ErrorMessage : ""}</h4>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </section>
    )
}
export default Error
