export const loginSendOtpUrl = () => {
    return "/login/sendOtp"
}
export const loginSubmitLoginUrl = () => {
    return "/login/loginByOtp"
}
export const resendOtpUrl = () => {
    return "/resendOtp"
}

export const singleUserUrl = id => {
    return '/user/' + id
}