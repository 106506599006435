import React, {useEffect, useState} from "react";
import {Radio} from "../../../components/Elements";
import {flightTypeOption} from "../../../components/utils/appUtils";
import OneWaySearch from "./oneWaySearch";
import RoundTripSearch from "./roundTripSearch";

const initState = {
    cabinClass: "2",
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
    resultFareType: "2",

}

const SearchFlightMainComponent = (props) => {
    let {flightObj = {}} = props;
    let [state, setState] = useState(initState)
    let [journeyType, setJourneyType] = useState("1");
    useEffect(() => {
        if (flightObj.journeyType) {
            setJourneyType(flightObj.journeyType)
        }
    }, [flightObj])
    return (
        <>
            <div className="radio-box mb-3">
                <Radio
                    name={'flightType'}
                    options={flightTypeOption}
                    value={journeyType}
                    onChange={(value) => {
                        setJourneyType(value)
                    }}/>
            </div>

            {journeyType == "1" ? <OneWaySearch journeyType={journeyType} {...props} state={state} setState={setState}/> : null}
            {journeyType == "2" ? <RoundTripSearch journeyType={journeyType} {...props} state={state} setState={setState}/> : null}
            {journeyType == "3"}
        </>
    )
}

export default SearchFlightMainComponent
