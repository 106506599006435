import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {airLinesFxn} from "../actions";
import {DatePicker, Form, notification, Radio, Select} from "../../../components/Elements";
import CityTypeheadComponent from "../../../components/CityTypeheadComponent";
import {CabinComponent, FareTypesComponent, PassengerComponent} from "./flightSearchComponent";
import moment from "moment";

const initState = {
    cabinClass: "2",
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
    resultFareType: "2"
}
const OneWaySearch = (props) => {
    let {journeyType, onClick, flightObj, state, setState} = props;
    const navigate = useNavigate();
    let dispatch = useDispatch()
    // let [state, setState] = useState(initState);
    let [airlineList, setAirlineList] = useState([])

    console.log(flightObj, "flight obj", state)
    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        loadAirLine: async () => {
            let params = {
                results: 10000,
                count: 10000,
            }
            let {data} = await airLinesFxn(params);
            setAirlineList(data)
        },
        handleSearchFlight: async () => {
            if (!state.origin) {
                return notification.warning({message: "Please choose Departing From."})
            }
            if (!state.destination) {
                return notification.warning({message: "Please choose Going To."})
            }
            if (!state.preferredDepartureTime) {
                return notification.warning({message: "Please choose Departure Date."})
            }
            if (state.adultCount == 0 && state.childCount == 0 && state.infantCount == 0) {
                return notification.warning({message: "Please choose Passenger."})
            }
            let obj = {
                ...state, journeyType
            }
            dispatch({type: "SEARCH_FLIGHT", flightObj: obj})
            onClick(obj)
        }
    }
    useEffect(() => {
        events.loadAirLine()
    }, [])

    useEffect(() => {
        if (flightObj && flightObj.preferredDepartureTime) {
            flightObj.preferredDepartureTime = moment(flightObj.preferredDepartureTime)
        }
        setState((prevState) => {
            return {
                ...prevState,
                ...flightObj
            }
        })
    }, [flightObj])

    return (
        <>
            <Form>
                <div className="row row-gap-3">
                    <div className={'col-md-4'} key={state.origin}>
                        <CityTypeheadComponent
                            placeholder={"Departing From?"}
                            cityId={state.origin} onSelect={(value) => {
                            events._updateState({origin: value})
                        }}/>
                    </div>
                    <div className={'col-md-4'} key={state.destination}>
                        <CityTypeheadComponent
                            placeholder={"Going To?"}
                            cityId={state.destination} onSelect={(value) => {
                            events._updateState({destination: value})
                        }}/>
                    </div>
                    <div className={'col-md-4'}>
                        <DatePicker
                            placeholder={'Departure Date'}
                            value={state.preferredDepartureTime}
                            onChange={(value) => {
                                events._updateState({preferredDepartureTime: value})
                            }}/>
                    </div>
                    <div className={'col-md-4'}>
                        <Select
                            options={airlineList}
                            keyAccessor={(option) => option.airLineCode}
                            valueAccessor={(option) => option.airLineName}
                            placeholder={"Preferred Airline"}
                            required={true}
                            showSearch={true}
                            multiple={true}
                            allowClear={true}
                            onChange={(value) => {
                                events._updateState({preferredAirlines: value})
                            }}
                        />
                    </div>
                    <div className="col-lg-4 col-12">
                        <CabinComponent state={state} onChange={(data) => {
                            events._updateState(data)
                        }}/>
                    </div>
                    <div className="col-lg-4 col-12">
                        <PassengerComponent state={state} onChange={(data) => {
                            events._updateState(data)
                        }}/>
                    </div>

                    <div className={'col-lg-12 col-12 mt-4'}>
                        <FareTypesComponent value={state.resultFareType} onClick={(data) => {
                            events._updateState({resultFareType: data})
                        }}/>
                    </div>
                </div>
                <button onClick={() => {
                    events.handleSearchFlight()
                }} className="btn btn-primary mt-4 w-100">
                    Find Your Flight
                </button>
            </Form>
        </>
    )
}

export default OneWaySearch
