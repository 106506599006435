import React, { useState } from "react";
import { Modal, Tooltip } from '../../components/Elements';
import { useSelector } from "react-redux";

const SeatsLayoutModal = (props) => {
    const { visible, onClose, seatsObj, onSeatConfirm, selectedFlight} = props;
    const [selectedSeats, setSelectedSeats] = useState({});
    // const { selectedFlight } = useSelector((state) => ({
    //     selectedFlight: state.flightsReducer.selectedFlight,
    // }));

    console.log(selectedFlight, "selectedflight")

    const segments = selectedFlight?.Segments?.[0] || [];

    const [activeSegmentIndex, setActiveSegmentIndex] = useState(0);

    const onSeatSelect = (seat, availabilityType, segmentIndex) => {
        if (availabilityType !== 3) {
            setSelectedSeats((prev) => ({
                ...prev,
                [segmentIndex]: seat,
            }));
        }
    };

    const onSelectSeatFromDetails = (segmentIndex) => {
        setActiveSegmentIndex(segmentIndex);
    };

    const handleConfirmSeatSelection = () => {
        const selectedSeat = selectedSeats[activeSegmentIndex];
        if (selectedSeat && onSeatConfirm) {
            // console.log(selectedSeat, "------")
            onSeatConfirm(selectedSeat);
        }
        onClose();
    };



    return (
        <Modal visible={visible} onClose={onClose}>
            <h5>Select Seats</h5>
            <div
                className="seat-selection"
                style={{ maxHeight: "600px", overflowY: "auto", overflowX: "hidden" }}
            >
                <div className="flightbox">
                    <div className="flightbody">
                        <div className="row">
                            {/* Seat Details Section */}
                            <div className="col-md-4" style={{ borderRight: '1px solid #ccc' }}>

                                {segments.map((segment, segmentIndex) => {
                                    const selectedSeat = selectedSeats[segmentIndex];
                                    return (
                                        <div className="box seat-details-box mt-2" key={segmentIndex}>
                                            <h6>Seat Details</h6>
                                            <div>
                                                <p>
                                                    <strong>Flight:</strong> {segment?.Origin.Airport.AirportCode} -{" "}
                                                    {segment?.Destination.Airport.AirportCode}
                                                </p>
                                                <p>
                                                    <strong>Seat No:</strong>{" "}
                                                    {selectedSeat?.Code || "Not Selected"}
                                                </p>
                                                <p>
                                                    <strong>Total Price:</strong>{" "}
                                                    {selectedSeat ? `${selectedSeat.Price} ${selectedSeat.Currency}` : "N/A"}
                                                </p>
                                                <a href="#" onClick={() => onSelectSeatFromDetails(segmentIndex)}>
                                                    Select Seat
                                                </a>
                                            </div>
                                        </div>
                                    );
                                })}

                                <div className="hintbox">
                                    {/* Hint Rows */}
                                    <div className="hintrow">
                                        <span className="hintseat">
                                            <dfn className="seathint quitezone">
                                                <span></span>
                                            </dfn>
                                        </span>
                                        <span className="hinttext">Quiet zone</span>
                                    </div>
                                    <div className="hintrow">
                                        <span className="hintseat">
                                            <dfn className="seathint hintgood">
                                                <span></span>
                                            </dfn>
                                        </span>
                                        <span className="hinttext">Selected Seat</span>
                                    </div>
                                    <div className="hintrow">
                                        <span className="hintseat">
                                            <dfn className="seathint hintbooked">
                                                <span></span>
                                            </dfn>
                                        </span>
                                        <span className="hinttext">Occupied Seat</span>
                                    </div>
                                    <div className="hintrow">
                                        <span className="hintseat">
                                            <dfn className="seathint">
                                                <i></i>
                                            </dfn>
                                        </span>
                                        <span className="hinttext">Bulkhead Seat</span>
                                    </div>
                                </div>
                            </div>

                            {/* RowSeats Section */}
                            <div className="col-md-7" style={{ borderRight: '1px solid #ccc' }}>
                                <div className="content">
                                    {segments.map((segment, segmentIndex) => {
                                        let hasDeck1LabelShown = false; // Initialize for each segment

                                        return (
                                            <div
                                                key={segmentIndex}
                                                className={`segment-rows ${
                                                    activeSegmentIndex === segmentIndex ? "active" : "hidden"
                                                }`}
                                            >
                                                <h6>
                                                    {segment?.Origin.Airport.AirportCode} -{" "}
                                                    {segment?.Destination.Airport.AirportCode}
                                                </h6>
                                                {seatsObj?.[segmentIndex]?.RowSeats?.map((row, rowIndex) => {
                                                    let showDeck1Label = false;

                                                    if (
                                                        row.Seats.some((seat) => seat.Deck === 1) &&
                                                        !hasDeck1LabelShown
                                                    ) {
                                                        showDeck1Label = true;
                                                        hasDeck1LabelShown = true;
                                                    }

                                                    return (
                                                        <div key={rowIndex} className="row-wrapper">
                                                            {showDeck1Label && (
                                                                <div className="deck-label">
                                                                    <strong>Deck 1</strong>
                                                                </div>
                                                            )}
                                                            {/* Ensures seats start on a new row */}
                                                            <div className="row">
                                                                <div className="seatrobox col-md-3">
                                                                    <ul className="seatrow">
                                                                        {row.Seats.filter((seat) =>
                                                                            ["A", "B", "C"].includes(seat.SeatNo)
                                                                        ).map((seat, seatIndex) => (
                                                                            <li
                                                                                key={seatIndex}
                                                                                className={`seat ${
                                                                                    seat.AvailablityType === 3
                                                                                        ? "booked"
                                                                                        : ""
                                                                                }`}
                                                                                onClick={() =>
                                                                                    onSeatSelect(
                                                                                        seat,
                                                                                        seat.AvailablityType,
                                                                                        segmentIndex
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    pointerEvents:
                                                                                        seat.AvailablityType === 3
                                                                                            ? "none"
                                                                                            : "auto",
                                                                                }}
                                                                            >
                                                                                <Tooltip
                                                                                    title={`Seat: ${seat.Code}, Price: ${seat.Price} ${seat.Currency}`}
                                                                                >
                                                    <span
                                                        className={`seat-code ${
                                                            selectedSeats[
                                                                segmentIndex
                                                                ]?.Code === seat.Code
                                                                ? "selected"
                                                                : ""
                                                        }`}
                                                    >
                                                        {seat.Code}
                                                    </span>
                                                                                </Tooltip>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                                <div className="seatrobox col-md-4">
                                                                    <ul className="seatrow">
                                                                        {row.Seats.filter((seat) =>
                                                                            ["D", "E", "F"].includes(seat.SeatNo)
                                                                        ).map((seat, seatIndex) => (
                                                                            <li
                                                                                key={seatIndex}
                                                                                className={`seat ${
                                                                                    seat.AvailablityType === 3
                                                                                        ? "booked"
                                                                                        : ""
                                                                                }`}
                                                                                onClick={() =>
                                                                                    onSeatSelect(
                                                                                        seat,
                                                                                        seat.AvailablityType,
                                                                                        segmentIndex
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    pointerEvents:
                                                                                        seat.AvailablityType === 3
                                                                                            ? "none"
                                                                                            : "auto",
                                                                                }}
                                                                            >
                                                                                <Tooltip
                                                                                    title={`Seat: ${seat.Code}, Price: ${seat.Price} ${seat.Currency}`}
                                                                                >
                                                    <span
                                                        className={`seat-code ${
                                                            selectedSeats[
                                                                segmentIndex
                                                                ]?.Code === seat.Code
                                                                ? "selected"
                                                                : ""
                                                        }`}
                                                    >
                                                        {seat.Code}
                                                    </span>
                                                                                </Tooltip>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer confirm-seat">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleConfirmSeatSelection}
                        disabled={!selectedSeats[activeSegmentIndex]}
                    >
                        Confirm
                    </button>
                </div>
            </div>

        </Modal>
    );
};

export default SeatsLayoutModal;
