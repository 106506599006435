import React, {useEffect, useState} from "react";

import SearchFlightMainComponent from "../../flightBooking/searchComponent/SearchFlightMainComponent";

const initState = {
    flightType: "One-Way",
    journeyType: "1",
    cabinClass: "1",
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
    directFlight: false,

}
let filterArrInit = {
    stopsList: []
}
const ModifySearch = (props) => {
    let {flightList, flightObj, setFlightObjState, flightSearch} = props

    useEffect(() => {
        // events.loadAirLine()
    }, [])


    return (
        <>
            <div className="flight-booking">
                <div className="container position-relative">
                    <div className={"tab-content p-3 p-lg-4 bg-white modify_box"}>
                        <SearchFlightMainComponent {...props}
                                                   flightObj={flightObj}
                                                   onClick={(resp) => {
                                                       flightSearch(resp)
                                                   }}/>
                    </div>

                </div>
            </div>

        </>
    );
};

export default ModifySearch;
