import React, {useEffect, useState} from "react"
import FlightImg from "../../../components/flightImg";
import {CabinObject, displayDate, displayTime, minToHours} from "../../../components/utils/appUtils";
import {Popover, Tooltip, Modal} from "../../../components/Elements"
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import AirportImg from '../../../assets/img/airport.png'
import FlightIcons from "./flightIcons";

const FlightAddress = ({data}) => {
    return (
        <>
            <small className="d-block">
                {data && data.Airport && data.Airport.AirportName ? data.Airport.AirportName : ""}
                {data && data.Airport && data.Airport.AirportCode ? ` (${data.Airport.AirportCode}) ` : ""}
            </small>
            <small className="d-block">
                {data && data.Airport && data.Airport.Terminal ? `${data.Airport.Terminal}, ` : ""}
                {data && data.Airport && data.Airport.CityName ? `${data.Airport.CityName} ` : ""}
                {/*{data && data.Airport && data.Airport.CountryName ? data.Airport.CountryName : ""}*/}
            </small>
        </>
    )
}
const AllSegmentComponent = (props) => {
    let {allSegmentData, children} = props;
    const contentBody = (
        <>
            <table className={'table table-border'}>
                <tr>
                    <th>Airline Info</th>
                    <th>Flight Info</th>
                    <th>Dept.T</th>
                    <th>Arr.T</th>
                </tr>
                {allSegmentData && allSegmentData.length ? allSegmentData.map((item) => {
                    let {Airline, Origin, Destination} = item;
                    return (
                        <>
                            <tr>
                                <td>
                                    {Airline && Airline.AirlineCode} {Airline && Airline.FlightNumber} {Airline && Airline.FareClass}
                                </td>
                                <td className={'justify-content-between'}>
                                    {Origin ? <>
                                        {Origin.Airport && Origin.Airport.AirportCode ? Origin.Airport.AirportCode : ""}
                                        ({Origin.DepTime ? displayTime(Origin.DepTime) : ""})
                                    </> : null}
                                    &nbsp;-&nbsp;

                                    {Destination ? <>
                                        {Destination.Airport && Destination.Airport.AirportCode ? Destination.Airport.AirportCode : ""}
                                        ({Destination.ArrTime ? displayTime(Destination.ArrTime) : ""})
                                    </> : null}
                                </td>
                                <td>

                                </td>
                                <td>

                                </td>
                            </tr>

                        </>
                    )
                }) : null}
            </table>
        </>
    )
    return (
        <>
            <Popover content={contentBody}>
                {children}
            </Popover>
        </>
    )
}

const NonStopComponent = (props) => {
    let {data} = props;
    let {Segments, FareRules: fareRules} = data;
    let allSegmentData = Segments[0];
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    return (
        <>
            <div className={''}>
                <ul>
                    <li className="p-0">
                          <span className="logo">
                            <img src={`../airlineLogos/${data.AirlineCode}.gif`} alt=""/>
                          </span>
                        <h6>
                            {segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                            <small className="d-block">
                                {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                            </small>
                            <small className="d-block">
                                {data['IsLCC'] ? "LCC" : "Non LCC"}
                            </small>
                        </h6>
                        <div className="d-flex align-items-center" style={{gap: 30}}>
                            <h6>
                                {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                    {displayDate(segmentStarting.Origin.DepTime)}
                                </> : null}
                                {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                            </h6>
                            <span>
                                    <AllSegmentComponent allSegmentData={allSegmentData}>
                                        <p>Non-Stop</p>
                                    </AllSegmentComponent>
                                    <FlightImg/>
                            </span>
                            <h6>
                                {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                    {displayDate(segmentEnding.Destination.ArrTime)}
                                </> : null}
                                {segmentEnding.Destination ?
                                    <FlightAddress data={segmentEnding.Destination}/> : null}
                            </h6>
                            <h6>
                                {segmentEnding && segmentEnding.Duration ? minToHours(segmentEnding.Duration) : ""}
                                <small className="d-block">Economy Class</small>
                                <small className="d-block">
                                    {data.IsRefundable ? "Refundable" : "Non Refundable"}
                                </small>
                            </h6>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}
const WithStopComponent = (props) => {
    let {data} = props;
    let {Segments, FareRules: fareRules} = data;
    let allSegmentData = Segments[0];
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    let originObj = fareRules[0];
    let destinationObj = fareRules[fareRules.length - 1];

    let stopsComponent = (
        <>
            <span><FlightImg/></span>
            <h6>
                <AllSegmentComponent allSegmentData={allSegmentData}>
                    {allSegmentData.length - 1} Stop(s)
                </AllSegmentComponent>
                <small className="d-block">
                    {originObj && originObj.Origin ? originObj.Origin : ""}
                    &nbsp;-&nbsp;
                    {destinationObj && destinationObj.Destination ? destinationObj.Destination : ""}
                </small>
                <small className="d-block">
                    {segmentStarting && segmentStarting.Duration ? <>
                        {minToHours(segmentStarting.Duration)}
                    </> : null}
                </small>
            </h6>
            <span><FlightImg/></span>
        </>
    )

    return (
        <>

            <ul>
                <li className="p-0">
                       <span className="logo">
                            <img src={`../airlineLogos/${data.AirlineCode}.gif`} alt=""/>
                        </span>
                    <h6>{segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                        <small className="d-block">
                            {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                            {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                            {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                        </small>
                        <small className="d-block">
                            {data['IsLCC'] ? "LCC" : "Non LCC"}

                            {/*{segmentData && segmentData.Duration ? minToHours(segmentData.Duration) : ""}*/}
                        </small>
                    </h6>

                    <div
                        className="d-flex align-items-center"
                        style={{gap: '30px'}}>
                        <h6>
                            {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                {displayDate(segmentStarting.Origin.DepTime)}
                            </> : null}
                            {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                        </h6>

                        {stopsComponent}

                        <h6>
                            {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                {displayDate(segmentEnding.Destination.ArrTime)}
                            </> : null}

                            {segmentEnding.Destination ?
                                <FlightAddress data={segmentEnding.Destination}/> : null}
                        </h6>


                        {allSegmentData.length == 1 ? <h6>
                            {segmentEnding && segmentEnding.Duration ? minToHours(segmentEnding.Duration) : ""}
                            <small
                                className="d-block">Economy
                                Class</small>
                            <small className="d-block">
                                {data.IsRefundable ? "Refundable" : "Non Refundable"}

                            </small>
                        </h6> : null}
                    </div>
                </li>
            </ul>


        </>
    )
}

const FlightDetailsBox = (props) => {
    let {data, flightInfo} = props;
    let [selectedFlight, setSelectedFlight] = useState({})
    let [newAgentMargin, setNewAgentMargin] = useState()
    const [showModal, setShowModal] = useState({
        visible: false,
        searchDestination: '',
        flightDestination: ''
    });
    const [showSelectedFlightModal, setShowSelectedFlightModal] = useState({
        visible: false,
        FareWithoutTax: 0,
        flightPrice: 0,
        agentMargin: 0,
        taxAndSurCharges: 0,
    });
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        if (data && data.length) {
            setSelectedFlight(data[0])
        }
    }, [data])

    const events = {
        confirmBooking: async (data) => {
            const searchOrigin = flightInfo.origin;
            const searchDestination = flightInfo.destination;
            const flightOrigin = data?.Segments?.[0]?.[0]?.Origin?.Airport?.AirportCode;
            const firstSegment = data?.Segments?.[0];
            const flightDestination = firstSegment?.[firstSegment.length - 1]?.Destination?.Airport?.AirportCode;
            if (searchDestination !== flightDestination) {
                setShowModal({
                    visible: true,
                    searchDestination: `${searchOrigin}-${searchDestination}`,
                    flightDestination: `${flightOrigin}-${flightDestination}`,
                });
            } else {
                dispatch({
                    type: "UPDATE_RESULT_INDEX",
                    selectedFlight: {...data, FareBasisCode: {}},
                    traceId: flightInfo.traceId,
                    flightBookingType: "onWayFlight"
                });

                setTimeout(() => {
                    navigate("/checkout");
                }, 1000)
            }
        },
        handleOpenAgentMarginModal: async (data) => {
            setShowSelectedFlightModal({
                visible: true,
                FareWithoutTax: data.FareWithoutTax || 0,
                agentMargin: data.agentXTravelMargin || 0,
                taxAndSurCharges: data.taxAndSurCharge || 0,
                flightPrice: data.PublishedFare || 0,
            })
        },
        handleUpdateAgentMargin: async (newMargin) => {
            setSelectedFlight((prevSelectedFlight) => {
                const updatedMargin = parseFloat(newMargin) || 0;
                const updatedPublishFare =
                    parseFloat(prevSelectedFlight.Fare.PublishedFare || 0) -
                    (parseFloat(prevSelectedFlight.Fare.agentXTravelMargin || 0)) +
                    updatedMargin;

                return {
                    ...prevSelectedFlight,
                    Fare: {
                        ...prevSelectedFlight.Fare,
                        agentXTravelMargin: updatedMargin,
                        PublishedFare: updatedPublishFare,
                    }
                };
            });
            setNewAgentMargin('');
            setShowSelectedFlightModal((prevState) => ({
                ...prevState,
                visible: false,
            }));
        }


    }
    const handleModalAction = (action) => {
        if (action === "yes") {
            setShowModal({...showModal, visible: false});
            dispatch({
                type: "UPDATE_RESULT_INDEX",
                selectedFlight: {...selectedFlight, FareBasisCode: {}},
                traceId: flightInfo.traceId,
            });
            navigate("/checkout");
        } else {
            setShowModal({...showModal, visible: false});
        }
    };
    return (
        selectedFlight && selectedFlight['Segments'] && selectedFlight['Segments'].length ? <>
            <div className="book-flight mb-3">
                <div className="row row-gap-3 mb-3">
                    <div className="col-lg-10">
                        {/*accordion-flight-track*/}
                        <div className={''}>
                            <div className="track-flights">
                                {selectedFlight['Segments'][0].length > 1 ?
                                    <WithStopComponent data={selectedFlight}/> :
                                    <NonStopComponent data={selectedFlight}/>}
                            </div>

                            <div className="class-price mt-2">
                                <div className="row">
                                    <div className="row row-gap-3">
                                        {data.map((item, index) => {
                                            let firstSegment = item['Segments'][0][0]
                                            return (
                                                <div className="col-lg-4">
                                                    <div className="main-box" onClick={() => {
                                                        setSelectedFlight(item)
                                                    }}>
                                                        <input type="radio" className="class-price"
                                                               value={item['ResultIndex']}
                                                               checked={selectedFlight['ResultIndex'] == item['ResultIndex']}
                                                        />
                                                        <div className="box">
                                                            <span></span>
                                                            <small>{CabinObject[firstSegment['CabinClass']]}</small>
                                                            <h5>Rs. {item['Fare'] && item['Fare'].PublishedFare ? item['Fare'].PublishedFare : ""}</h5>
                                                        </div>
                                                    </div>
                                                    <FlightIcons flight={item} traceId={flightInfo.traceId}/>

                                                </div>
                                            )
                                        })}


                                    </div>


                                    <div className="details d-flex gap-3 flex-wrap align-items-center mt-3">
                                        {/*<button
                                            className="btn btn-grey text-nowrap d-flex gap-2 align-items-center">View Details
                                        </button>*/}

                                        {/*    {selectedFlight['Segments'][0][0].NoOfSeatAvailable ?
                                            <p className="m-0" style={{fontSize: '14px'}}>
                                                <b className="ms-2"
                                                   style={{color: 'red'}}>
                                                    Seat(s)
                                                    left: {selectedFlight['Segments'][0][0].NoOfSeatAvailable}</b>
                                            </p> : null}*/}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-2" key={selectedFlight}>
                        <div className="d-flex flex-wrap gap-3 h-100">
                            <div className="total-price cursor-pointer" onClick={() => {
                                events.handleOpenAgentMarginModal(selectedFlight.Fare)
                            }}>
                                <span>Total Price</span>
                                <h5>Rs. {selectedFlight.Fare && selectedFlight.Fare.PublishedFare ? selectedFlight.Fare.PublishedFare : ""}
                                </h5>
                                <small></small>
                                <button
                                    onClick={() => {
                                        events.confirmBooking(selectedFlight)
                                    }}
                                    className="btn btn-primary">Book Now
                                </button>
                            </div>
                            {/*     <button className="btn btn-primary-theme mt-auto w-100"
                                    style={{fontSize: '14px'}}>Change Class
                            </button>*/}
                        </div>
                    </div>
                </div>

            </div>
            {showModal.visible && (
                <Modal
                    visible={showModal.visible}
                    className={'popup-confirm'}
                    width={'38%'}
                    onClose={() => setShowModal({...showModal, visible: false})}
                >
                    <div className={'mt-1 alert-popup'}>
                        <div className={'mb-4'}>
                            <img src={AirportImg} alt={'airportimg'} width={'30%'}/>
                        </div>
                        <h3 className={'mb-4'}>Different Airport Alert</h3>
                        <p className={'mb-4'}>
                            You have searched for Outbound <strong>({showModal.searchDestination})</strong>,
                            however, this flight operates from Outbound <strong>({showModal.flightDestination})</strong>.

                        </p>
                        <h3 className={'mb-4'}>Do you wish to continue?</h3>
                        <div className="d-flex justify-content-center gap-3 mb-2">
                            <button
                                className="customYesBtn"
                                onClick={() => handleModalAction("yes")}
                            >
                                Yes
                            </button>
                            <button
                                className="customNoBtn"
                                onClick={() => handleModalAction("no")}
                            >
                                No
                            </button>

                        </div>
                    </div>
                </Modal>
            )}
            {/*           {showSelectedFlightModal.visible && (
                <Modal
                    visible={showSelectedFlightModal.visible}
                    className="popup-confirm"
                    width="30%"
                    height='auto'
                    onClose={() => setShowSelectedFlightModal({...showSelectedFlightModal, visible: false})}
                    footer={null}
                >
                    <div className="modal-header">
                        <h5>Update Agent Margin</h5>
                    </div>
                    <div style={{borderBottom: '1px solid #ccc', marginTop: '8px'}}/>

                    <div className="modal-body">

                        <div className="form-group mt-3">
                            <div>
                                <label htmlFor="agentMarginInput">New Agent Margin</label>
                                <input
                                    id="agentMarginInput"
                                    type="number"
                                    className="form-control mt10"
                                    value={newAgentMargin || showSelectedFlightModal.agentMargin}
                                    onChange={(e) => setNewAgentMargin(parseFloat(e.target.value) || '')}
                                    placeholder="Enter new margin"
                                />
                                {(newAgentMargin || showSelectedFlightModal.agentMargin) ? (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setNewAgentMargin('');
                                            setShowSelectedFlightModal(prevState => ({
                                                ...prevState,
                                                agentMargin: 0
                                            }));
                                        }}

                                        style={{
                                            position: "absolute",
                                            right: "10px",
                                            top: "37%",
                                            transform: "translateY(-50%)",
                                            border: "none",
                                            background: "transparent",
                                            cursor: "pointer",
                                        }}
                                    >
                                        ✖
                                    </button>
                                ) : null}
                            </div>
                            <br/>
                            <div className={'d-flex justify-content-end'}>
                                <button
                                    className="btn btn-primary updateMarginBtn"
                                    onClick={() => events.handleUpdateAgentMargin(newAgentMargin || showSelectedFlightModal.agentMargin)}
                                >
                                    Submit For Current
                                </button>
                                <button className={'btn btn-info updateMarginBtn'}>
                                    Submit For All
                                </button>
                            </div>
                        </div>

                    </div>
                </Modal>

            )}*/}
        </> : null
    )
}

export default FlightDetailsBox
