import React, {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import commonFxns from "../../components/commonFxns";
import blackHeader from "../../assets/img/black-header.png";
import countryFlagList from "../../../src/components/Elements/PhoneInput/countryFlag.json"
import currencyList from "../../assets/currencyList.json"

const languageList = [
    {name: "English", code: "en"},
    {name: "Deutsch", code: "de"},
]
const CountryFlagComponent = (props) => {
    let {value} = props;
    return (
        <li className="nav-item dropdown ">
            <a className="nav-link d-flex align-items-center gap-2 dropdown-toggle" href="#"
               role="button"
               data-bs-toggle="dropdown"
               aria-expanded="false">
                🇮🇳
            </a>
            <div className={'dropdown-menu country-flag-list-box'}>
                <h6 className={'title'}>
                    Country/Region
                </h6>
                <ul>
                    {countryFlagList.map((item) => {
                        return (
                            item.name && <li className={`${item.isoCode == value ? "active" : ""}`}>
                                {item.emoji} &nbsp;{item.name}
                            </li>
                        )
                    })}
                </ul>
            </div>

        </li>
    )
}
const LanguageFlagComponent = (props) => {
    let {value} = props;
    return (
        <li className="nav-item dropdown ">
            <a className="nav-link d-flex align-items-center gap-2 dropdown-toggle" href="#"
               role="button"
               data-bs-toggle="dropdown"
               aria-expanded="false">
                EN
            </a>
            <div className={'dropdown-menu country-flag-list-box'}>
                <h6 className={'title'}>
                    Language
                </h6>
                <ul>
                    {languageList.map((item) => {
                        return (
                            item.name && <li className={`${item.code == value ? "active" : ""}`}>
                                <b>{item.code}</b> &nbsp;{item.name}
                            </li>
                        )
                    })}
                </ul>
            </div>

        </li>
    )
}
const CurrencyListComponent = (props) => {
    let {value} = props;
    return (
        <li className="nav-item dropdown">
            <a className="nav-link d-flex align-items-center gap-2 dropdown-toggle" href="#"
               role="button"
               data-bs-toggle="dropdown"
               aria-expanded="false">
                INR
            </a>
            <div className={'dropdown-menu country-flag-list-box'}>
                <h6 className={'title'}>
                    Currency
                </h6>
                <ul>
                    {currencyList.map((item) => {
                        return (
                            item.name_plural && <li className={`${item.code == value ? "active" : ""}`}>
                                <b className={'currency-code'}>{item.code}</b>&nbsp;{item.name_plural}
                            </li>
                        )
                    })}
                </ul>
            </div>

        </li>
    )
}
const LoginHeader = (props) => {
    let {currentUser, dispatch} = props;
    let {pathname} = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    let isSearchFlight = pathname == "/find-booking"
    let logoUrl = isSearchFlight ? "../images/logo.png" : "../images2/x-travel-landscape.png"
    let scrollClass = isSearchFlight ? "fixed-header" : "fixed-header white"
    return (
        <>
            <header className={isSearchFlight ? "" : 'dashboard'}>
                {isSearchFlight ? <img src={blackHeader} className={'header-main'}/> : ""}

                {/* Fixed navbar */}

                <nav className={`navbar navbar-expand-md navbar-dark fixed-top ${isScrolled ? scrollClass : ""}`}
                     style={{background: isSearchFlight ? "transparent" : "inherit"}}>
                    <div className="container-fluid">
                        <NavLink className="navbar-brand" to="/find-booking">
                            <img src={logoUrl} alt="logo" height="90"/>
                        </NavLink>
                        <button
                            className="navbar-toggler collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse"
                            aria-controls="navbarCollapse"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        ><span className="navbar-toggler-icon"/>
                        </button>
                        <div className="navbar-collapse collapse" id="navbarCollapse">
                            <ul className={`navbar-nav ms-auto mb-2 mb-md-0 align-items-center ${isSearchFlight && "white"}`}>
                                <li className="nav-item">
                                <span>
                                    <div className="pic">
                                        <img
                                            src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                            alt="User"
                                        />
                                    </div>
                                    Hello {currentUser && currentUser.name ? currentUser.name : ""}
                                </span>
                                </li>
                                <li className="nav-item">
                                <span className="d-flex align-items-center gap-2">
                                    <img src="/images2/wallet-color-icon.png" width="25" alt="wallet-icon"/>
                                    My Wallet: INR 72,365.51
                                    <button className="btn p-0 border-0">
                                        <img src="/images2/add-icon.png" alt="add-icon"/>
                                    </button>
                                </span>
                                </li>
                                {/*   <li className="nav-item">
                                <span className="d-flex align-items-center gap-2">
                                    <img src="../images2/wallet-icon.png" alt="wallet-icon" width="25" className={'filter-icon'}/>
                                    My Cash: 100.00
                                </span>
                            </li>*/}
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link d-flex align-items-center gap-2 dropdown-toggle"
                                        href="#"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <img src="../images2/manager-icon.png" alt="manager-icon" width="25"
                                             className={'filter-icon'}/>Your Manager
                                    </a>
                                    <ul className="dropdown-menu manage-box">
                                        <div className="d-flex gap-3"
                                             style={{borderBottom: "1px solid #d0d8e0", paddingBottom: "10px"}}>
                                        <span className="pic m-0">
                                            <img
                                                src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                alt="Manager"
                                            />
                                        </span>
                                            <h5 className="m-0">
                                                Harpreet Kaur
                                                <span className="d-block mt-1">+91 98888 98888</span>
                                            </h5>
                                        </div>
                                        <li>
                                            <NavLink className="dropdown-item red" to="#">
                                                Schedule a Call with Expert
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="dropdown-item" to="#">
                                                Raise a Complaint
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="dropdown-item" to="#">
                                                Request a Portal Training
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/ticket-list">
                                        Dashboard
                                    </NavLink>
                                </li>

                                <li>
                                    <ul className={'header-group-btn'}>
                                        <CountryFlagComponent value={"IN"}/>
                                        <LanguageFlagComponent value={'en'}/>
                                        <CurrencyListComponent value={'INR'}/>
                                    </ul>
                                </li>


                                <li className="nav-item cursor-pointer" onClick={() => commonFxns.logout(dispatch)}>
                                    Log Out
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default LoginHeader;
