import {ticketListUrl, getBookingDetailsUrl, bookSeatsUrl} from "./api";
import {customAxios as axios} from "../../request";
import {hideLoader, showLoader} from "../../actions/loader";
import {flightsFearQuoteUrl} from "../flightBooking/apis";
import {notification} from "../../components/Elements";

export const ticketListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        params: {...valData}
    }
    let {data} = await axios.get(ticketListUrl(), config)
    dispatch(hideLoader())
    return data;
}

export const getBookingDetailsFxn = (valData) => async (dispatch) => {
    let config = {
        params: {...valData}
    }
    dispatch(showLoader());
    let {data} = await axios.get(getBookingDetailsUrl(), config)
    dispatch(hideLoader())
    return data;
}

export const bookSeatsFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.post(bookSeatsUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success Book Seat'
        })
    } else {
        /*    notification.error({
                  message: data.message || 'Error'
              })*/
    }
    return data;
}


