import React, {useEffect, useState} from "react";
import FlightImg from "../../components/flightImg";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Icon} from "../../components/Elements";
import FlightInfoComponent from "./components/FlightInfoComponent";
import ReviewBookingComponent from "./components/ReviewBookingComponent";
import {FlightSummary, ReturnFlightSummary} from "./components/summary";
import _ from "lodash";
import {flightsFearQuoteFxn, lccFlightTicketFxn} from "../flightBooking/actions";
import Passengers from "./components/passengers";
import TicketResponse from "../ticketResponse";
import PaymentComponent from "./components/paymentComponent";
import ArrowIcon from "../../assets/img/arrow.png";

const RoundTripBooking = (props) => {
    const {flightInfo, selectedFlight, flightObj, traceId, fareObject, bookingType, ssrResponse} = props;
    let {mappedOutBoundFlights, mappedInBoundFlights} = selectedFlight;
    let [state, setState] = useState({});
    let [ticketId, setTicketId] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(1)
    const [selectedPayment, setSelectedPayment] = useState('');
    let [passengerState, setPassengerState] = useState({})
    let [bookingFlightData, setBookingFlightData] = useState({})

    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
    };
    useEffect(() => {
        events.setFlightBookingFxn()
    }, [bookingType])
    const events = {
        setFlightBookingFxn: () => {
            let bookingFlightData = bookingType == "Inbound" ? selectedFlight['mappedInBoundFlights'] : selectedFlight['mappedOutBoundFlights'];
            setBookingFlightData(bookingFlightData)
        },
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },

        addPassenger: () => {
            setActiveTab(2)
        },
        bookTicket: async () => {
            let {adultPassengers = [], childPassengers = [], infantPassengers = [], contactState = {}} = passengerState;
            let allPassenger = [];
            if (adultPassengers && adultPassengers.length) {
                _.each(adultPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 1})
                })
            }
            if (childPassengers && childPassengers.length) {
                _.each(childPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 2})
                })
            }
            if (infantPassengers && infantPassengers.length) {
                _.each(infantPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 3})
                })
            }
            let obj = {
                traceId: traceId,
                resultIndex: bookingFlightData['ResultIndex'],
                allPassenger,
                fareQuote: fareObject,
                isLcc: bookingFlightData['IsLCC'],
                ...passengerState.state,
                ...contactState
            }
            let {ticketId, sessionId, success} = await dispatch(lccFlightTicketFxn(obj));
            if (success) {
                setTicketId(ticketId);
                dispatch({type: "UPDATE_SESSION_ID", sessionId: sessionId})
                // navigate(`/response/${ticketId}`, {state: {params: {sessionId}}});
                navigate(`/response/${ticketId}?sessionId=${sessionId}`, {state: {params: {sessionId}}});

            } else {
                navigate(`/response/${ticketId}`, {state: {params: {sessionId}}});
            }
        },
        goBackToPreviousTab: () => {
            if (activeTab > 0) {
                setActiveTab(activeTab - 1);
            }
        }
    };

    return (
        <div className="checkout-box bg-gray">
            <div className="flight-booking bg-white spacing-40">
                <div className="container position-relative">
                    <div className="col-lg-12">
                        <ul className="nav nav-tabs border-0 gap-3" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link  rounded-pill border-0 ${activeTab == 1 ? "active" : ""}`}
                                    id="flight-itinerary-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#flight-itinerary"
                                    type="button"
                                    role="tab"
                                    aria-controls="flight-itinerary"
                                    disabled={activeTab !== 1}
                                    aria-selected="true">
                                    <Icon type={'plane'}/>
                                    Flight Itinerary
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link  rounded-pill border-0 ${activeTab == 2 ? "active" : ""}`}
                                    id="passenger-details-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#passenger-details"
                                    type="button"
                                    role="tab"
                                    disabled={activeTab !== 2}
                                    aria-controls="passenger-details"
                                    aria-selected="false">
                                    Passenger Details
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link  rounded-pill border-0 ${activeTab == 3 ? "active" : ""}`}
                                    id="review-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#review"
                                    type="button"
                                    role="tab"
                                    disabled={activeTab !== 3}
                                    aria-controls="review"
                                    aria-selected="false"
                                >
                                    Review
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link  rounded-pill border-0 ${activeTab == 4 ? "active" : ""}`}
                                    id="payment-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#payment"
                                    type="button"
                                    role="tab"
                                    disabled={activeTab !== 4}
                                    aria-controls="payment"
                                    aria-selected="false">
                                    Payment
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className="spacing-40 container">

                <div className="row row-gap-3">
                    <div className="col-lg-9">
                        <div className="head d-flex gap-3 align-items-center mb-3">
                            <span>Visa, Passport & Health Regulations</span>
                            <button
                                className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2"
                                onClick={() => window.history.back()}>
                                Back to Search
                                <img src={ArrowIcon} alt={'arrow'}/>
                            </button>
                        </div>
                        <div className="find-flight-main">
                            <FlightInfoComponent {...props} bookingType={bookingType}/>

                            {/*<div className="accordion-flight-track">
                                <p className="note">
                                    Require to change Plane - Layover Time: 3h 15m
                                </p>
                            </div>*/}

                        </div>


                        <div className="tab-content" id="myTabContent">
                            <div className={`tab-pane fade ${activeTab == 1 ? "show active" : ""}`}
                                 id="flight-itinerary" role="tabpanel"
                                 aria-labelledby="flight-itinerary-tab">

                                <div className="find-flight-main">

                                    <button className="btn btn-primary w-100 mt-3"
                                            onClick={() => events.addPassenger()}>ADD
                                        PASSENGERS DETAILS
                                    </button>
                                </div>
                            </div>


                            <div className={`tab-pane fade ${activeTab == 2 ? "show active" : ""}`}
                                 id="passenger-details" role="tabpanel"
                                 aria-labelledby="passenger-details-tab">
                                <Passengers
                                    {...props} flightObj={flightObj}
                                    selectedFlight={bookingFlightData}
                                    ssrResponse={ssrResponse}
                                    callbackState={(resp) => {
                                        setPassengerState(resp)
                                        setActiveTab(3)
                                    }}/>
                            </div>


                            <div className={`tab-pane fade ${activeTab === 3 ? "show active" : ""}`}
                                 id="review"
                                 role="tabpanel"
                                 aria-labelledby="review-tab">
                                {/*   <ReviewBookingComponent
                                    passengerState={passengerState}
                                    fareObject={fareObject}
                                    traceId={traceId}
                                    state={state}
                                    selectedFlight={selectedFlight}

                                    callbackState={() => {
                                        setActiveTab(4)
                                    }}/>*/}

                                <ReviewBookingComponent
                                    passengerState={passengerState}
                                    fareObject={fareObject}
                                    traceId={traceId}
                                    selectedFlight={bookingFlightData}
                                    flightObj={flightObj}
                                    callbackState={() => {
                                        setActiveTab(4)
                                    }}
                                    // bookState={events.holdBookingFxn}
                                />

                            </div>


                            <div className={`tab-pane fade ${activeTab == 4 ? "show active" : ""}`} id="payment"
                                 role="tabpanel"
                                 aria-labelledby="payment-tab">
                                <PaymentComponent passengerState={passengerState}
                                                  fareObject={fareObject}
                                                  traceId={traceId}
                                                  state={state}
                                                  selectedFlight={selectedFlight}
                                                  callbackState={events.bookTicket}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        {
                            bookingFlightData && bookingFlightData['ResultIndex'] ? (
                                <>
                                    <FlightSummary
                                        flightData={bookingFlightData}
                                        inboundData={mappedInBoundFlights}
                                        outboundData={mappedOutBoundFlights}
                                    />
                                </>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>

    );
};

export default RoundTripBooking;
