import React from "react";

const LastSearchFlightComponent = () => {
    return (
        <>
            last search
        </>
    )
}

export default LastSearchFlightComponent
