import React, {useEffect, useState} from "react";
import {ticketListFxn} from "./action";
import moment from "moment";
import {useDispatch} from "react-redux";
import {Tooltip} from "../../components/Elements";

const TicketListComp = () => {
    const dispatch = useDispatch()
    const [ticketList, setTicketList] = useState([]);
    const events = {
        ticketList: async () => {
            let {data} = await dispatch(ticketListFxn());
            console.log(data, "data");
            setTicketList(data);
        },
    };

    useEffect(() => {
        events.ticketList();
    }, []);

    return (
        <div className="row row-gap-3">
            <div className="col-lg-12">
                <h4 className="mb-4 ">Manage Bookings</h4>
                <div className="ticket-list pe-3">
                    {ticketList.map((ticket, index) => {
                        let {response} = ticket;
                        return response && response?.ResponseStatus == "1" ? (
                            <>
                                <div className="track-flights" key={index}>
                                    <div
                                        className="accordion"
                                        id={`accordionPanelsStayOpenExample${index}`}
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id={`panelsStayOpen-heading${index}`}
                                            >
                                                <ul>
                                                    <li className="p-3 head-accordion manageBookingList">
                                                        <h6 className="pnr">
                                                            <Tooltip title={`View Invoice`}>
                                                                <div>
                                                                    PNR:&nbsp;
                                                                    {ticket &&
                                                                    ticket.response &&
                                                                    ticket.response.Response &&
                                                                    ticket.response.Response.PNR}
                                                                    <a
                                                                        href={`/response/${ticket?._id}`}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        className={'ms-2'}>
                                                                        <img
                                                                            src="/images2/eye.png"
                                                                            width="20"
                                                                            alt="eye-icon"
                                                                        />
                                                                    </a>
                                                                </div>
                                                            </Tooltip>
                                                            <small className={'d-block'}>
                                                                Booking Date:&nbsp;
                                                                {ticket &&
                                                                ticket.response &&
                                                                ticket.response.Response &&
                                                                ticket.response.Response.FlightItinerary &&
                                                                ticket.response.Response.FlightItinerary.InvoiceCreatedOn &&
                                                                moment(ticket.response.Response.FlightItinerary.InvoiceCreatedOn).format('DD/MMM/YY')}
                                                            </small>
                                                        </h6>
                                                        <div className={'d-flex'}>
                                                            <img className="airlineLogoSize"
                                                                 src={`../airlineLogos/${
                                                                     ticket &&
                                                                     ticket.response &&
                                                                     ticket.response.Response &&
                                                                     ticket.response.Response.FlightItinerary &&
                                                                     ticket.response.Response.FlightItinerary.Segments &&
                                                                     ticket.response.Response.FlightItinerary.Segments.length > 0 &&
                                                                     ticket.response.Response.FlightItinerary.Segments[0].Airline &&
                                                                     ticket.response.Response.FlightItinerary.Segments[0].Airline.AirlineCode &&
                                                                     ticket.response.Response.FlightItinerary.Segments[0].Airline.AirlineCode
                                                                 }.gif`} alt=""/>
                                                            <h6 className="name">
                                                                {ticket &&
                                                                ticket.response &&
                                                                ticket.response.Response &&
                                                                ticket.response.Response.FlightItinerary &&
                                                                ticket.response.Response.FlightItinerary.Segments &&
                                                                ticket.response.Response.FlightItinerary.Segments.length > 0 &&
                                                                ticket.response.Response.FlightItinerary.Segments[0].Airline &&
                                                                ticket.response.Response.FlightItinerary.Segments[0].Airline.AirlineName &&
                                                                ticket.response.Response.FlightItinerary.Segments[0].Airline.AirlineName}
                                                                <small className="d-block">
                                                                    {ticket &&
                                                                    ticket.response &&
                                                                    ticket.response.Response &&
                                                                    ticket.response.Response.FlightItinerary &&
                                                                    ticket.response.Response.FlightItinerary.Segments &&
                                                                    ticket.response.Response.FlightItinerary.Segments.length > 0 &&
                                                                    ticket.response.Response.FlightItinerary.Segments[0].Origin &&
                                                                    ticket.response.Response.FlightItinerary.Segments[0].Origin.DepTime &&
                                                                    moment(ticket.response.Response.FlightItinerary.Segments[0].Origin.DepTime).format('DD/MM/YY')}
                                                                </small>
                                                            </h6>

                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <h6 style={{
                                                                justifyContent: "center",
                                                                textAlign: "center",
                                                            }}>
                                                                <small className="d-block">
                                                                    {ticket &&
                                                                    ticket.response &&
                                                                    ticket.response.Response &&
                                                                    ticket.response.Response.FlightItinerary &&
                                                                    ticket.response.Response.FlightItinerary.Origin &&
                                                                    ticket.response.Response.FlightItinerary.Origin}
                                                                    <br/>
                                                                    {ticket &&
                                                                    ticket.response &&
                                                                    ticket.response.Response &&
                                                                    ticket.response.Response.FlightItinerary &&
                                                                    ticket.response.Response.FlightItinerary.Segments &&
                                                                    ticket.response.Response.FlightItinerary.Segments.length > 0 &&
                                                                    ticket.response.Response.FlightItinerary.Segments[0].Origin &&
                                                                    ticket.response.Response.FlightItinerary.Segments[0].Origin.DepTime &&
                                                                    moment(ticket.response.Response.FlightItinerary.Segments[0].Origin.DepTime).format('HH:MM A')}
                                                                </small>
                                                            </h6>
                                                            <span className="mx-4">
                                                                <img src="images2/plane.png" alt="plane"/>
                                                              </span>
                                                            <h6
                                                                style={{
                                                                    justifyContent: "center",
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                <small className="d-block me-3">
                                                                    {ticket &&
                                                                    ticket.response &&
                                                                    ticket.response.Response &&
                                                                    ticket.response.Response.FlightItinerary &&
                                                                    ticket.response.Response.FlightItinerary.Destination &&
                                                                    ticket.response.Response.FlightItinerary.Destination}
                                                                    <br/>
                                                                    {ticket &&
                                                                    ticket.response &&
                                                                    ticket.response.Response &&
                                                                    ticket.response.Response.FlightItinerary &&
                                                                    ticket.response.Response.FlightItinerary.Segments &&
                                                                    ticket.response.Response.FlightItinerary.Segments.length > 0 &&
                                                                    ticket.response.Response.FlightItinerary.Segments[0].Origin &&
                                                                    ticket.response.Response.FlightItinerary.Segments[0].Origin.DepTime &&
                                                                    moment(ticket.response.Response.FlightItinerary.Segments[0].Origin.ArrTime).format('HH:MM A')}
                                                                </small>
                                                            </h6>
                                                        </div>
                                                        <h6 className="passengerBlock d-flex">
                                                            {ticket &&
                                                            ticket.Passengers &&
                                                            ticket.Passengers.map((passenger, index) => (
                                                                <a
                                                                    href={`/ticket-details?ticketId=${ticket?._id}&firstName=${passenger.FirstName}&lastName=${passenger.LastName}`}
                                                                    target="_blank"
                                                                    key={index}
                                                                    className={'passengersStyle'}>
                                                                    <small className="d-block">
                                                                        <Tooltip
                                                                            title={`View Ticket - ${passenger.FirstName} ${passenger.LastName}`}>
                                                                            {passenger.FirstName} {passenger.LastName}
                                                                            {index !== ticket.Passengers.length - 1 && ','}
                                                                        </Tooltip>
                                                                    </small>
                                                                </a>
                                                            ))}
                                                        </h6>

                                                        <div className={'d-flex align-items-center'}>
                                                            <Tooltip title={`Invoice`}>
                                                                <a href={`/booking-details?ticketId=${ticket?._id}`}
                                                                   className="btn btn-primary min-width-btn w-50 mr10"
                                                                   target="_blank">
                                                                    Invoice
                                                                </a>
                                                            </Tooltip>
                                                            <Tooltip title={`Delete`}>
                                                                <a href={''} className="btn p-0 mr10">
                                                                    <img
                                                                        src="..//images2/delete.png"
                                                                        width={28}
                                                                        alt="eye-icon"
                                                                    />
                                                                </a>
                                                            </Tooltip>
                                                            <a
                                                                href=""
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#panelsStayOpen-collapse${index}`}
                                                                aria-expanded="true"
                                                                aria-controls={`panelsStayOpen-collapse${index}`}
                                                                className="btn p-0">
                                                                <img
                                                                    src="../images2/arrow-down.png"
                                                                    alt="arrow-down"
                                                                    width={28}
                                                                />
                                                            </a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </h2>
                                            <div
                                                id={`panelsStayOpen-collapse${index}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={`panelsStayOpen-heading${index}`}
                                            >
                                                <div className="accordion-body">
                                                    <ul>
                                                        <li className="manageBookingList">
                                                            <h6>
                                                                {ticket &&
                                                                ticket.Passengers &&
                                                                ticket.Passengers.length
                                                                    ? ticket.Passengers.length
                                                                    : 0}{" "}
                                                                <small className="d-block">Passengers</small>
                                                            </h6>
                                                            <h6>
                                                                {ticket &&
                                                                ticket.response &&
                                                                ticket.response.Response &&
                                                                ticket.response.Response.FlightItinerary &&
                                                                ticket.response.Response.FlightItinerary
                                                                    .Segments &&
                                                                ticket.response.Response.FlightItinerary
                                                                    .Segments[0] &&
                                                                ticket.response.Response.FlightItinerary
                                                                    .Segments[0].Origin &&
                                                                ticket.response.Response.FlightItinerary
                                                                    .Segments[0].Origin.DepTime &&
                                                                moment(
                                                                    ticket.response.Response.FlightItinerary
                                                                        .Segments[0].Origin.DepTime
                                                                ).format("DD MMM, YY")}
                                                                <small className="d-block">Departure</small>
                                                            </h6>
                                                            <h6>
                                                                Departure Time{" "}
                                                                <small className="d-block">
                                                                    {ticket &&
                                                                    ticket.response &&
                                                                    ticket.response.Response &&
                                                                    ticket.response.Response.FlightItinerary &&
                                                                    ticket.response.Response.FlightItinerary
                                                                        .Segments &&
                                                                    ticket.response.Response.FlightItinerary
                                                                        .Segments[0] &&
                                                                    ticket.response.Response.FlightItinerary
                                                                        .Segments[0].Origin &&
                                                                    ticket.response.Response.FlightItinerary
                                                                        .Segments[0].Origin.DepTime &&
                                                                    moment(
                                                                        ticket.response.Response.FlightItinerary
                                                                            .Segments[0].Origin.DepTime
                                                                    ).format("HH:mm A")}
                                                                </small>
                                                            </h6>
                                                            <h6>
                                                                {ticket &&
                                                                ticket.response &&
                                                                ticket.response.Response &&
                                                                ticket.response.Response.FlightItinerary &&
                                                                ticket.response.Response.FlightItinerary
                                                                    .Segments &&
                                                                ticket.response.Response.FlightItinerary
                                                                    .Segments[0] &&
                                                                ticket.response.Response.FlightItinerary
                                                                    .Segments[0].Destination &&
                                                                ticket.response.Response.FlightItinerary
                                                                    .Segments[0].Destination.ArrTime &&
                                                                moment(
                                                                    ticket.response.Response.FlightItinerary
                                                                        .Segments[0].Destination.ArrTime
                                                                ).format("DD MMM, YY")}
                                                                <small className="d-block">Arrival</small>
                                                            </h6>
                                                            <h6>
                                                                Arrival Time{" "}
                                                                <small className="d-block">
                                                                    {ticket &&
                                                                    ticket.response &&
                                                                    ticket.response.Response &&
                                                                    ticket.response.Response.FlightItinerary &&
                                                                    ticket.response.Response.FlightItinerary
                                                                        .Segments &&
                                                                    ticket.response.Response.FlightItinerary
                                                                        .Segments[0] &&
                                                                    ticket.response.Response.FlightItinerary
                                                                        .Segments[0].Destination &&
                                                                    ticket.response.Response.FlightItinerary
                                                                        .Segments[0].Destination.ArrTime &&
                                                                    moment(
                                                                        ticket.response.Response.FlightItinerary
                                                                            .Segments[0].Destination.ArrTime
                                                                    ).format("HH:mm A")}
                                                                </small>
                                                            </h6>
                                                            <h6>
                                                                Class:{" "}
                                                                <small className="d-block">Business</small>
                                                            </h6>
                                                            <h6>
                                                                Journey Time:{" "}
                                                                <small className="d-block">
                                                                    {ticket &&
                                                                    ticket.response &&
                                                                    ticket.response.Response &&
                                                                    ticket.response.Response.FlightItinerary &&
                                                                    ticket.response.Response.FlightItinerary
                                                                        .Segments &&
                                                                    ticket.response.Response.FlightItinerary
                                                                        .Segments[0].Duration &&
                                                                    `${Math.floor(
                                                                        ticket.response.Response.FlightItinerary
                                                                            .Segments[0].Duration / 60
                                                                    )}h ${
                                                                        ticket.response.Response.FlightItinerary
                                                                            .Segments[0].Duration % 60
                                                                    }m`}
                                                                </small>
                                                            </h6>
                                                        </li>
                                                        {/* <div
                                                            className="d-flex flex-row"
                                                            style={{
                                                                border: "1px solid #ddd",
                                                                borderRadius: "8px",
                                                                padding: "10px",
                                                                width: "fit-content",
                                                            }}
                                                        >
                                                            <h6 className={'mt10'}>Passengers:</h6>
                                                            {ticket && ticket.Passengers && (
                                                                <small className="btn btn-link">
                                                                    {ticket.Passengers.map((passenger, idx) => (
                                                                        <a
                                                                            className={idx > 0 ? 'mt10' : ''}
                                                                            key={idx}
                                                                            // href={`/ticket-details?bookingId=${ticket?.response?.Response?.BookingId}&pnr=${ticket?.response?.Response?.PNR}&firstName=${passenger.FirstName}&lastName=${passenger.LastName}&traceId=${ticket?.TraceId}`}
                                                                            // target="_blank"
                                                                            // rel="noopener noreferrer"
                                                                            href={`/ticket-details?ticketId=${ticket?._id}&firstName=${passenger.FirstName}&lastName=${passenger.LastName}`}
                                                                            target="_blank"
                                                                        >
                                                                            {idx + 1}. {passenger.FirstName} {passenger.LastName}
                                                                            <br/>
                                                                        </a>

                                                                    ))}
                                                                </small>
                                                            )}

                                                        </div>*/}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null;
                    })}
                </div>
            </div>
        </div>
    );
};

export default TicketListComp;
