import { Card } from "../../../components/Elements";
import React from "react";

const ContactDetailsComponent = (props) => {
    const { adultPassengers, contactState } = props;



    return (
        <>
            <Card title={'Contact Details'} round={12}>
                <div className={'row'}>

                        <div className={'col-md-6'}>
                            <b>Contact No</b>: +{contactState?.contactNo || ""}<br />
                        </div>


                    <div className={'col-md-6'}>

                        <b>Email ID</b>: {contactState?.email || ""}<br />
                    </div>
                </div>
            </Card>
        </>
    );
};

export default ContactDetailsComponent;
