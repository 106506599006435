import React from "react"
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import "./index.css"

const TooltipComponent = (props) => {
    let {children, position = 'top', title, className = ''} = props;
    return (
        <Tooltip overlay={title} placement={position} overlayStyle={{cursor: "pointer"}}
                 overlayClassName={"tooltip-box"}>
            <div className={className}>
                {children}
            </div>
        </Tooltip>
    )
}
export default TooltipComponent
