import React, {useEffect, useState} from "react";
import {bookSeatsFxn} from "../../ticketList/action";
import {useDispatch, useSelector} from "react-redux";
import SeatsLayoutModal from "../../ModalComponent/seatLayout";
import BadgeIcon from "../../../assets/img/badge.png";
import FoodIcon from "../../../assets/img/food.png";
import BaggageIcon from "../../../assets/img/baggage-icon-png-24182.png";
import SeatIcon from "../../../assets/img/seat.png";
import {InputBox} from "../../../components/Elements";

const AncillaryServiceForm = (props) => {
    const {
        adultPassengers = [],
        childPassengers = [], fareObj,
        updateAncillaryServices,
        infantPassengers = [],
        ssrResponse,
        selectedFlight
    } = props;
    const {flightObj, traceId} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj,
        traceId: state.flightsReducer.traceId,
        // selectedFlight: state.flightsReducer.selectedFlight,
    }));

    const dispatch = useDispatch();
    const [selectedPassenger, setSelectedPassenger] = useState(0);
    const [seatsObj, setSeatsObj] = useState([]);
    const [passengerServices, setPassengerServices] = useState({});
    const [showSeatsModal, setShowSeatsModal] = useState({
        visible: false,
        seatsObj: [],
    });
    const [selectedSeat, setSelectedSeat] = useState(null);
    useEffect(() => {
        getBookSeatsUrl();
    }, [traceId, selectedFlight]);
    const getBookSeatsUrl = async () => {
        if (ssrResponse && ssrResponse['SeatDynamic'] && ssrResponse['SeatDynamic'][0] && ssrResponse['SeatDynamic'][0]['SegmentSeat']) {
            setSeatsObj(ssrResponse['SeatDynamic'][0]['SegmentSeat']);
        }
    };

    const handlePassengerSelection = (passengerIndex) => {
        setSelectedPassenger(passengerIndex);
    };

    const handleSeatSelection = (seat) => {
        setSelectedSeat(seat);
        handleServiceChange("seat", seat);
        setShowSeatsModal({visible: false, seatsObj});
    };

    const formatBaggageOption = (baggage) =>
        `${baggage.Weight}-Kg ₹${baggage.Price}`;
    const handleServiceChange = (field, value) => {
        const updatedServices = {
            ...passengerServices[selectedPassenger],
            [field]: value,
        };

        setPassengerServices((prev) => ({
            ...prev,
            [selectedPassenger]: updatedServices,
        }));

        const updatedPassengers = [
            ...adultPassengers,
            ...childPassengers,
        ];

        const allUpdatedPassengerDetails = updatedPassengers.map((passenger, index) => {
            const ancillaryService = passengerServices[index] || {};
            return {
                ...passenger,
                ancillaryService: {
                    seat: ancillaryService?.seat,
                    baggage: ancillaryService?.baggage,
                    meal: ancillaryService?.meal,
                    specialServices: ancillaryService?.specialServices || [],
                },
            };
        });

        const finalPassengerDetails = Array.isArray(allUpdatedPassengerDetails)
            ? allUpdatedPassengerDetails
            : [allUpdatedPassengerDetails];

        updateAncillaryServices(finalPassengerDetails);
    };


    const handleSpecialServiceChange = (service, checked) => {
        const updatedSpecialServices = checked
            ? [
                ...(passengerServices[selectedPassenger]?.specialServices || []),
                service,
            ]
            : (passengerServices[selectedPassenger]?.specialServices || []).filter(
                (s) => s.Code !== service.Code
            );


        handleServiceChange("specialServices", updatedSpecialServices);
    };

    const renderPassengerCard = (passenger, title, index, isInfant = false) => {
        const services = passengerServices[index] || {};
        return (
            <div
                key={index}
                className={`card p-3 mb-3 ${
                    selectedPassenger === index ? "border-primary" : ""
                }`}
                onClick={() => handlePassengerSelection(index)}
                style={{cursor: "pointer"}}
            >
                <label htmlFor={`passenger-${index}`} className="w-100">
                    <div className="d-flex justify-content-between align-items-center">
                        <span>
                           <input
                               type="radio"
                               id={`passenger-${index}`}
                               name="passenger"
                               value={index}
                               checked={selectedPassenger === index}
                               onChange={() => handlePassengerSelection(index)}
                               className="mr-2"
                           />
                            <b className={'ms-2'}>   {title} </b>
                        </span>
                    </div>
                    <div className="mt-2 text-muted">
                        <span className="text-muted">
                            <img src={SeatIcon} alt={'baggage'} style={{height: 15, marginRight: 5}}/>
                            {services.seat
                                ? `${services.seat.RowNo}${services.seat.SeatNo}`
                                : "0"}
                        </span>
                        <small>
                            <img src={BaggageIcon} alt={'baggage'}
                                 style={{height: 18, marginRight: 5}}/> {services.baggage
                            ? `${formatBaggageOption(services.baggage)} kg`
                            : "0 kg"}
                        </small>
                    </div>
                    {services.meal && (
                        <div className="mt-2 text-muted">
                            <img src={FoodIcon} alt={'baggage'} style={{height: 18, marginRight: 5}}/>
                            <small>{services.meal.AirlineDescription}</small>
                        </div>
                    )}
                    {/*{services.specialServices && services.specialServices.length > 0 && (*/}
                    {/*    <div className="mt-2 text-muted">*/}
                    {/*        <img src={BadgeIcon} alt={'baggage'} style={{height: 18, marginRight: 5}}/> <small>*/}
                    {/*        {" "}*/}
                    {/*        {services.specialServices*/}
                    {/*            .map((service) => service.Description)*/}
                    {/*            .join(", ")}*/}
                    {/*    </small>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </label>
            </div>
        );
    };

    const renderInfantMessage = () => (
        <div className="text-muted">
            <p>Baggage OR Meal Preference is not available for Infant.</p>
        </div>
    );

    return (
        <>
            <form>
                <div className="row">
                    <div className="col-md-4">
                        <h6>Select Passenger</h6>
                        {adultPassengers.map((passenger, index) =>
                            renderPassengerCard(passenger, 'ADULT', index)
                        )}
                        {childPassengers.map((passenger, index) =>
                            renderPassengerCard(passenger, 'CHILD', adultPassengers.length + index)
                        )}

                    </div>

                    <div className="col-md-7 card p-4">

                        <>
                            <div className={'mt-3'}>
                                <InputBox

                                    icon={<img src={BaggageIcon} alt={"baggage"} style={{height: 18, marginRight: 5}}/>}
                                    title={"Baggage"}
                                >
                                    <select
                                        id="baggage"
                                        className="form-control"
                                        value={passengerServices[selectedPassenger]?.baggage?.Code || ""}
                                        onChange={(e) => {
                                            const selectedBaggage = ssrResponse.Baggage?.[0]?.find(
                                                (baggage) => baggage.Code === e.target.value
                                            );
                                            handleServiceChange("baggage", selectedBaggage);
                                        }}
                                    >
                                        <option value="">Select baggage weight</option>
                                        {(ssrResponse.Baggage?.[0] || []).map((baggage, index) => (
                                            <option key={index} value={baggage.Code}>
                                                {formatBaggageOption(baggage)}
                                            </option>
                                        ))}
                                    </select>
                                </InputBox>
                            </div>
                            <div className={'mt-3'}>
                                <InputBox
                                    icon={<img src={FoodIcon} alt={"meal"} style={{height: 18, marginRight: 5}}/>}
                                    title={"Meal"}
                                >
                                    <select
                                        id="meal"
                                        className="form-control"
                                        value={passengerServices[selectedPassenger]?.meal?.Code || ""}
                                        onChange={(e) => {
                                            const selectedMeal = ssrResponse.MealDynamic?.[0]?.find(
                                                (meal) => meal.Code === e.target.value
                                            );
                                            handleServiceChange("meal", selectedMeal);
                                        }}
                                    >
                                        <option value="">Select meal preference</option>
                                        {(ssrResponse.MealDynamic?.[0] || []).map((meal, index) => (
                                            <option key={index} value={meal.Code}>
                                                {meal.AirlineDescription} - ₹{meal.Price}
                                            </option>
                                        ))}
                                    </select>
                                </InputBox>
                            </div>
                            <div className="mb-2 mt-3">
                                <InputBox

                                    icon={<img src={BadgeIcon} alt={'special services'}
                                               style={{height: 18, marginRight: 5}}/>}
                                    title={"Special Services"}
                                >
                                    {ssrResponse.SpecialServices?.[0]?.SegmentSpecialService?.[0]?.SSRService?.map(
                                        (service, index) => {
                                            const isCheckbox =
                                                service.Text === "Priority Check-In" || service.Text === "Lounge Access";

                                            return (
                                                <div key={index} className="form-check">
                                                    <input
                                                        type={isCheckbox ? "checkbox" : "radio"}
                                                        id={`specialService-${service.Code}`}
                                                        className="form-check-input"
                                                        value={service.Code}
                                                        name={!isCheckbox ? "specialServiceRadio" : undefined}
                                                        checked={passengerServices[selectedPassenger]?.specialServices?.some(
                                                            (s) => s.Code === service.Code
                                                        )}
                                                        onChange={(e) => {
                                                            if (isCheckbox) {
                                                                handleSpecialServiceChange(service, e.target.checked);
                                                            } else {
                                                                // Only one service can be selected, so directly update the array
                                                                handleServiceChange("specialServices", [service]);
                                                            }
                                                        }}
                                                    />
                                                    <label
                                                        htmlFor={`specialService-${service.Code}`}
                                                        className="form-check-label option-form"
                                                    >
                                                        {service.Text} - <b>₹{service.Price} {service.Currency}</b>
                                                    </label>
                                                </div>
                                            );
                                        }
                                    )}
                                </InputBox>
                            </div>


                            <div className="mb-2 mt-3">
                                <InputBox
                                    icon={<img src={SeatIcon} alt={'baggage'} style={{height: 18, marginRight: 5}}/>}
                                    title={"Seat Preference"}
                                    extra={(
                                        <button
                                            type="button"
                                            className="btn btn-primary ms-3"
                                            onClick={() => setShowSeatsModal({visible: true, seatsObj})}
                                        >
                                            Select Seats
                                        </button>
                                    )}
                                />
                            </div>

                            {selectedSeat && (
                                <div className="mt-3">
                                    <h6>
                                        Selected Seat: ({selectedSeat.RowNo}
                                        {selectedSeat.SeatNo})
                                        - <span>{selectedSeat.Price} {selectedSeat.Currency}</span>
                                    </h6>

                                </div>
                            )}
                        </>

                    </div>
                </div>

                {showSeatsModal.visible && (
                    <SeatsLayoutModal
                        visible={showSeatsModal.visible}
                        onClose={() => setShowSeatsModal({visible: false})}
                        seatsObj={seatsObj}
                        selectedFlight={selectedFlight}
                        onSeatConfirm={handleSeatSelection}
                    />
                )}
            </form>
        </>
    );
};

export default AncillaryServiceForm;
