import React, {Suspense} from "react";
import {
    Route,
    BrowserRouter as Router,
    Routes, Navigate
} from "react-router-dom";

import MainLayout from "../layout/mainLayout"
import MainInnerLayout from "../layout/mainLayout/innerLayout"
import FrontLayout from "../layout/frontLayout"
import AuthLayout from "../layout/authLayout"
import FullPgeLayouts from "../layout/authLayout"
import {history} from "../reducers/store";
import {frontMenu, menu, authMenu, innerMenu, fullPageMenu} from "./routeMenus";
import {connect} from "react-redux";
import ScrollToTop from "../web/scrollTopComponent";
import FullPageLayout from "../layout/fullPageLayout";

const RouteList = (props) => {
    let {currentUser = {}} = props;
    return (
        <>
            <Router history={history}>
                <ScrollToTop/>
                <Routes>
                    <Route path={"/"} element={<FrontLayout  {...props}/>}>
                        {frontMenu.map((item) => {
                            let {component: Component, path} = item
                            return (
                                <Route path={path} element={<Component/>} user={currentUser}/>
                            )
                        })}
                    </Route>
                    <Route path={"/"} element={<MainLayout  {...props}/>}>
                        {menu.map((item) => {
                            let {component: Component, path} = item
                            return (
                                <Route path={path} element={<Component/>} user={currentUser}/>
                            )
                        })}
                    </Route>
                    <Route path={"/"} element={<MainInnerLayout  {...props}/>}>
                        {innerMenu.map((item) => {
                            let {component: Component, path} = item
                            return (
                                <Route path={path} element={<Component/>} user={currentUser}/>
                            )
                        })}
                    </Route>
                    <Route path={"/"} element={<AuthLayout  {...props}/>}>
                        {authMenu.map((item) => {
                            let {component: Component, path} = item
                            return (
                                <Route path={path} element={<Component/>} user={currentUser}/>
                            )
                        })}
                    </Route>

                    <Route path={"/"} element={<FullPageLayout  {...props}/>}>
                        {fullPageMenu.map((item) => {
                            let {component: Component, path} = item
                            return (
                                <Route path={path} element={<Component/>} user={currentUser}/>
                            )
                        })}
                    </Route>
                </Routes>
            </Router>
        </>

    );
};

const mapStateToProps = ({global}) => ({
    currentUser: global.currentUser,
    rights: global.rights
})
export default connect(
    mapStateToProps,
    null
)(RouteList)
