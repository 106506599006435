import React from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import Footer from "../footer";
import LoginHeader from "./loginHeader";

const Layout = (props) => {
    let {currentUser} = props;

    return (
        currentUser && currentUser._id ? <>
                <LoginHeader  {...props}/>
                <main className="flex-shrink-0">
                    <Outlet/>
                </main>
                <Footer/>
            </> :
            <Navigate replace to="/login"/>
    );
};

export default Layout;
