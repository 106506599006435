import React, {useEffect, useState} from "react";
import RegisterBanner from "../../components/pageComponents/registerBanner";
import {searchFlightFxn} from "../flightBooking/actions";
import {useDispatch, useSelector} from "react-redux";
import {displayDateOnly} from "../../components/utils/appUtils";
import {
    groupFlightsFxn,
} from "./commonFilterFxns";
import FlightFilters from "./flightFilters";
import SortFlightFilters from "./sortFlightFilters";
import SingleGroupFlight from "./singleGroupFlight";
import ModifySearch from "./components/modifySearch";
import {Tabs, Tooltip} from "../../components/Elements";
import RoundFlightDetailsBox from "./roundTrip/roundFlightDetailsBox";
import RoundTripBook from "./roundTrip/roundTripBook";
import OverseasFlightAddress from "./components/overseasflightDetailsBox";
import FlightDetailsBox from "./components/flightDetailsBox";

let filterArrInit = {
    stopsList: []
}

let refundFilterList = ['Non Refundable', 'Refundable']
const SliderComponent = ({priceRange}) => {
    return (
        <>
            <div className="slider-container">
                <div className="price-slider">
                    {/* Add your slider logic here */}
                </div>
            </div>
        </>
    )
}


const FindFlight = () => {
    const dispatch = useDispatch();
    const {flightObj} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj
    }))
    const [flightObjState, setFlightObjState] = useState(flightObj)
    const [flightList, setFlightList] = useState([])
    const [sortedFlights, setSortedFlights] = useState([]);

    const [inBoundFlightList, setInBoundFlightList] = useState([])
    const [inBoundSortedFlights, setInBoundSortedFlights] = useState([]);

    const [flightInfo, setFlightInfo] = useState({})
    const [showModifySearch, setShowModifySearch] = useState(false)
    const [selectedInboundFlight, setSelectedInboundFlight] = useState(null);
    const [selectedOutboundFlight, setSelectedOutboundFlight] = useState(null);


    const handleFlightSelect = (flight, type) => {
        if (type === "inBound") {
            setSelectedInboundFlight(flight);
        } else if (type === "outBound") {
            setSelectedOutboundFlight(flight);
        }
    };


    const events = {
        searchFlight: async (flightQuery) => {
            let {data, info, inBoundFlightList} = await dispatch(searchFlightFxn(flightQuery));
            setFlightObjState(flightQuery)
            setFlightList(data)
            setInBoundFlightList(inBoundFlightList)
            events.filterOutBoundFlight(data)
            events.filterInBoundFlight(inBoundFlightList)
            setFlightInfo((prevState) => {
                return {
                    ...prevState,
                    ...info
                }
            })
        },
        filterOutBoundFlight: (data) => {
            let groupedData = groupFlightsFxn(data);
            setSortedFlights(groupedData)
            if ((!selectedOutboundFlight || (selectedOutboundFlight && !selectedOutboundFlight['ResultIndex'])) && groupedData && groupedData.length) {
                let {flights} = groupedData[0]
                setSelectedOutboundFlight(flights[0])
            }
        },
        filterInBoundFlight: (data) => {
            let groupedData = groupFlightsFxn(data);
            setInBoundSortedFlights(groupedData)
            if ((!selectedInboundFlight || (selectedInboundFlight && !selectedInboundFlight['ResultIndex'])) && groupedData && groupedData.length) {
                let {flights} = groupedData[0]
                setSelectedInboundFlight(flights[0])
            }
        },
    }

    const toggleModifySearch = () => {
        setShowModifySearch((prev) => !prev);
    };


    useEffect(() => {
        events.searchFlight(flightObj)
    }, [])

    const tabItems = [
        {
            key: '1',
            label: 'Outbound',
            children: (
                <>
                    <FlightFilters
                        title={'Outbound'}
                        flightList={flightList}
                        refundFilterList={refundFilterList}
                        setSortedFlights={events.filterOutBoundFlight}
                    />
                </>
            ),
        },
        {
            key: '2',
            label: 'Inbound',
            children: (
                <>
                    <FlightFilters
                        title={'Inbound'}
                        flightList={inBoundFlightList}
                        refundFilterList={refundFilterList}
                        setSortedFlights={events.filterInBoundFlight}
                    />
                </>
            ),
        },
    ]

    const getPassengerValue = (state) => {
        let valueD = []
        if (state.adultCount) {
            valueD.push(`${state.adultCount} Adult(s)`)
        }
        if (state.childCount) {
            valueD.push(`${state.childCount} Child(s)`)
        }
        if (state.infantCount) {
            valueD.push(`${state.infantCount} Infant(s)`)
        }
        return valueD.join(', ')
    }

    const onWayFlightList = (
        <>
            <SortFlightFilters
                flightList={flightList}
                refundFilterList={refundFilterList}
                setSortedFlights={events.filterOutBoundFlight}/>
            {sortedFlights && sortedFlights.length ?
                sortedFlights.map((item) => {
                    return (
                        <>
                            <FlightDetailsBox flightInfo={flightInfo} data={item.flights}/>
                        </>
                    )
                }) : null}
        </>
    )
    const returnFlightList = (
        <>
            <div className="divide-book-flight sticky-check-box">
                <div className="d-flex mb-3">
                    <div className="col-md-12">
                        <RoundTripBook flightInfo={flightInfo}
                                       mappedInboundFlights={selectedInboundFlight}
                                       mappedOutboundFlights={selectedOutboundFlight}
                        />
                    </div>
                </div>
            </div>
            <div className="divide-book-flight">
                <div className="row row-gap-3 mb-3">
                    <div className="col-lg-6">
                        <div
                            className="d-flex flex-wrap gap-3 align-items-center mb-3">
                            <SortFlightFilters
                                flightList={flightList}
                                refundFilterList={refundFilterList}
                                setSortedFlights={events.filterOutBoundFlight}
                            />
                        </div>
                        <div className={'scrolled-box'}>
                            {sortedFlights && sortedFlights.length ? sortedFlights.map((item) => {
                                let {flights} = item;
                                return (
                                    <RoundFlightDetailsBox
                                        flightInfo={flightInfo}
                                        data={flights}
                                        type={'outBound'}
                                        flightData={selectedOutboundFlight}
                                        onFlightSelect={(data) => {
                                            handleFlightSelect(data, 'outBound')
                                        }}/>
                                )
                            }) : null}
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div
                            className="d-flex flex-wrap gap-3 align-items-center mb-3">
                            <SortFlightFilters
                                flightList={inBoundFlightList}
                                refundFilterList={refundFilterList}
                                setSortedFlights={events.filterInBoundFlight}
                            />
                        </div>
                        <div className={'scrolled-box'}>
                            {inBoundSortedFlights && inBoundSortedFlights.length ? inBoundSortedFlights.map((item) => {
                                let {flights} = item;
                                return (
                                    <RoundFlightDetailsBox
                                        flightInfo={flightInfo}
                                        type={'inBound'}
                                        data={flights}
                                        flightData={selectedInboundFlight}
                                        onFlightSelect={(data) => {
                                            handleFlightSelect(data, 'inBound')
                                        }}/>
                                )
                            }) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    const overseasFlightList = (
        <>
            {flightList && flightList.length ? flightList.map((item) => {
                return (
                    <>
                        <OverseasFlightAddress
                            data={item}
                            flightInfo={flightInfo}
                            key={item.id}/>
                    </>
                )
            }) : null}
        </>
    )

    const filterBoxComponent = (
        <div className={'sticky-box'}>
            {flightObj.journeyType === "2" && inBoundFlightList && inBoundFlightList.length ?
                <Tabs
                    tabPosition="top"
                    items={tabItems}
                    defaultActiveKey="1"
                    className={'w-100'}
                /> :
                <FlightFilters
                    flightList={flightList}
                    refundFilterList={refundFilterList}
                    setSortedFlights={events.filterOutBoundFlight}
                />}
        </div>
    )

    const totalFlightBlock = (
        <>
            {flightObj.journeyType === "2" ? inBoundFlightList && inBoundFlightList.length ? sortedFlights.length : flightList.length : sortedFlights.length}
        </>
    )


    return (
        <>

            <div className="find-flight">
                <div className="flight-top-filters spacing-60">
                    <div className="container">
                        <div className="radio-box mb-3">
                            <button className="btn rounded-pill border-0" type="button">
                                <img src="../images2/book-flights.png" alt="Book Flight"/>
                                Book Flight
                            </button>
                            <ul>
                                <li>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault"
                                           id="roundTrip"/>
                                    <label htmlFor="roundTrip">Round-Trip</label>
                                </li>
                                <li>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault"
                                           id="oneWay"/>
                                    <label htmlFor="oneWay">One-Way</label>
                                </li>
                                <li>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault"
                                           id="multiDestination"/>
                                    <label htmlFor="multiDestination">Multi-Destination</label>
                                </li>
                            </ul>
                        </div>
                        <div className="search-filter">
                            <div className="row row-gap-3 align-items-center">
                                <div className="col-lg-4 col-12">
                                    <div className="double-box">
                                        <div className="content">
                                            <span>From</span>
                                            <h5>{flightObj.origin}</h5>
                                            <small></small>
                                        </div>
                                        <div className="divide-box position-relative">
                                            <img src="../images2/shift.png" alt="Shift Icon"/>
                                        </div>
                                        <div className="content" style={{paddingLeft: '20px'}}>
                                            <span>To</span>
                                            <h5>{flightObj.destination}</h5>
                                            <small></small>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="double-box">
                                        <div className="content">
                                            <span>Depart</span>
                                            <h5>{displayDateOnly(flightObj.preferredDepartureTime)}</h5>
                                            {/*<small>Sunday</small>*/}
                                        </div>
                                        {flightObj.preferredReturnTime ? <>
                                            <div className="divide-box"></div>
                                            <div className="content">
                                                <span>Arrive</span>
                                                <h5>{displayDateOnly(flightObj.preferredReturnTime)}</h5>
                                            </div>
                                        </> : null}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-12">
                                    <div className="double-box">
                                        <div className="content">
                                            <span>Passenger</span>
                                            <h5>
                                                <Tooltip title={getPassengerValue(flightObj)} className={'dot-replace'}>
                                                    {getPassengerValue(flightObj)}
                                                </Tooltip>
                                            </h5>
                                            {/*<small>Business Class</small>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-12">
                                    <button
                                        onClick={toggleModifySearch}
                                        type="button"
                                        className="btn btn-primary w-100"
                                    >
                                        {showModifySearch ? "Hide Modify Search" : "Modify Search"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showModifySearch && (
                        <ModifySearch
                            flightList={flightList}
                            flightObj={flightObj}
                            // setFlightObjState={(updatedObj) => setFlightObjState(updatedObj)}
                            flightSearch={(resp) => {
                                events.searchFlight(resp)
                            }}
                        />
                    )}
                </div>

                <div className="result-flights spacing-60 bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                {filterBoxComponent}
                            </div>
                            <div className="col-lg-9">
                                <div className="find-flight-main">
                                    <div className="d-flex flex-wrap gap-3 align-items-center mb-3">
                                        <h5 className="m-0">
                                            {totalFlightBlock} Available Flights
                                        </h5>
                                        <ul className="ms-auto p-0 d-flex gap-2 mb-0 align-items-center">
                                            <li>Share By:</li>
                                            <li>
                                                <img
                                                    src="/images2/whatsapp-icon.png"
                                                    width="28"
                                                    alt="whatsapp-icon"
                                                />
                                            </li>
                                            <li>
                                                <img
                                                    src="/images2/gmail-icon.png"
                                                    width="28"
                                                    alt="gmail-icon"
                                                />
                                            </li>
                                            <li>
                                                <img
                                                    src="/images2/eye-icon.png"
                                                    width="28"
                                                    alt="eye-icon"
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                    {sortedFlights && sortedFlights.length > 0 ?
                                        <>
                                            {flightObj.journeyType === "1" ?
                                                onWayFlightList :
                                                <>
                                                    {inBoundFlightList && inBoundFlightList.length ?
                                                        returnFlightList : overseasFlightList
                                                    }
                                                </>
                                            }
                                        </> :
                                        <div className="head text-center mb-5">
                                            <h5>No flights found that match your filter criteria.</h5>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <RegisterBanner/>
        </>
    );
};

export default FindFlight;
