import React from "react"

const RegisterBanner = () => {
    return (
        <>
            <section className="bonus spacing-60">
                <div className="shape-circle"/>
                <div className="container">
                    <div className="box position-relative text-center px-3">
                        <div className="col-lg-8 mx-auto col-md-12">
                            <img
                                src="https://images.pexels.com/photos/7683897/pexels-photo-7683897.jpeg?auto=compress&cs=tinysrgb&w=600"
                                alt=""
                            />
                            <div className="overlay"/>
                            <div className="position-relative z-1">
                                <h3 className={'color-white'}>Book now and Earn 20% bonus Miles</h3>
                                <p>
                                    Ready to make your next trip even more rewarding? <br/> With our
                                    limited-time offer, <br/>
                                    every booking brings you closer to your dream destination!
                                </p>
                            </div>
                        </div>
                    </div>
                    <section className="note-banner">
                        <h3 className="m-0 text-uppercase">
                            <a href="register.html">Register Now!</a>
                        </h3>
                    </section>
                </div>
            </section>
        </>
    )
}
export default RegisterBanner
