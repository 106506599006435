import React, {useEffect} from "react"
import ReactPlayer from "react-player";

const HomeBanner = () => {
    useEffect(() => {
        setTimeout(() => {
            if (document.getElementById('unizVideo')) {
                let videoFile = document.getElementById('unizVideo')
                const video = videoFile.querySelector("video");
                if (video) {
                    video.muted = true;
                    video.play()
                }
                console.log(video);
            }
        }, 500)
    }, [])


    return (
        <>
            <div className="banner spacing-60" style={{height: 700}}>
                {/*<video
                    className="w-100"
                    controls={false}
                    id={'unizVideo'}
                    muted={true}
                    loop={true}>
                    <source src='../videos/slider_video.mp4' type='video/mp4'/>
                </video>

                */}

                <ReactPlayer
                    id={'unizVideo'}
                    loop={true}
                    progressInterval={1000}
                    // onReady={true}
                    className="video-player-box" playing={true}
                    url={'../videos/slider_video.mp4'}/>


                {/*<img src="../images/home-banner.jpg" className="w-100"/>*/}
                {/* <div className="container position-relative">
                    <div className="col-lg-11 col-12 ms-auto">
                        <div className="row row-gap-3">
                            <div className="col-lg-5 me-auto">
                                <h1>Powering Your Travel Business with Seamless Flight Bookings</h1>
                                <p className="m-0">Access the Best Deals and Unmatched Flight Options at Your
                                    Fingertips</p>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
        </>
    )
}
export default HomeBanner
