import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FlightInfoBox from "../findFlight/flightInfoBox";
import {GetEachFormFields} from "../../components/utils/formUtils";
import {CustomFormFields} from "../../components/utils/customFormUtils";
import {
    daysArr,
    genderArr,
    monthArr,
    passengerTypeObject,
    titleArr,
    yearArr,
    fixed2Digit, nationalityArr,

} from "../../components/utils/appUtils";
import _ from "lodash"
import {flightsFearQuoteFxn, lccFlightTicketFxn} from "../flightBooking/actions";
import {Icon, Input, notification, Select, PhoneNo, Card} from "../../components/Elements";
import RegisterBanner from "../../components/pageComponents/registerBanner";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import ChangePrice from "../ModalComponent/changePrice";
import FlightImg from "../../components/flightImg";
import Airplane from '../../assets/img/airplane.png'
import ArrowIcon from '../../assets/img/arrow.png'
import ItenaryIcon from '../../assets/img/iternary.png'
import PassengerIcon from '../../assets/img/passenger.png'
import PaymentIcon from '../../assets/img/payment2.png'
import TickIcon from '../../assets/img/tick.png'
import ArrowUpIcon from '../../assets/img/arrowUp.png'
import ReviewIcon from '../../assets/img/review.png'
import Table from "rc-table";
import FlightInfoComponent from "../findFlight/flightInfoComponent";
import {Link} from "react-router-dom";

const SummaryRow = (props) => {
    let {title, published, offered, showIcon = true} = props;
    return (
        <>
            <div className={'summary-grid'}>
                <div>{title}</div>
                <div>
                    {published ?
                        <>
                            {showIcon ? <Icon name={'rupee'}/> : ""}
                            {published}
                        </> : 0}

                </div>
                {/*   {offered !== undefined ? <div>
                    {showIcon ? <Icon name={'rupee'}/> : ""}
                    {offered}
                </div> : null}*/}
            </div>
        </>
    )
}
const ReturnFlightSummary = (props) => {
    let {inboundData, outboundData} = props;

    let {FareBreakdown: fareInbound} = inboundData;
    let {FareBreakdown: fareOutbound} = outboundData;

    let taxEvents = {
        getSingleTax: (data, taxName) => {
            let findD = _.find(data, (item) => {
                return item.key == taxName;
            });
            return findD ? findD.value : 0;
        },
        getOtherTax: (data) => {
            let totalTax = 0;
            _.each(data, (item) => {
                if (!['YQTax', 'YR'].includes(item.key)) {
                    totalTax += item.value;
                }
            });
            return totalTax;
        },
    };

    const calculateTotals = (fareBreakdown) => {
        return fareBreakdown.reduce(
            (acc, item) => {
                const baseFare = item.BaseFare || 0;
                const tax = item.Tax || 0;
                const otherTax = taxEvents.getOtherTax(item.TaxBreakUp);
                const yqTax = taxEvents.getSingleTax(item.TaxBreakUp, 'YQTax');
                const yrTax = taxEvents.getSingleTax(item.TaxBreakUp, 'YR');

                acc.totalBaseFare += baseFare;
                acc.totalTax += tax;
                acc.totalOtherTax += otherTax;
                acc.totalYQTax += yqTax;
                acc.totalYRTax += yrTax;
                acc.totalAmount += baseFare + tax;

                return acc;
            },
            {
                totalBaseFare: 0,
                totalTax: 0,
                totalOtherTax: 0,
                totalYQTax: 0,
                totalYRTax: 0,
                totalAmount: 0,
            }
        );
    };

    const inboundTotals = calculateTotals(fareInbound || []);
    const outboundTotals = calculateTotals(fareOutbound || []);


    const combinedTotals = {
        totalBaseFare: inboundTotals.totalBaseFare + outboundTotals.totalBaseFare,
        totalTax: inboundTotals.totalTax + outboundTotals.totalTax,
        totalOtherTax: inboundTotals.totalOtherTax + outboundTotals.totalOtherTax,
        totalYQTax: inboundTotals.totalYQTax + outboundTotals.totalYQTax,
        totalYRTax: inboundTotals.totalYRTax + outboundTotals.totalYRTax,
        totalAmount: inboundTotals.totalAmount + outboundTotals.totalAmount,
    };

    return (
        <>
            <div className="detail-box">
                <h4>Fare Summary</h4>
                <SummaryRow published={'Published'} offered={'Offered'} showIcon={false}/>

                {fareInbound && fareInbound.length
                    ? fareInbound.map((item, index) => (
                        <SummaryRow
                            key={`inbound-${index}`}
                            title={<b>{passengerTypeObject[item.PassengerType]}</b>}
                            published={fixed2Digit(item.BaseFare)}
                            offered={fixed2Digit(item.BaseFare / 2)}
                            showIcon={false}
                        />
                    ))
                    : null}

                {fareOutbound && fareOutbound.length
                    ? fareOutbound.map((item, index) => (
                        <SummaryRow
                            key={`outbound-${index}`}
                            title={<b>{passengerTypeObject[item.PassengerType]}</b>}
                            published={fixed2Digit(item.BaseFare)}
                            offered={fixed2Digit(item.BaseFare / 2)}
                            showIcon={false}
                        />
                    ))
                    : null}


                <SummaryRow
                    title={'QT Tax'}
                    published={fixed2Digit(combinedTotals.totalOtherTax)}
                    offered={fixed2Digit(combinedTotals.totalOtherTax / 2)}
                />
                <SummaryRow
                    title={'YQ Tax'}
                    published={fixed2Digit(combinedTotals.totalYQTax)}
                    offered={fixed2Digit(combinedTotals.totalYQTax / 2)}
                />
                <SummaryRow
                    title={'YR Tax'}
                    published={fixed2Digit(combinedTotals.totalYRTax)}
                    offered={fixed2Digit(combinedTotals.totalYRTax / 2)}
                />
                <hr/>
                <SummaryRow
                    title={'Amount To Pay'}
                    published={<b>{fixed2Digit(combinedTotals.totalAmount)}</b>}
                    offered={<b>{fixed2Digit(combinedTotals.totalAmount / 2)}</b>}
                />
                <hr/>
                <ul>
                    <li>
                        <button className="btn btn-primary w-100" type="button">Pay <Icon
                            name={'rupee'}/> {fixed2Digit(combinedTotals.totalAmount)}</button>
                    </li>
                </ul>
            </div>
        </>
    );
};

const FlightSummary = (props) => {
    let {flightData} = props;
    let {FareBreakdown: fareBreakdown} = flightData;
    console.log(flightData, '==========');
    let taxEvents = {
        getSingleTax: (data, taxName) => {
            let findD = _.find(data, (item) => {
                return item.key == taxName
            })
            if (findD) {
                return findD.value
            }
        },
        getOtherTax: (data) => {
            let totalTax = 0;
            _.each(data, (item) => {
                if (!['YQTax', 'YR'].includes(item.key)) {
                    totalTax = totalTax + item.value
                }
            })
            return totalTax;
        },
    }
    return (
        <>
            <div className="detail-box">
                <h4>Fare Summary</h4>
                <SummaryRow published={'Published'} offered={'Offered'} showIcon={false}/>
                {fareBreakdown && fareBreakdown.length ? fareBreakdown.map((item) => {
                    let totalAmount = item['BaseFare'] + item['Tax'];
                    return (
                        <>
                            <SummaryRow title={<b>{passengerTypeObject[item['PassengerType']]}</b>}
                                        published={fixed2Digit(item['BaseFare'])}
                                        offered={fixed2Digit(item['BaseFare'] / 2)}
                                        showIcon={false}
                            />
                            <SummaryRow title={'QT Tax'}
                                        published={fixed2Digit(taxEvents.getOtherTax(item['TaxBreakUp']))}
                                        offered={fixed2Digit(taxEvents.getOtherTax(item['TaxBreakUp']) / 2)}/>
                            <SummaryRow title={'YQ Tax'}
                                        published={fixed2Digit(taxEvents.getSingleTax(item['TaxBreakUp'], 'YQTax'))}
                                        offered={fixed2Digit(taxEvents.getSingleTax(item['TaxBreakUp'], 'YQTax') / 2)}/>
                            <SummaryRow title={'YR Tax'}
                                        published={fixed2Digit(taxEvents.getSingleTax(item['TaxBreakUp'], 'YR'))}
                                        offered={fixed2Digit(taxEvents.getSingleTax(item['TaxBreakUp'], 'YR') / 2)}/>
                            <SummaryRow title={'T. Fee and S.Charges:'}
                                        published={0}
                                        offered={0}/>
                            <hr/>
                            <SummaryRow title={<b>Total</b>}
                                        published={<b>{fixed2Digit(totalAmount)}</b>}
                                        offered={<strong>{fixed2Digit(totalAmount / 2)}</strong>}/>
                            <hr/>
                            <br/>
                        </>
                    )
                }) : null}
            </div>
        </>
    )
}

let initState = {
    title: undefined,
    firstName: "",
    gender: undefined,
    day: undefined,
    month: undefined,
    year: undefined,
    Nationality: "",
    PassportNumber: "",
    PassportExpiryDate: "",
}
const SinglePassengerComponent = (props) => {
    let {data = initState, index, passengerType, onChange, fareObject} = props;
    let [state, setState] = useState({});
    useEffect(() => {
        setState(data);
    }, []);

    console.log(state, "------------state")
    const passengerField = [
        {
            key: "title",
            type: "select",
            placeholder: "Choose Title *",
            required: true,
            options: titleArr,
            span: "col-md-2",
            keyAccessor: (option) => option.name,
            valueAccessor: (option) => option.name,
        },
        {
            key: "firstName",
            placeholder: "First Name *",
            required: true,
            span: "col-md-5",
        },
        {
            key: "lastName",
            placeholder: "Last Name *",
            required: true,
            span: "col-md-5",
        },
        {
            key: "gender",
            type: "select",
            placeholder: "Gender *",
            required: true,
            options: genderArr,
            span: "col-md-6",
            keyAccessor: (option) => option.value,
            valueAccessor: (option) => option.name,
        },
        {
            key: "day",
            type: "select",
            placeholder: "Day *",
            required: true,
            span: "col-md-2",
            showSearch: true,
            options: daysArr(),
        },
        {
            key: "month",
            type: "select",
            placeholder: "Month *",
            required: true,
            span: "col-md-2",
            showSearch: false,
            options: monthArr,
            keyAccessor: (option) => option.name,
            valueAccessor: (option) => option.value,
        },
        {
            key: "year",
            type: "select",
            placeholder: "Year *",
            required: true,
            showSearch: true,
            span: "col-md-2",
            options: yearArr(passengerType),
        },

        ...(fareObject?.IsPassportRequiredAtTicket
            ? [
                {
                    key: "Nationality",
                    placeholder: "Nationality",
                    type: "select",
                    required: true,
                    span: "col-md-6",
                    options: nationalityArr,
                    keyAccessor: (option) => option.nationality,
                    valueAccessor: (option) => option.nationality,
                },
                {
                    key: "PassportNumber",
                    placeholder: "Passport Number",
                    type: "text",
                    required: true,
                    span: "col-md-6",
                },
                {
                    key: "PassportExpiryDate",
                    type: "dateOfBirth",
                    placeholder: "Expiry Date",
                    required: true,
                    span: "col-md-6",
                    // format: "YYYY-MM-DD",
                },
            ]
            : []),

    ];

    const passEvents = {
        _updateState: (obj) => {
            setState((prevState) => ({
                ...prevState,
                ...obj,
            }));
        },
        onChange: (dataObj, fieldName) => {
            if (fieldName === "title") {
                let findGender = _.find(titleArr, (item) => item.name === dataObj["title"]);
                if (findGender) {
                    dataObj["gender"] = findGender.gender;
                }
                passEvents._updateState(dataObj);
            } else {
                passEvents._updateState(dataObj);
            }
        },
    };

    useEffect(() => {
        onChange(state);
    }, [state]);

    let customId = `${passengerType}-${index + 1}`;
    return (
        <div className="accordion-item" key={customId}>
            <h2 className="accordion-header" id={`${customId}-headingOne`}>
                <ul>
                    <li className="p-3 head-accordion">
                        <h6 className={"text-capitalize"}>
                            {passengerType} {index + 1}
                        </h6>
                        <a
                            href="#"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${customId}-collapseOne`}
                            aria-expanded="true"
                            aria-controls={`${customId}-collapseOne`}
                            className="btn p-0 collapsed"
                        >
                            <img src={ArrowUpIcon} alt="arrow-up" width={28}/>
                        </a>
                    </li>
                </ul>
            </h2>
            <div
                id={`${customId}-collapseOne`}
                className="accordion-collapse collapse show"
                aria-labelledby={`${customId}-headingOne`}
            >
                <div className="accordion-body">
                    <div className="row row-gap-3">
                        {passengerField.map((item) => {
                            let id = `${item.key}-${index}-${passengerType}`;
                            return (
                                <div className={item.span} key={id}>
                                    <CustomFormFields
                                        item={{
                                            size: 'small',
                                            ...item, passengerType: passengerType,
                                            required: item.required,
                                            id: id, name: id,
                                            value: state && state[item.key] ? state[item.key] : "",
                                            onChange: (value) => {
                                                passEvents.onChange({[item.key]: value}, item.key)
                                            }
                                        }}/>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};


const SinglePassCard = (props) => {
    let {title, index, data} = props;
    return (
        <ul className={'pass-grid'}>
            <li>
                {title} ({index + 1})
            </li>
            <li>
                <b>Name</b> : {data && data.firstName && data.lastName ? `${data.firstName} ${data.lastName}` : ""}<br/>
                <b>Gender</b> : {data && data.gender == 2 ? "Female" : "Male"}<br/>
                <b>Contact No</b> : {data && data.contactNo ? data.contactNo : ""}

            </li>
            <li>
                <b>D.O.B</b> : {data && data.day && data.month && data.year ? `${data.day}-${data.month}-${data.year}` : ""}<br/>
                <b>Email ID</b> : {data && data.email ? data.email : ""}<br/>
                {data && data.PassportNumber && (
                    <>
                        <b>Passport No.</b> : {data && data.PassportNumber ? data.PassportNumber : ""}<br/>
                        <b>Passport Expiry</b> :{data && data.PassportExpiryDate ? data.PassportExpiryDate : ""}<br/>
                    </>
                )}

            </li>
        </ul>
    )
}
const ReviewBookingComponent = (props) => {
    let {adultPassengers, childPassengers, infantPassengers} = props;
    return (
        <>
            <Card title={'Passengers'} round={12}>
                {adultPassengers && adultPassengers.map((item, index) => {
                    return <SinglePassCard title={'ADULT'} index={index} data={item}/>
                })}
                {childPassengers && childPassengers.map((item, index) => {
                    return <SinglePassCard title={'CHILD'} index={index} data={item}/>
                })}
                {infantPassengers && infantPassengers.map((item, index) => {
                    return <SinglePassCard title={'INFANT'} index={index} data={item}/>
                })}
            </Card>


        </>
    )
}

const AnchillaryServices = (props) => {
    let {adultPassengers, childPassengers, infantPassengers} = props;

    return (
        <>
            <Card title={'Anchillary Services'} round={12}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{width: '20%'}}>
                        <p>Adult 1</p>

                    </div>

                    <div style={{width: '80%'}}>
                        <table className={'table table-border'} bordered>
                            <thead>
                            <tr>
                                <th>Baggage</th>
                                <th>Seat</th>
                                <th>Meal</th>
                                <th>Priority-Checkin</th>
                            </tr>
                            </thead>
                            <tbody>

                            <tr>
                                <td>1 Bag</td>
                                <td>Seat 12A</td>
                                <td>Vegetarian</td>
                                <td>Yes</td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </Card>
        </>
    );
}

const ContactDetailsComponent = (props) => {
    let {adultPassengers, childPassengers, infantPassengers} = props;
    return (
        <>
            <Card title={'Contact Details'} round={12}>
                {adultPassengers && adultPassengers.map((item, index) => {
                    return <SinglePassCard index={index} data={item}/>
                })}

            </Card>


        </>
    )
}

const GstDetailsComponent = (props) => {
    let {adultPassengers, childPassengers, infantPassengers} = props;
    return (
        <>
            <Card title={'GST Number for Business Travel (Optional)'} round={12}>
                {adultPassengers && adultPassengers.map((item, index) => {
                    return <SinglePassCard index={index} data={item}/>
                })}

            </Card>


        </>
    )
}


const contactInitState = {
    email: "",
    contactNo: ""
}
const Checkout = () => {
    const {flightObj, traceId, selectedFlight, fareQuoteObj} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj,
        traceId: state.flightsReducer.traceId,
        selectedFlight: state.flightsReducer.selectedFlight,
        // fareQuoteObj: state.flightsReducer.fareQuoteObj,
    }))
    const navigate = useNavigate();
    const [state, setState] = useState(contactInitState)
    let dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(1)
    const [fareObject, setFareObject] = useState({})
    const [inboundFareObj, setInboundFareObj] = useState({})
    const [outboundFareObj, setOutboundFareObj] = useState({})
    const [childPassengers, setChildPassengers] = useState([]);
    const [adultPassengers, setAdultPassengers] = useState([]);
    const [infantPassengers, setInfantPassengers] = useState([]);
    const [redeemAmount, setRedeemAmount] = useState('');
    const [selectedPayment, setSelectedPayment] = useState('');
    const [fareState, setFareState] = useState({
        oldFare: {},
        newFare: {},
        quoteFare: {},
        visible: false
    })
    const [inboundFareState, setInboundFareState] = useState({
        oldFare: {},
        newFare: {},
        quoteFare: {},
        visible: false
    })
    const [outboundFareState, setOutboundFareState] = useState({
        oldFare: {},
        newFare: {},
        quoteFare: {},
        visible: false
    })
    const [showDetails, setShowDetails] = useState(false);

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };
    const handleRedeemChange = (e) => {
        setRedeemAmount(e.target.value);
    };
    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
    };
    useEffect(() => {
        events.fareQuote();
    }, [])

    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        updateAdultPassenger: (data, index) => {
            let cloneD = _.clone(adultPassengers);
            cloneD[index] = {...cloneD[index], ...data}
            setAdultPassengers(cloneD)
        },
        updateChildPassenger: (data, index) => {
            let cloneD = _.clone(childPassengers);
            cloneD[index] = {...cloneD[index], ...data}
            setChildPassengers(cloneD)
        },
        updateInfantPassengers: (data, index) => {
            let cloneD = _.clone(infantPassengers);
            cloneD[index] = {...cloneD[index], ...data}
            setInfantPassengers(cloneD)
        },
        fareQuote: async () => {
            if (selectedFlight.mappedInBoundFlights && selectedFlight.mappedOutBoundFlights) {
                if (selectedFlight.mappedInBoundFlights) {
                    const {data, success} = await dispatch(
                        flightsFearQuoteFxn({
                            traceId: traceId,
                            resultIndex: selectedFlight.mappedInBoundFlights['ResultIndex']
                        })
                    );
                    if (success) {
                        events.checkInboundPriceDifferent(data);
                    }
                }
                if (selectedFlight.mappedOutBoundFlights) {
                    const {data, success} = await dispatch(
                        flightsFearQuoteFxn({
                            traceId: traceId,
                            resultIndex: selectedFlight.mappedOutBoundFlights['ResultIndex']
                        })
                    );
                    if (success) {
                        events.checkOutboundPriceDifferent(data);
                    }
                }
            } else {
                const {data, success} = await dispatch(flightsFearQuoteFxn({
                    traceId: traceId,
                    resultIndex: selectedFlight['ResultIndex']
                }));
                if (success) {
                    events.checkPriceDifferent(data);
                }
            }
        },
        checkPriceDifferent: (data) => {
            let {Fare: quoteFare} = data;
            if (quoteFare['PublishedFare'] == quoteFare['PublishedFare'] && quoteFare['OfferedFare'] == quoteFare['OfferedFare']) {
                setFareObject(data);
            } else {
                dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data})
                setFareState({
                    oldFare: quoteFare,
                    newFare: quoteFare,
                    quoteFare: data,
                    visible: true
                })
            }
        },
        checkInboundPriceDifferent: (data) => {
            let {Fare: quoteFare} = data;
            if (quoteFare['PublishedFare'] == quoteFare['PublishedFare'] && quoteFare['OfferedFare'] == quoteFare['OfferedFare']) {
                setInboundFareObj(data);
            } else {
                dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data})
                setInboundFareState({
                    oldFare: quoteFare,
                    newFare: quoteFare,
                    quoteFare: data,
                    visible: true
                })
            }
        },
        checkOutboundPriceDifferent: (data) => {
            let {Fare: quoteFare} = data;
            if (quoteFare['PublishedFare'] == quoteFare['PublishedFare'] && quoteFare['OfferedFare'] == quoteFare['OfferedFare']) {
                setOutboundFareObj(data);
            } else {
                dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data})
                setOutboundFareState({
                    oldFare: quoteFare,
                    newFare: quoteFare,
                    quoteFare: data,
                    visible: true
                })
            }
        },
        closeChangePriceModal: (data) => {
            setFareState({
                oldFare: {},
                newFare: {},
                quoteFare: {},
                visible: false,
            });
        },
        submitChangePriceModal: (data) => {
            setFareObject(data);
            dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data});
            setFareState({
                oldFare: {},
                newFare: {},
                quoteFare: {},
                visible: false,
            });
        },
        addPassenger: () => {
            setActiveTab(2)
        },
        sendForReview: (e) => {
            e.preventDefault();
            let allPassenger = [...adultPassengers, ...childPassengers, ...infantPassengers]
            let findE = _.find(allPassenger, (item) => {
                return !item.title && !item.firstName && !item.lastName && !item.gender && !item.day && !item.month && !item.year
            })
            if (findE) {
                notification.error({message: "Please enter all required fields."})
                return
            }
            if (!state.email) {
                notification.error({message: "Please enter email address."})
                return
            }
            if (!state.contactNo) {
                notification.error({message: "Please enter contact no."})
                return
            }
            setActiveTab(3)
        },
        bookTicket: async () => {
            let allPassenger = [];
            if (adultPassengers && adultPassengers.length) {
                _.each(adultPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 1})
                })
            }
            if (childPassengers && childPassengers.length) {
                _.each(childPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 2})
                })
            }

            let obj = {
                traceId: traceId,
                resultIndex: selectedFlight['ResultIndex'],
                allPassenger,
                fareQuote: fareObject,
                isLcc: selectedFlight['IsLCC'],
                ...state
            }
            let {ticketId} = await dispatch(lccFlightTicketFxn(obj));
            navigate(`/response/${ticketId}`)
        }
    }


    return (
        <>

            <div className="checkout-box bg-gray">
                <div className="flight-booking bg-white spacing-40">
                    <div className="container position-relative">

                        <div className="col-lg-12">
                            <ul className="nav nav-tabs border-0 gap-3" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 1 ? "active" : ""}`}
                                        id="flight-itinerary-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#flight-itinerary"
                                        type="button"
                                        role="tab"
                                        aria-controls="flight-itinerary"
                                        disabled={activeTab !== 1}
                                        aria-selected="true">
                                        <img src={ItenaryIcon} alt={'arrow'}/>
                                        Flight Itinerary
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 2 ? "active" : ""}`}
                                        id="passenger-details-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#passenger-details"
                                        type="button"
                                        role="tab"
                                        disabled={activeTab !== 2}
                                        aria-controls="passenger-details"
                                        aria-selected="false">
                                        <img src={PassengerIcon} alt={'arrow'}/>
                                        Passenger Details
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 3 ? "active" : ""}`}
                                        id="review-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#review"
                                        type="button"
                                        role="tab"
                                        disabled={activeTab !== 3}
                                        aria-controls="review"
                                        aria-selected="false"
                                    >
                                        <img src={ReviewIcon} alt={'arrow'} width={24}/>
                                        Review
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 4 ? "active" : ""}`}
                                        id="payment-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#payment"
                                        type="button"
                                        role="tab"
                                        disabled={activeTab !== 4}
                                        aria-controls="payment"
                                        aria-selected="false">
                                        <img src={PaymentIcon} alt={'arrow'}/>
                                        Payment
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="spacing-40 container">

                    <div className="row row-gap-3">
                        <div className="col-lg-9">

                            <div className="tab-content" id="myTabContent">
                                <div className={`tab-pane fade ${activeTab == 1 ? "show active" : ""}`}
                                     id="flight-itinerary" role="tabpanel"
                                     aria-labelledby="flight-itinerary-tab">
                                    <div className="head d-flex gap-3 align-items-center mb-3">
                                        <span>Visa, Passport & Health Regulations</span>
                                        <button
                                            className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2"
                                            onClick={() => window.history.back()}> {/* Use a function to go back */}
                                            Back to Search
                                            <img src={ArrowIcon} alt={'arrow'}/>
                                        </button>
                                    </div>
                                    <div className="find-flight-main">
                                        {selectedFlight.mappedInBoundFlights || selectedFlight.mappedOutBoundFlights ? (
                                            <>
                                                <div className={'row'}>
                                                    <div className={'col-md-6'}>
                                                        {selectedFlight.mappedInBoundFlights && (
                                                            <FlightInfoComponent
                                                                selectedFlight={selectedFlight.mappedInBoundFlights}
                                                                flightObj={flightObj}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className={'col-md-6'}>
                                                        {selectedFlight.mappedOutBoundFlights && (
                                                            <FlightInfoComponent
                                                                selectedFlight={selectedFlight.mappedOutBoundFlights}
                                                                flightObj={flightObj}
                                                            />
                                                        )}
                                                    </div>
                                                </div>

                                            </>
                                        ) : (
                                            <>
                                                <FlightInfoComponent selectedFlight={selectedFlight}
                                                                     flightObj={flightObj}/>
                                            </>
                                        )}

                                     {/*   <div className="accordion-flight-track">
                                            <p className="note">
                                                Require to change Plane - Layover Time: 3h 15m
                                            </p>
                                        </div>*/}

                                        <button className="btn btn-primary w-100 mt-3"
                                                onClick={() => events.addPassenger()}>ADD
                                            PASSENGERS DETAILS
                                        </button>
                                    </div>
                                </div>


                                <div className={`tab-pane fade ${activeTab == 2 ? "show active" : ""}`}
                                     id="passenger-details" role="tabpanel"
                                     aria-labelledby="passenger-details-tab">
                                    <div className="head d-flex gap-3 align-items-center mb-3">
                                        <span>Select from History</span>
                                        <button
                                            className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2">
                                            Back to Search <img src={ArrowIcon} width="24" alt="arrow"/>
                                        </button>
                                    </div>
                                    {selectedFlight.mappedInBoundFlights || selectedFlight.mappedOutBoundFlights ? (
                                        <>
                                            <div className={'row'}>
                                                <div className={'col-md-6'}>
                                                    {selectedFlight.mappedInBoundFlights && (
                                                        <FlightInfoComponent
                                                            selectedFlight={selectedFlight.mappedInBoundFlights}
                                                            flightObj={flightObj}
                                                        />
                                                    )}
                                                </div>
                                                <div className={'col-md-6'}>
                                                    {selectedFlight.mappedOutBoundFlights && (
                                                        <FlightInfoComponent
                                                            selectedFlight={selectedFlight.mappedOutBoundFlights}
                                                            flightObj={flightObj}
                                                        />
                                                    )}
                                                </div>
                                            </div>

                                        </>
                                    ) : (
                                        <>

                                            <FlightInfoComponent selectedFlight={selectedFlight} flightObj={flightObj}/>
                                        </>
                                    )}

                                    <form onSubmit={events.sendForReview}>
                                        <div className="passanger-details">
                                            <div className={"track-flights"} style={{overFlow: 'hidden'}}>
                                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                                    {_.times(flightObj.adultCount, (adItem) => {
                                                        return (
                                                            <>
                                                                <SinglePassengerComponent
                                                                    data={adultPassengers[adItem]}
                                                                    fareObject={fareObject}
                                                                    index={adItem}
                                                                    onChange={(data) => {
                                                                        events.updateAdultPassenger(data, adItem)
                                                                    }}
                                                                    passengerType={'adult'} key={`adult-${adItem}`}/>
                                                            </>
                                                        )
                                                    })}
                                                    {_.times(flightObj.childCount, (adItem) => {
                                                        return (
                                                            <>
                                                                <SinglePassengerComponent
                                                                    data={childPassengers[adItem]}
                                                                    fareObject={fareObject}
                                                                    index={adItem}
                                                                    onChange={(data) => {
                                                                        events.updateChildPassenger(data, adItem)
                                                                    }}
                                                                    passengerType={'child'} key={`child-${adItem}`}/>
                                                            </>
                                                        )
                                                    })}

                                                    {_.times(flightObj.infantCount, (adItem) => {
                                                        return (
                                                            <>
                                                                <SinglePassengerComponent
                                                                    data={infantPassengers[adItem]}
                                                                    index={adItem}
                                                                    onChange={(data) => {
                                                                        events.updateInfantPassengers(data, adItem)
                                                                    }}
                                                                    passengerType={'infant'} key={`infant-${adItem}`}/>
                                                            </>
                                                        )
                                                    })}
                                                    {/*<button className="btn btn-primary w-100 mb-3" onClick={events.addPassenger}>ADD MORE PASSENGER</button>*/}

                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                                            <ul>
                                                                <li className="p-3 head-accordion">
                                                                    <h6>Contact Details</h6>
                                                                    <div className="d-flex gap-3">
                                                                        {/*     <button
                                                                            className="btn bg-transparent p-0 active">
                                                                            Save Contact Details
                                                                        </button>*/}
                                                                        <a
                                                                            href=""
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target="#panelsStayOpen-collapseTwo"
                                                                            aria-expanded="false"
                                                                            aria-controls="panelsStayOpen-collapseTwo"
                                                                            className="btn p-0 collapsed"
                                                                        >
                                                                            <img src={ArrowUpIcon}
                                                                                 alt="arrow-up" width={28}/>
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </h2>
                                                        <div
                                                            id="panelsStayOpen-collapseTwo"
                                                            className="accordion-collapse collapse show"
                                                            aria-labelledby="panelsStayOpen-headingTwo">
                                                            <div className="accordion-body">

                                                                <div className="row row-gap-3">
                                                                    <div className="col-lg-6">
                                                                        <PhoneNo
                                                                            type={'number'}
                                                                            size={'small'}
                                                                            placeholder={'Contact No'}
                                                                            required={true}
                                                                            value={state.contactNo}
                                                                            onChange={(value) => {
                                                                                events._updateState({contactNo: value})
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-6">
                                                                        <Input
                                                                            type={'email'}
                                                                            placeholder={'Email address'}
                                                                            value={state.email}
                                                                            size={'small'}
                                                                            required={true}
                                                                            onChange={(value) => {
                                                                                events._updateState({email: value})
                                                                            }}
                                                                        />

                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header"
                                                            id="panelsStayOpen-headingThree">
                                                            <ul>
                                                                <li className="p-3 head-accordion">
                                                                    <h6>GST Number for Business Travel (Optional)</h6>
                                                                    <div className="d-flex gap-3">
                                                                        <button
                                                                            className="btn bg-transparent p-0 active">
                                                                            Save GST Details
                                                                        </button>
                                                                        <a
                                                                            href=""
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target="#panelsStayOpen-collapseThree"
                                                                            aria-expanded="false"
                                                                            aria-controls="panelsStayOpen-collapseThree"
                                                                            className="btn p-0 collapsed"
                                                                        >
                                                                            <img src={ArrowUpIcon} alt="arrow-up"
                                                                                 width={28}/>
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </h2>
                                                        <div
                                                            id="panelsStayOpen-collapseThree"
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby="panelsStayOpen-headingThree"
                                                        >
                                                            <div className="accordion-body">
                                                                <form>
                                                                    <div className="row row-gap-3">
                                                                        <div className="col-lg-6">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="GST Number"
                                                                                name="GSTNumber"
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Company Name"
                                                                                name="GSTCompanyName"
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Company Address"
                                                                                name="GSTCompanyAddress"
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Company Contact Number"
                                                                                name="GSTCompanyContactNumber"
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <input
                                                                                type="email"
                                                                                className="form-control"
                                                                                placeholder="Company Email"
                                                                                name="GSTCompanyEmail"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary w-100 mt-3">PROCEED TO REVIEW</button>
                                    </form>
                                </div>


                                <div
                                    className={`tab-pane fade ${activeTab === 3 ? "show active" : ""}`}
                                    id="review"
                                    role="tabpanel"
                                    aria-labelledby="review-tab"
                                >


                                    <FlightInfoComponent selectedFlight={selectedFlight} flightObj={flightObj}/>

                                    <ReviewBookingComponent
                                        childPassengers={childPassengers}
                                        data={selectedFlight}
                                        adultPassengers={adultPassengers}
                                    />
                                    <div className="flight-accordion-details mt-3">
                                        <AnchillaryServices
                                            childPassengers={childPassengers}
                                            data={selectedFlight}
                                            adultPassengers={adultPassengers}
                                        />
                                    </div>

                                    <div className="flight-accordion-details mt-3">
                                        <ContactDetailsComponent
                                            childPassengers={childPassengers}
                                            data={selectedFlight}
                                            adultPassengers={adultPassengers}
                                        />
                                    </div>
                                    <div className="flight-accordion-details mt-3">
                                        <GstDetailsComponent
                                            childPassengers={childPassengers}
                                            data={selectedFlight}
                                            adultPassengers={adultPassengers}
                                        />
                                    </div>
                                    <div className="flight-accordion-details mt-3">
                                        <div className="accordion" id="accordionPanelsStayOpenExample">
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header d-flex align-items-center"
                                                    id="panelsStayOpen-headingOne"
                                                >
                                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button
                                                                className="nav-link active"
                                                                id="fare-rules-tab"
                                                                data-bs-toggle="tab"
                                                                data-bs-target="#fare-rules-tab-pane"
                                                                type="button"
                                                                role="tab"
                                                                aria-controls="fare-rules-tab-pane"
                                                                aria-selected="true"
                                                            >
                                                                Fare Rules
                                                            </button>
                                                        </li>
                                                    </ul>

                                                </h2>
                                                <div
                                                    id="panelsStayOpen-collapseOne"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="panelsStayOpen-headingOne"
                                                >
                                                    <div className="accordion-body">
                                                        <div className="tab-content" id="myTabContent">
                                                            <div
                                                                className="tab-pane fade active show"
                                                                id="fare-rules-tab-pane"
                                                                role="tabpanel"
                                                                aria-labelledby="fare-rules-tab"
                                                                tabIndex="0"
                                                            >
                                                                <div className="flight-custom-table">
                                                                    <div className="flight-destimation-time">
                                                                        <div
                                                                            className="row row-gap-3 align-items-center">
                                                                            <div className="col-lg-4">
                                                                                <div
                                                                                    className="d-flex align-items-center"
                                                                                    style={{gap: "30px"}}
                                                                                >
                                                                                    <h6 className="m-0">Delhi</h6>
                                                                                    <span>
                           <FlightImg/>
                          </span>
                                                                                    <h6 className="m-0">Toronto</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <p className="m-0">
                                                                                    On Friday, October, 4th, 2024
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <p className="m-0">21hr 10min</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="content-box">
                                                                        <ul>
                                                                            <small
                                                                                style={{fontSize: "14px"}}
                                                                                className="mb-3 d-block"
                                                                            >
                                                                                * To view charges, click on the below
                                                                                fee sections.
                                                                            </small>
                                                                            <li>
                                                                                <span>Time Frame</span>{" "}
                                                                                <span>As Per Airline</span>
                                                                            </li>
                                                                            <li>
                                                                                <span>Cancellation Fee</span>{" "}
                                                                                <span>
                          Airline Cancellation Charges + Taxes + Surcharges
                        </span>
                                                                            </li>
                                                                            <li>
                                                                                <span>Date Change Fee</span>{" "}
                                                                                <span>
                          Before Departure: Rs.14,011.54 Allowed
                        </span>
                                                                            </li>
                                                                            <li>
                                                                                <span>No Show Fee</span>{" "}
                                                                                <span>
                          As Per Airline: Fees As Per Airline Rule + Surcharges
                        </span>
                                                                            </li>
                                                                            <li>
                                                                                <span>Seat Chargeable Fee</span>{" "}
                                                                                <span>As Per Airline</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div
                                                                        className="rules d-flex flex-wrap flex-lg-nowrap gap-3">
                                                                        <ul className="w-100">
                                                                            {/*<li>*/}
                                                                            {/*    Lorem ipsum dolor sit amet consectetur adipisicing elit.*/}
                                                                            {/*</li>*/}
                                                                            {/*<li>Lorem ipsum dolor sit amet.</li>*/}
                                                                            {/*<li>Lorem ipsum dolor sit amet.</li>*/}
                                                                            {/*<li>*/}
                                                                            {/*    Lorem ipsum dolor sit amet consectetur adipisicing elit.*/}
                                                                            {/*</li>*/}
                                                                        </ul>
                                                                        <button
                                                                            className="btn btn-primary text-nowrap mt-auto"
                                                                            onClick={toggleDetails}>
                                                                            View Details
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {showDetails && (
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <h5 className="card-title">Booking History</h5>
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Time</th>
                                                        <th>Status</th>
                                                        <th>Username</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                    <div className={'row'}>
                                        <div className={'col-md-5'}>
                                            <div className="d-flex align-items-center">
                                                {/*<input*/}
                                                {/*    type="checkbox"*/}
                                                {/*    id="acceptTerms"*/}
                                                {/*    className="form-check-input"*/}
                                                {/*    onChange={(e) => {  }}*/}
                                                {/*/>*/}
                                                <p htmlFor="acceptTerms" className="ms-2 mt-3">
                                                    <img src={TickIcon} alt={'tick'}/> I accept the terms
                                                    and conditions
                                                </p>
                                            </div>
                                        </div>
                                        <div className={'col-md-7'}>
                                            <button className="btn btn-primary w-100 mt-3" onClick={events.bookTicket}>
                                                Proceed To Pay
                                            </button>
                                        </div>
                                    </div>

                                </div>


                                <div className={`tab-pane fade ${activeTab == 4 ? "show active" : ""}`} id="payment"
                                     role="tabpanel"
                                     aria-labelledby="payment-tab">
                                    <div className="head d-flex gap-3 align-items-center mb-3">
                                        <button
                                            className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2">
                                            Back to Search <img src={ArrowIcon} width="24" alt="Back"/>
                                        </button>
                                    </div>
                                    <FlightInfoComponent selectedFlight={selectedFlight} flightObj={flightObj}/>

                                    <div className="passanger-details">
                                        <div className="title">
                                            <h5 className="w-100 justify-content-start">Payments</h5>
                                        </div>

                                        <div className="payment-method">
                                            <h4>Choose Payment Method</h4>

                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="deposit-tab"
                                                            data-bs-toggle="tab" data-bs-target="#deposit-tab-pane"
                                                            type="button" role="tab"
                                                            aria-controls="deposit-tab-pane"
                                                            aria-selected="true">Deposit
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="card-tab" data-bs-toggle="tab"
                                                            data-bs-target="#card-tab-pane" type="button" role="tab"
                                                            aria-controls="card-tab-pane"
                                                            aria-selected="false">Credit/Debit Card
                                                    </button>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="deposit-tab-pane"
                                                     role="tabpanel"
                                                     aria-labelledby="deposit-tab" tabIndex="0">
                                                    Deposit Content
                                                </div>
                                                <div className="tab-pane fade" id="card-tab-pane" role="tabpanel"
                                                     aria-labelledby="card-tab" tabIndex="0">
                                                    <div className="choose-mode">
                                                        <ul>
                                                            <li>
                                                                <input type="radio" name="choose" value="mastercard"
                                                                       onChange={handlePaymentChange}
                                                                       checked={selectedPayment === 'mastercard'}/>
                                                                <label><img src="images2/master-card.png"
                                                                            alt="Mastercard"/></label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" name="choose" value="paypal"
                                                                       onChange={handlePaymentChange}
                                                                       checked={selectedPayment === 'paypal'}/>
                                                                <label><img src="images2/pay-pal.png"
                                                                            alt="PayPal"/></label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" name="choose" value="visa"
                                                                       onChange={handlePaymentChange}
                                                                       checked={selectedPayment === 'visa'}/>
                                                                <label><img src="images2/visa.png"
                                                                            alt="Visa"/></label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" name="choose" value="wallet"
                                                                       onChange={handlePaymentChange}
                                                                       checked={selectedPayment === 'wallet'}/>
                                                                <label>Wallet</label>
                                                            </li>
                                                            <li>
                                                                <input type="radio" name="choose" value="upi"
                                                                       onChange={handlePaymentChange}
                                                                       checked={selectedPayment === 'upi'}/>
                                                                <label>UPI</label>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <form>
                                                        <div className="row row-gap-3">
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control"
                                                                       placeholder="Card Number"/>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control"
                                                                       placeholder="Expiration Date MM/YY"/>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control"
                                                                       placeholder="CVV"/>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control"
                                                                       placeholder="Name on Card"/>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="terms-check d-flex flex-wrap gap-2 gap-lg-5 flex-lg-nowrap mt-3 align-items-center">
                                            <div className="d-flex gap-2 text-nowrap align-items-center">
                                                <input type="checkbox"/>
                                                <span><img src="../images2/design-check.png" alt="Check"/></span>
                                                I accept <a href="#">terms & conditions</a>
                                            </div>
                                            <button className="btn btn-primary w-100 mx-auto">PROCEED TO PAY
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {
                                inboundFareObj.ResultIndex &&
                                outboundFareObj.ResultIndex ? (
                                    <ReturnFlightSummary
                                        inboundData={inboundFareObj}
                                        outboundData={outboundFareObj}
                                    />
                                ) : fareObject && fareObject.ResultIndex ? (
                                    <FlightSummary flightData={fareObject}/>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>

            <RegisterBanner/>
            {/*{inboundFareState.visible && outboundFareState.visible ?*/}
            {/*    <ChangePrice {...inboundFareState}*/}
            {/*                 onSubmit={events.submitChangePriceModal}*/}
            {/*                 onClose={events.closeChangePriceModal}/> : null}*/}
            {/*}*/}
            {fareState.visible ?
                <ChangePrice {...fareState}
                             onSubmit={events.submitChangePriceModal}
                             onClose={events.closeChangePriceModal}/> : null}
        </>
    );
}

export default Checkout;
