export const ticketListUrl = () => {
    return "/x-travel/ticket-list"
}

export const getBookingDetailsUrl = () => {
    return "/x-travel/booking-details"
}

export const bookSeatsUrl = () => {
    return "/x-travel/ssr-request"
}
