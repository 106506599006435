import React from "react";

const AddMarkup = () => {
    return (
        <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                    <ul>
                        <li className="p-3 head-accordion">
                            <h6>Add Markup</h6>
                            <a
                                href="#"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseOne"
                                aria-expanded="true"
                                aria-controls="panelsStayOpen-collapseOne"
                                className="btn p-0 collapsed"
                            >
                                <img
                                    src="images2/arrow-up.png"
                                    alt="Arrow Up"
                                    width="28"
                                />
                            </a>
                        </li>
                    </ul>
                </h2>
                <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne"
                >
                    <div className="accordion-body">
                        <form>
                            <div className="row row-gap-3">
                                <div className="col-lg-3">
                                    <select name="markup" id="markup-type"
                                            className="form-control">
                                        <option value="0">Markup Type</option>
                                        <option value="1">Option 1</option>
                                        <option value="2">Option 2</option>
                                        <option value="3">Option 3</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <select name="airlines" id="airlines"
                                            className="form-control">
                                        <option value="0">Airlines</option>
                                        <option value="1">Airline 1</option>
                                        <option value="2">Airline 2</option>
                                        <option value="3">Airline 3</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <select name="pax-types" id="pax-types"
                                            className="form-control">
                                        <option value="0">Pax Types</option>
                                        <option value="1">Pax Types 1</option>
                                        <option value="2">Pax Types 2</option>
                                        <option value="3">Pax Types 3</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <select
                                        name="amount-type"
                                        id="amount-type"
                                        className="form-control"
                                    >
                                        <option value="0">Amount Types</option>
                                        <option value="1">Amount Types 1</option>
                                        <option value="2">Amount Types 2</option>
                                        <option value="3">Amount Types 3</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <select name="value" id="value"
                                            className="form-control">
                                        <option value="0">Value</option>
                                        <option value="1">Value 1</option>
                                        <option value="2">Value 2</option>
                                        <option value="3">Value 3</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                        <button className="btn btn-primary w-100 mt-3">ADD MARKUP
                        </button>
                    </div>
                </div>
            </div>

            {/* Manage Markup Section */}
            <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                    <ul>
                        <li className="p-3 head-accordion">
                            <h6>Manage Markup</h6>
                            <a
                                href="#"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseTwo"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseTwo"
                                className="btn p-0 collapsed"
                            >
                                <img
                                    src="images2/arrow-up.png"
                                    alt="Arrow Up"
                                    width="28"
                                />
                            </a>
                        </li>
                    </ul>
                </h2>
                <div
                    id="panelsStayOpen-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingTwo"
                >
                    <div className="accordion-body">
                        <form>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">
                                        Created on{" "}
                                        <img src="images2/short.png" width="16" alt=""/>
                                    </th>
                                    <th scope="col">
                                        Markup Type{" "}
                                        <img src="images2/short.png" width="16" alt=""/>
                                    </th>
                                    <th scope="col">
                                        Airline{" "}
                                        <img src="images2/short.png" width="16" alt=""/>
                                    </th>
                                    <th scope="col">
                                        Pax Types{" "}
                                        <img src="images2/short.png" width="16" alt=""/>
                                    </th>
                                    <th scope="col">
                                        Amount Type{" "}
                                        <img src="images2/short.png" width="16" alt=""/>
                                    </th>
                                    <th scope="col">
                                        Value{" "}
                                        <img src="images2/short.png" width="16" alt=""/>
                                    </th>
                                    <th scope="col">
                                        Action{" "}
                                        <img src="images2/short.png" width="16" alt=""/>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Company Name</td>
                                    <td>International</td>
                                    <td>All/Air India</td>
                                    <td>N/A</td>
                                    <td>Fixed</td>
                                    <td>50,000</td>
                                    <td>
                                        <button
                                            className="btn py-0"
                                            title="Edit"
                                            type="button"
                                        >
                                            <img
                                                src="images2/edit.png"
                                                alt="Edit"
                                                width="20"
                                            />
                                        </button>
                                        <button
                                            className="btn py-0"
                                            title="Delete"
                                            type="button"
                                        >
                                            <img
                                                src="images2/trash.png"
                                                alt="Delete"
                                                width="20"
                                            />
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </div>

            {/* Manage Users Section */}
            <div className="manage-user card-box mt-3 bg-white">
                <div className="head p-3">
                    <div className="box d-flex flex-wrap pb-3 gap-3 align-items-center">
                        <h6 className="m-0">Manage Users</h6>
                        <div className="search-box ms-auto">
                            <input type="text" placeholder="Search by Keywords..."/>
                            <button className="btn btn-primary-theme">
                                <img
                                    src="images2/search-white.png"
                                    alt="Search"
                                    width="20"
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">
                                User Name{" "}
                                <img
                                    src="images2/short.png"
                                    width="16"
                                    alt="Sort"
                                />
                            </th>
                            <th scope="col">
                                Mobile Number{" "}
                                <img
                                    src="images2/short.png"
                                    width="16"
                                    alt="Sort"
                                />
                            </th>
                            <th scope="col">
                                Email ID{" "}
                                <img
                                    src="images2/short.png"
                                    width="16"
                                    alt="Sort"
                                />
                            </th>
                            <th scope="col">
                                Role{" "}
                                <img
                                    src="images2/short.png"
                                    width="16"
                                    alt="Sort"
                                />
                            </th>
                            <th scope="col">
                                Status{" "}
                                <img
                                    src="images2/short.png"
                                    width="16"
                                    alt="Sort"
                                />
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Sunny Mehra</td>
                            <td>+91 80540500340</td>
                            <td>sunny340@gmail.com</td>
                            <td>Counsellor</td>
                            <td>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="status-switch"
                                        checked
                                    />
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AddMarkup;