import React, {useEffect, useState} from "react"
import handBag from "../../../assets/fareIcons/hand-bag.png";
import baggage from "../../../assets/fareIcons/luggage.png";
import registered from "../../../assets/fareIcons/registered.png";
import checkFile from "../../../assets/fareIcons/check.png";
import {Card, Tooltip} from "../../../components/Elements";
import _ from "lodash"
import {getFlightFearRuleFxn} from "../../flightBooking/actions";

const CabinBagsComponent = (props) => {
    let {segments} = props;
    return (
        <Card title={'Hand Baggage'} className={'bag-tooltip'} bodyClass={'p-1'} headerClass={'p-1'}>
            <div className={'row'}>
                {segments && segments.length ? segments[0].map((item) => {
                    let {Origin, Destination} = item;
                    return (
                        <>
                            <div className={'col-md-6'}>
                                {Origin && Origin.Airport && Origin.Airport.AirportCode ? Origin.Airport.AirportCode : ""} - {Destination && Destination.Airport && Destination.Airport.AirportCode ? Destination.Airport.AirportCode : ""}
                            </div>
                            <div className={'col-md-6'}>
                                {item['CabinBaggage']}
                            </div>
                        </>
                    )
                }) : null}
            </div>
        </Card>
    )
}
const BaggageComponent = (props) => {
    let {segments} = props;
    return (
        <Card title={'Check-In Baggage'} className={'bag-tooltip'} bodyClass={'p-1'} headerClass={'p-1'}>
            <div className={'row'}>
                {segments && segments.length ? segments[0].map((item) => {
                    let {Origin, Destination} = item;
                    return (
                        <>
                            <div className={'col-md-6'}>
                                {Origin && Origin.Airport && Origin.Airport.AirportCode ? Origin.Airport.AirportCode : ""} - {Destination && Destination.Airport && Destination.Airport.AirportCode ? Destination.Airport.AirportCode : ""}
                            </div>
                            <div className={'col-md-6'}>
                                {item['Baggage']}
                            </div>
                        </>
                    )
                }) : null}
            </div>
        </Card>
    )
}
const FareRuleComponent = (props) => {
    let {flight, traceId} = props;
    let [cancelRules, setCancelRules] = useState([])
    let [issueRules, setIssueRules] = useState([])
    let {MiniFareRules: miniFareRules} = flight;
    let [fareRuleData, setFareRuleData] = useState("")
    let [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        events.setDD()
    }, [miniFareRules])

    let events = {
        setDD: () => {
            let issueRules = []
            let cancelRules = []
            if (miniFareRules && miniFareRules[0]) {
                _.each(miniFareRules[0], (item) => {
                    if (item) {
                        if (item['Type'] == "Reissue") {
                            issueRules.push(item)
                        }
                        if (item['Type'] == "Cancellation") {
                            cancelRules.push(item)
                        }
                    }
                })
                setCancelRules(cancelRules)
                setIssueRules(issueRules)
            }
        },
        getFareRuleFxn: async (e) => {
            let obj = {
                traceId,
                resultIndex: flight['ResultIndex']
            }
            setIsLoading(true)
            let {success, data: fareRuleData} = await getFlightFearRuleFxn(obj);
            setFareRuleData(fareRuleData)
            setIsLoading(false)
        }
    }

    let liRender = (item) => {
        return (
            <li style={{fontSize: 12, borderBottom: "1px solid #f1f1f1"}} className={'pb-1 mt-1'}>
                {item['Details']}<br/>
                {item['To'] ?
                    <>
                        From {item['From']} To {item['To']} {item['Unit']} & before dept
                    </> :
                    <>
                        From {item['From']} {item['Unit']} & above before dept
                    </>
                }
            </li>
        )
    }
    return (
        <div className={'dropdown-menu p-2'} style={{width: 800, fontSize: 12}} onClick={(e) => {
            e.stopPropagation();
        }}>
            <Card title={'Fare Rules'}>
                <table className={'table  table-sm table-border'}>
                    <tr>
                        <th>Cancellation</th>
                        <th>Reissue</th>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                {cancelRules && cancelRules.length ? cancelRules.map((item) => {
                                    return liRender(item)
                                }) : null}
                            </ul>
                        </td>
                        <td>
                            <ul>
                                {issueRules && issueRules.length ? issueRules.map((item) => {
                                    return liRender(item)
                                }) : null}
                            </ul>
                        </td>
                    </tr>
                </table>
            </Card>
            <Card title={"Important*"} className={'mt-2'}>
                <ul className="ifareul">
                    <li style={{fontWeight: "bold"}}>
                        <sup>*</sup>MENTIONED FEE ARE INDICATIVE PER PAX AND PER SECTOR.
                    </li>
                    <li>
                        APART FROM AIRLINE CHARGES,GST+RAF+ APPLICABLE CHARGES IF ANY, WILL BE
                        CHARGED.
                    </li>
                    <li>
                        FOR DOMESTIC BOOKINGS, PASSENGERS ARE REQUIRED TO SUBMIT THE CANCELLATION OR
                        REISSUE REQUEST AT LEAST 2 HOURS BEFORE THE AIRLINES CANCELLATION AND
                        REISSUE POLICY.
                    </li>
                    <li>
                        FOR INTERNATIONAL BOOKINGS, PASSENGERS ARE REQUIRED TO SUBMIT THE
                        CANCELLATION OR REISSUE REQUEST AT LEAST 4 HOURS BEFORE THE AIRLINES
                        CANCELLATION AND REISSUE POLICY.
                    </li>
                </ul>
                <div style={{textAlign: "right"}} className={'mt-1'}>
                    <a className={'btn-link cursor-pointer'} onClick={events.getFareRuleFxn}>
                        Click here for more details
                    </a>
                </div>
            </Card>

            {fareRuleData && fareRuleData['Airline'] ?
                <Card className={'mt-2'}>
                    <div
                        dangerouslySetInnerHTML={{__html: fareRuleData['FareRuleDetail'].replace(/\n/g, '<br />')}}></div>
                </Card> : null}

            {isLoading ? <div className={'p-2'} style={{textAlign: "center"}}>
                Loading ....
            </div> : null}
        </div>
    )
}

const FlightIcons = (props) => {
    let {flight} = props;
    return (
        <>
            <ul className={'fare-icons-box '}>
                <li>
                    <Tooltip title={<CabinBagsComponent segments={flight['Segments']}/>}>
                        <img src={handBag}/>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip title={<BaggageComponent segments={flight['Segments']}/>}>
                        <img src={baggage}/>
                    </Tooltip>
                </li>
                <li>
                    <Tooltip title={flight.IsRefundable ? "Refundable" : "Non Refundable"}>
                        <img src={registered}/>
                    </Tooltip>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link d-flex align-items-center" href="#"
                       role="button"
                       data-bs-toggle="dropdown"
                       aria-expanded="false">
                        <Tooltip title={'Fare rule'}>
                            <img src={checkFile}/>
                        </Tooltip>
                    </a>
                    <FareRuleComponent {...props}/>
                </li>
            </ul>
        </>
    )
}
export default FlightIcons
