import React from "react";

const FlightStatusComponent = () => {
    return (
        <>
            <div className="tab-head">
                <h6 className="m-0">Flight Tracking &amp; Status</h6>
            </div>
            <form>
                <div className="row row-gap-3">
                    <div className="col-lg-3 col-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Airlines"
                        />
                    </div>
                    <div className="col-lg-3 col-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Flight Code"
                        />
                    </div>
                    <div className="col-lg-3 col-12">
                        <input
                            type="date"
                            className="form-control"
                            placeholder="Date"
                        />
                    </div>
                    <div className="col-lg-3 col-12">
                        <button type="submit" className="btn btn-primary w-100">
                            Search
                        </button>
                    </div>
                </div>
            </form>
            <div className="track-flights">
                <div className="accordion" id="accordionPanelsStayOpenExampleTwo">
                    <div className="accordion-item">
                        <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingOne"
                        >
                            <ul>
                                <li className="p-3 head-accordion">
                                    <h6>Direct</h6>
                                    <h6
                                        style={{
                                            justifyContent: "center",
                                            textAlign: "center"
                                        }}
                                    >
                                        NEW DELHI{" "}
                                        <small className="d-block">Terminal 2</small>
                                    </h6>
                                    <span>
                          <img src="images2/plane.png" alt="plane"/>
                        </span>
                                    <h6
                                        style={{
                                            justifyContent: "center",
                                            textAlign: "center"
                                        }}
                                    >
                                        BOMBAY <small className="d-block">Terminal 3</small>
                                    </h6>
                                    <h6>
                                        6624485{" "}
                                        <small className="d-block">Flight Number</small>
                                    </h6>
                                    <h6>
                                        02:00 <small className="d-block">Departure</small>
                                    </h6>
                                    <h6>
                                        08:00 <small className="d-block">Arrival</small>
                                    </h6>
                                    <a href="#" className="btn btn-primary">
                                        Now at New York
                                    </a>
                                    <a
                                        href=""
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseOne"
                                        aria-expanded="true"
                                        aria-controls="panelsStayOpen-collapseOne"
                                        className="btn p-0"
                                    >
                                        <img
                                            src="images2/arrow-up.png"
                                            alt="arrow-up"
                                            width={28}
                                        />
                                    </a>
                                </li>
                            </ul>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="panelsStayOpen-headingOne"
                        >
                            <div className="accordion-body">
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                                Ut, optio!
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingTwo"
                        >
                            <ul>
                                <li className="p-3 head-accordion">
                                    <h6>Direct</h6>
                                    <h6
                                        style={{
                                            justifyContent: "center",
                                            textAlign: "center"
                                        }}
                                    >
                                        NEW DELHI{" "}
                                        <small className="d-block">Terminal 2</small>
                                    </h6>
                                    <span>
                          <img src="images2/plane.png" alt="plane"/>
                        </span>
                                    <h6
                                        style={{
                                            justifyContent: "center",
                                            textAlign: "center"
                                        }}
                                    >
                                        BOMBAY <small className="d-block">Terminal 3</small>
                                    </h6>
                                    <h6>
                                        6624485{" "}
                                        <small className="d-block">Flight Number</small>
                                    </h6>
                                    <h6>
                                        02:00 <small className="d-block">Departure</small>
                                    </h6>
                                    <h6>
                                        08:00 <small className="d-block">Arrival</small>
                                    </h6>
                                    <a href="#" className="btn btn-primary">
                                        Now at New York
                                    </a>
                                    <a
                                        href=""
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseOne"
                                        aria-expanded="true"
                                        aria-controls="panelsStayOpen-collapseOne"
                                        className="btn p-0"
                                    >
                                        <img
                                            src="images2/arrow-up.png"
                                            alt="arrow-up"
                                            width={28}
                                        />
                                    </a>
                                </li>
                            </ul>
                        </h2>
                        <div
                            id="panelsStayOpen-collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingTwo"
                        >
                            <div className="accordion-body">
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                                Dolores exercitationem dolorum vel fugiat mollitia,
                                recusandae assumenda impedit at distinctio ducimus.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FlightStatusComponent
