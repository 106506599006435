import {Card} from "../../../components/Elements";
import React from "react";
import SinglePassCard from "./SinglePassCard";

const GstDetailsComponent = (props) => {
    let {adultPassengers, childPassengers, infantPassengers, gstState, contactState} = props;
    return (
        <>
            <Card title={'GST Number for Business Travel (Optional)'} round={12}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <b>GSTCompanyName</b> : {gstState && gstState.gstCompanyName? `${gstState.gstCompanyName}` : ""}<br/>
                        <b>GSTCompanyAddress</b> : {gstState && gstState.gstCompanyAddress? `${gstState.gstCompanyAddress}` : ""}<br/>
                        <b>GSTCompanyContactNumber</b> : {gstState && gstState.gstCompanyContactNumber? `${gstState.gstCompanyContactNumber}` : ""}<br/>
                    </div>
                    <div className={'col-md-6'}>
                        <b>GSTNumber</b> : {gstState && gstState.gstNumber? `${gstState.gstNumber}` : ""}<br/>
                        <b>GSTCompanyEmail</b> : {gstState && gstState.gstCompanyEmail? `${gstState.gstCompanyEmail}` : ""}<br/>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default GstDetailsComponent
