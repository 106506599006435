import { Card } from "../../../components/Elements";
import React from "react";
import UserIcon from "../../../assets/img/user-icon.png";

const AncillaryServices = (props) => {
    let { adultPassenger, childPassenger, infantPassenger } = props;

    const renderPassengerServices = (passenger) => {
        const specialServicesText = passenger.ancillaryService?.specialServices?.map((service, index) => (
            <span key={index}>{service.Text}<br /></span>
        ));

        return (
            <tr key={passenger.firstName + passenger.lastName}>
                <td>{passenger.ancillaryService?.baggage?.Weight || 0} kg <br/>
                    {passenger.ancillaryService?.baggage?.Currency || 'INR'} {passenger.ancillaryService?.baggage?.Price || 0}
                </td>
                <td>Seat {passenger.ancillaryService?.seat?.Code || "N/A"}</td>
                <td>{passenger.ancillaryService?.meal?.Code || "--"}</td>
                <td>{specialServicesText || "--"}</td>
            </tr>
        );
    };

    const renderPassengerTable = (passengerArray, title, index) => {
        return (
            <div className="row mb-3" key={title}>
                <div className="col-md-3">
                    <p>
                        <img src={UserIcon} alt={'icon'} style={{ height: 16, marginRight: 5 }}/>  <strong>{title} {index + 1}</strong>
                    </p>
                </div>
                <div className="col-md-8">
                    <table className="table table-bordered text-center">
                        <thead>
                        <tr>
                            <th>Baggage</th>
                            <th>Seat</th>
                            <th>Meal</th>
                            <th>Special Services</th>
                        </tr>
                        </thead>
                        <tbody style={{ minHeight: '85px'}}>
                        {passengerArray?.map(renderPassengerServices)}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    return (
        <>
            <Card title={'Ancillary Services'} round={12}>
                <div>
                    {adultPassenger?.map((passenger, index) => renderPassengerTable([passenger], 'ADULT', index))}
                    {childPassenger?.map((passenger, index) => renderPassengerTable([passenger], 'CHILD', index))}
                    {/* {infantPassenger?.map(passenger => renderPassengerTable([passenger], passenger.firstName))} */}
                </div>
            </Card>
        </>
    );
};

export default AncillaryServices;
