import React, {useEffect, useState} from "react"
import FlightImg from "../../../components/flightImg";
import {CabinObject, dateWithDay, displayDate, displayTime, minToHours} from "../../../components/utils/appUtils";
import {Popover, Tooltip} from "../../../components/Elements"
import LuggageIcon from '../../../assets/img/luggages.png'
import FlightIcons from "../components/flightIcons";
const FlightAddress = ({data}) => {
    return (
        <>
            {data && data.Airport && data.Airport.CityName ? `${data.Airport.CityName} ` : ""}
        </>
    )
}
const AirportAddress = ({data}) => {
    return (
        <>
            <small className="d-block">
                {data && data.Airport && data.Airport.AirportName ? data.Airport.AirportName : ""}
                {data && data.Airport && data.Airport.AirportCode ? ` (${data.Airport.AirportCode}) ` : ""}
            </small>
        </>
    )
}
const AllSegmentComponent = (props) => {
    let {allSegmentData, children} = props;
    const contentBody = (
        <>
            <table className={'table table-border'}>
                <tr>
                    <th>Airline Info</th>
                    <th>Flight Info</th>
                    <th>Dept.T</th>
                    <th>Arr.T</th>
                </tr>
                {allSegmentData && allSegmentData.length ? allSegmentData.map((item) => {
                    let {Airline, Origin, Destination} = item;
                    return (
                        <>
                            <tr>
                                <td>
                                    {Airline && Airline.AirlineCode} {Airline && Airline.FlightNumber} {Airline && Airline.FareClass}
                                </td>
                                <td className={'justify-content-between'}>
                                    {Origin ? <>
                                        {Origin.Airport && Origin.Airport.AirportCode ? Origin.Airport.AirportCode : ""}
                                        ({Origin.DepTime ? displayTime(Origin.DepTime) : ""})
                                    </> : null}
                                    &nbsp;-&nbsp;

                                    {Destination ? <>
                                        {Destination.Airport && Destination.Airport.AirportCode ? Destination.Airport.AirportCode : ""}
                                        ({Destination.ArrTime ? displayTime(Destination.ArrTime) : ""})
                                    </> : null}
                                </td>
                                <td>

                                </td>
                                <td>

                                </td>
                            </tr>

                        </>
                    )
                }) : null}
            </table>
        </>
    )
    return (
        <>
            <Popover content={contentBody}>
                <h6>
                    {children}
                </h6>
            </Popover>
        </>
    )
}

const NonStopComponent = (props) => {
    let {data} = props;
    let {Segments, FareRules: fareRules} = data;
    let allSegmentData = Segments[0];
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    return (
        <>
            <div className={''}>

                <ul>
                    <li className="p-0 flex-wrap">
                        <div className="d-flex w-100 gap-3">
                              <span className="logo">
                                     <img src={`../airlineLogos/${data.AirlineCode}.gif`} alt=""/>
                              </span>
                            <h6>
                                {segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                                <small className="d-block">
                                    {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                                    {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                                    {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                                </small>
                            </h6>
                            <h6>
                                {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null} -
                                {segmentEnding.Destination ? <FlightAddress data={segmentStarting.Destination}/> : null}

                                <small className="d-block">
                                    {segmentEnding && segmentEnding.Duration ? minToHours(segmentEnding.Duration) : ""}
                                </small>
                            </h6>
                        </div>
                        <div
                            className="d-flex align-items-center overflow-auto"
                            style={{gap: 30}}>
                            <h6>
                                {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                    {displayTime(segmentStarting.Origin.DepTime)}
                                </> : null}
                                {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                    <small className="d-block">  {dateWithDay(segmentStarting.Origin.DepTime)}</small>
                                </> : null}

                                {segmentStarting.Origin ? <AirportAddress data={segmentStarting.Origin}/> : null}
                            </h6>
                            <span>
                                  <FlightImg/>
                            </span>
                            <AllSegmentComponent allSegmentData={allSegmentData}>
                                Non-Stop
                                <small className="d-block">
                                    {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null} -
                                    {segmentEnding.Destination ?
                                        <FlightAddress data={segmentStarting.Destination}/> : null}
                                </small>
                                <small className="d-block">
                                    {segmentEnding && segmentEnding.Duration ? minToHours(segmentEnding.Duration) : ""}
                                </small>
                            </AllSegmentComponent>
                            <span>
                                  <FlightImg/>
                            </span>
                            <h6>
                                {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                    {displayTime(segmentEnding.Destination.ArrTime)}
                                </> : null}
                                {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                    <small className="d-block">{dateWithDay(segmentEnding.Destination.ArrTime)}</small>
                                </> : null}
                                {segmentStarting.Destination ?
                                    <AirportAddress data={segmentStarting.Destination}/> : null}
                            </h6>
                        </div>
                    </li>
                </ul>


            </div>
        </>
    )
}
const WithStopComponent = (props) => {
    let {data} = props;
    let {Segments, FareRules: fareRules} = data;
    let allSegmentData = Segments[0];
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    let originObj = fareRules[0];
    let destinationObj = fareRules[fareRules.length - 1];

    let stopsComponent = (
        <>
            <span><FlightImg/></span>
            <h6>
                <AllSegmentComponent allSegmentData={allSegmentData}>
                    {allSegmentData.length - 1} Stop(s)
                </AllSegmentComponent>
                <small className="d-block">
                    {originObj && originObj.Origin ? originObj.Origin : ""}
                    &nbsp;-&nbsp;
                    {destinationObj && destinationObj.Destination ? destinationObj.Destination : ""}
                </small>
                <small className="d-block">
                    {segmentStarting && segmentStarting.Duration ? <>
                        {minToHours(segmentStarting.Duration)}
                    </> : null}
                </small>
            </h6>
            <span><FlightImg/></span>
        </>
    )

    return (
        <>

            <ul>
                <li className="p-0 flex-wrap">
                    <div className="d-flex w-100 gap-3">
                              <span className="logo">
                                     <img src={`../airlineLogos/${data.AirlineCode}.gif`} alt=""/>
                              </span>
                        <h6>
                            {segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                            <small className="d-block">
                                {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                            </small>
                        </h6>
                        <h6>
                            {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null} -
                            {segmentEnding.Destination ? <FlightAddress data={segmentStarting.Destination}/> : null}

                            <small className="d-block">
                                {segmentEnding && segmentEnding.Duration ? minToHours(segmentEnding.Duration) : ""}
                            </small>
                        </h6>
                    </div>
                    <div
                        className="d-flex align-items-center overflow-auto"
                        style={{gap: 30}}>
                        <h6>
                            {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                {displayTime(segmentStarting.Origin.DepTime)}
                            </> : null}
                            {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                <small className="d-block">  {dateWithDay(segmentStarting.Origin.DepTime)}</small>
                            </> : null}

                            {segmentStarting.Origin ? <AirportAddress data={segmentStarting.Origin}/> : null}
                        </h6>
                        {stopsComponent}
                        <h6>
                            {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                {displayTime(segmentEnding.Destination.ArrTime)}
                            </> : null}
                            {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                <small className="d-block">{dateWithDay(segmentEnding.Destination.ArrTime)}</small>
                            </> : null}
                            {segmentStarting.Destination ?
                                <AirportAddress data={segmentStarting.Destination}/> : null}
                        </h6>
                    </div>
                </li>
            </ul>


        </>
    )
}

const RoundFlightDetailsBox = (props) => {
    let {data, onFlightSelect, flightData, type, flightInfo} = props;
    let [selectedFlight, setSelectedFlight] = useState({})
    useEffect(() => {
        setSelectedFlight(flightData)
    }, [flightData])
    const handleFlightSelection = (flight) => {
        setSelectedFlight(flight);
        onFlightSelect(flight);
    };

    return (
        data && data[0] && data[0]['Segments'] && data[0]['Segments'].length ? <>
            <div className="book-flight mb-3">
                <div className="row row-gap-3 mb-3">
                    <div className="col-lg-12">
                        <div className={''}>
                            <div className="track-flights">
                                {data[0]['Segments'][0].length > 1 ?
                                    <WithStopComponent data={data[0]}/> :
                                    <NonStopComponent data={data[0]}/>}
                            </div>

                            <div className="class-price mt-2">
                                <div className="row">
                                    <div className="row row-gap-3">
                                        {data.map((item) => {
                                            let firstSegment = item['Segments'][0][0]
                                            return (
                                                <div className="col-lg-6">
                                                    <div className="main-box">
                                                        <input type="radio" name={`class-price-${type}`}
                                                               className="class-price"
                                                               checked={item['ResultIndex'] == flightData['ResultIndex']}
                                                               onChange={() => handleFlightSelection(item)}
                                                        />
                                                        <div className="box">
                                                            <span></span>
                                                            <small>{CabinObject[firstSegment['CabinClass']]}</small>
                                                            <h5>Rs. {item['Fare'] && item['Fare'].PublishedFare ? item['Fare'].PublishedFare : ""}</h5>
                                                        </div>
                                                    </div>
                                                    <FlightIcons flight={item} traceId={flightInfo.traceId}/>

                                                </div>
                                            )
                                        })}


                                    </div>
                                    {/*     <div className="row row-gap-3 mt-2">
                                    <div className="col-lg-6">
                                        <button className="btn btn-grey text-nowrap">+1 more fare...</button>
                                    </div>
                                </div>*/}

                                    <div className="details d-flex gap-3 flex-wrap align-items-center mt-3">
                                        {/*<button
                                            className="btn btn-grey text-nowrap d-flex gap-2 align-items-center">View Details
                                        </button>*/}

                                        {selectedFlight['Segments'] && selectedFlight['Segments'][0] && selectedFlight['Segments'][0][0].NoOfSeatAvailable ?
                                            <p className="m-0" style={{fontSize: '14px'}}>
                                                {/*Flight Arrives after 1 Day(s)*/}
                                                <b className="ms-2"
                                                   style={{color: 'red'}}>
                                                    Seat(s)
                                                    left: {selectedFlight['Segments'][0][0].NoOfSeatAvailable}</b>
                                            </p> : null}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </> : null
    )
}
export default RoundFlightDetailsBox
