import React, {useEffect} from "react";
import FlightSlider from "../../components/pageComponents/flightSlider";
import ExclusiveDeals from "../../components/pageComponents/exclusiveDeals";
import {Form} from "../../components/Elements";
import DownloadApplications from "../../components/pageComponents/downloadApplications";
import Reviews from "../../components/pageComponents/reviews";
import ManageBookingComponent from "./components/manageBookingComponent";
import FlightStatusComponent from "./components/flightStatusComponent";
import LastSearchFlightComponent from "./components/lastSearchFlightComponent";
import SearchFlightMainComponent from "./searchComponent/SearchFlightMainComponent";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";


const FlightBooking = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({type: "RESET_FLIGHT"})
    }, [])


    return (
        <div>
            <div className="flight-booking spacing-150 pb-0" style={{position: "inherit"}}>
                <div className="banner-box" style={{height: 600}}>
                    <img
                        src="../img3/find_flight_bg.jpg"
                        alt="home-banner"
                        className="w-100"
                    />
                </div>
                <div className="container position-relative">
                    <div className="col-lg-12">
                        <ul className="nav nav-tabs justify-content-center border-0 gap-3"
                            id="myTab"
                            role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active rounded-pill border-0"
                                    id="book-flight-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#book-flight"
                                    type="button"
                                    role="tab"
                                    aria-controls="book-flight"
                                    aria-selected="true"
                                >
                                    <img src="images2/book-flights.png" alt=""/> book flight
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link rounded-pill border-0"
                                    id="manage-booking-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#manage-booking"
                                    type="button"
                                    role="tab"
                                    aria-controls="manage-booking"
                                    aria-selected="false"
                                >
                                    <img src="images2/manage-bookings.png" alt=""/> manage
                                    booking
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link rounded-pill border-0"
                                    id="flight-status-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#flight-status"
                                    type="button"
                                    role="tab"
                                    aria-controls="flight-status"
                                    aria-selected="false"
                                >
                                    <img src="images2/flight-status.png" alt=""/> flight status
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link rounded-pill border-0"
                                    id="last-search-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#last-search"
                                    type="button"
                                    role="tab"
                                    aria-controls="last-search"
                                    aria-selected="false"
                                >
                                    <img src="images2/last-search-flight.png" alt=""/> last
                                    search flights
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content p-3 p-lg-4 bg-white mt-4" id="myTabContent">
                            <div className="tab-pane fade show active"
                                 id="book-flight"
                                 role="tabpanel"
                                 aria-labelledby="book-flight-tab">
                                <SearchFlightMainComponent
                                    {...props}
                                    onClick={(resp) => {
                                        navigate('/find-flight')
                                    }}/>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="manage-booking"
                                role="tabpanel"
                                aria-labelledby="manage-booking-tab">
                                <ManageBookingComponent/>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="flight-status"
                                role="tabpanel"
                                aria-labelledby="flight-status-tab">
                                <FlightStatusComponent/>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="last-search"
                                role="tabpanel"
                                aria-labelledby="last-search-tab">
                                <LastSearchFlightComponent/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<FlightSlider/>*/}
            <ExclusiveDeals/>
            <DownloadApplications/>
            <Reviews size={'sm'}/>
        </div>
    );
}

export default Form.create()(FlightBooking);
