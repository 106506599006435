import React, {useEffect, useState} from "react"
import FlightInfoComponent from "./components/FlightInfoComponent";
import {Icon, Input, notification, PhoneNo} from "../../components/Elements";
import FlightImg from "../../components/flightImg";
import {FlightSummary, ReturnFlightSummary} from "./components/summary";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import {flightsFearQuoteFxn, holdFlightBookFxn, lccFlightTicketFxn} from "../flightBooking/actions";
import ReviewBookingComponent from "./components/ReviewBookingComponent";
import AncillaryServices from "./components/AncillaryServices";
import ContactDetailsComponent from "./components/ContactDetailsComponent";
import GstDetailsComponent from "./components/GstDetailsComponent";
import ArrowIcon from '../../assets/img/arrow.png'
import ItenaryIcon from '../../assets/img/iternary.png'
import PassengerIcon from '../../assets/img/passenger.png'
import PaymentIcon from '../../assets/img/payment2.png'
import TickIcon from '../../assets/img/tick.png'
import ArrowUpIcon from '../../assets/img/arrowUp.png'
import ReviewIcon from '../../assets/img/review.png'
import Passengers from "./components/passengers";
import TicketResponse from "../ticketResponse";
import PaymentComponent from "./components/paymentComponent";

const contactInitState = {
    email: "",
    contactNo: ""
}
const OneWayBooking = (props) => {
    let {flightObj, selectedFlight, traceId, ssrResponse} = props;
    const navigate = useNavigate();
    const [state, setState] = useState(contactInitState)
    let dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(1)
    const [fareObject, setFareObject] = useState({})
    const [inboundFareObj, setInboundFareObj] = useState({})
    const [outboundFareObj, setOutboundFareObj] = useState({})
    const [childPassengers, setChildPassengers] = useState([]);
    const [adultPassengers, setAdultPassengers] = useState([]);
    const [infantPassengers, setInfantPassengers] = useState([]);
    const [redeemAmount, setRedeemAmount] = useState('');
    const [selectedPayment, setSelectedPayment] = useState('');
    let [passengerState, setPassengerState] = useState({})
    const [ticketId, setTicketId] = useState(null);
    const [fareState, setFareState] = useState({
        oldFare: {},
        newFare: {},
        quoteFare: {},
        visible: false
    })
    const [inboundFareState, setInboundFareState] = useState({
        oldFare: {},
        newFare: {},
        quoteFare: {},
        visible: false
    })
    const [outboundFareState, setOutboundFareState] = useState({
        oldFare: {},
        newFare: {},
        quoteFare: {},
        visible: false
    })
    const handleRedeemChange = (e) => {
        setRedeemAmount(e.target.value);
    };
    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
    };
    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        updateAdultPassenger: (data, index) => {
            let cloneD = _.clone(adultPassengers);
            cloneD[index] = {...cloneD[index], ...data}
            setAdultPassengers(cloneD)
        },
        updateChildPassenger: (data, index) => {
            let cloneD = _.clone(childPassengers);
            cloneD[index] = {...cloneD[index], ...data}
            setChildPassengers(cloneD)
        },
        updateInfantPassengers: (data, index) => {
            let cloneD = _.clone(infantPassengers);
            cloneD[index] = {...cloneD[index], ...data}
            setInfantPassengers(cloneD)
        },
        fareQuote: async () => {
            // let obj = {
            //     traceId: traceId,
            //     resultIndex: selectedFlight['ResultIndex'],
            // };

            if (selectedFlight.mappedInBoundFlights && selectedFlight.mappedOutBoundFlights) {
                if (selectedFlight.mappedInBoundFlights) {
                    const {data, success} = await dispatch(
                        flightsFearQuoteFxn({
                            traceId: traceId,
                            resultIndex: selectedFlight.mappedInBoundFlights['ResultIndex']
                        })
                    );
                    if (success) {
                        events.checkInboundPriceDifferent(data);
                    }
                }
                if (selectedFlight.mappedOutBoundFlights) {
                    const {data, success} = await dispatch(
                        flightsFearQuoteFxn({
                            traceId: traceId,
                            resultIndex: selectedFlight.mappedOutBoundFlights['ResultIndex']
                        })
                    );
                    if (success) {
                        events.checkOutboundPriceDifferent(data);
                    }
                }

            } else {

                const {data, success} = await dispatch(flightsFearQuoteFxn({
                    traceId: traceId,
                    resultIndex: selectedFlight['ResultIndex']
                }));
                // console.log(data, '9999999999');
                if (success) {
                    events.checkPriceDifferent(data);
                }
            }
        },
        checkPriceDifferent: (data) => {
            // console.log(data, "---------")
            // let {Fare: selectedFare} = selectedFlight;
            let {Fare: quoteFare} = data;
            if (quoteFare['PublishedFare'] == quoteFare['PublishedFare'] && quoteFare['OfferedFare'] == quoteFare['OfferedFare']) {
                setFareObject(data);
            } else {
                dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data})
                setFareState({
                    oldFare: quoteFare,
                    newFare: quoteFare,
                    quoteFare: data,
                    visible: true
                })
            }
        },
        checkInboundPriceDifferent: (data) => {
            // console.log(data, "---------")
            // let {Fare: selectedFare} = selectedFlight;
            let {Fare: quoteFare} = data;
            if (quoteFare['PublishedFare'] == quoteFare['PublishedFare'] && quoteFare['OfferedFare'] == quoteFare['OfferedFare']) {
                setInboundFareObj(data);
            } else {
                dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data})
                setInboundFareState({
                    oldFare: quoteFare,
                    newFare: quoteFare,
                    quoteFare: data,
                    visible: true
                })
            }
        },
        checkOutboundPriceDifferent: (data) => {
            // console.log(data, "---------")
            // let {Fare: selectedFare} = selectedFlight;
            let {Fare: quoteFare} = data;
            if (quoteFare['PublishedFare'] == quoteFare['PublishedFare'] && quoteFare['OfferedFare'] == quoteFare['OfferedFare']) {
                setOutboundFareObj(data);
            } else {
                dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data})
                setOutboundFareState({
                    oldFare: quoteFare,
                    newFare: quoteFare,
                    quoteFare: data,
                    visible: true
                })
            }
        },
        closeChangePriceModal: (data) => {
            setFareState({
                oldFare: {},
                newFare: {},
                quoteFare: {},
                visible: false,
            });
        },
        submitChangePriceModal: (data) => {
            setFareObject(data);
            dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data});
            setFareState({
                oldFare: {},
                newFare: {},
                quoteFare: {},
                visible: false,
            });
        },
        addPassenger: () => {
            setActiveTab(2)
        },
        sendForReview: (e) => {
            e.preventDefault();
            let allPassenger = [...adultPassengers, ...childPassengers, ...infantPassengers]
            let findE = _.find(allPassenger, (item) => {
                return !item.title && !item.firstName && !item.lastName && !item.gender && !item.day && !item.month && !item.year
            })
            if (findE) {
                notification.error({message: "Please enter all required fields."})
                return
            }

            setActiveTab(3)
        },
        bookTicket: async () => {
            let {adultPassengers = [], childPassengers = [], infantPassengers = [], contactState = {}} = passengerState;
            let allPassenger = [];
            if (adultPassengers && adultPassengers.length) {
                _.each(adultPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 1})
                })
            }
            if (childPassengers && childPassengers.length) {
                _.each(childPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 2})
                })
            }
            if (infantPassengers && infantPassengers.length) {
                _.each(infantPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 3})
                })
            }

            let obj = {
                traceId: traceId,
                resultIndex: selectedFlight['ResultIndex'],
                allPassenger,
                fareQuote: fareObject,
                isLcc: selectedFlight['IsLCC'],
                ...passengerState.state,
                ...contactState
            }
            let {ticketId, sessionId, success} = await dispatch(lccFlightTicketFxn(obj));

            if (success) {
                setTicketId(ticketId);
                dispatch({type: "UPDATE_SESSION_ID", sessionId: sessionId})
                // navigate(`/response/${ticketId}`);
                navigate(`/response/${ticketId}`, {state: {params: {sessionId}, passengerState}});
            } else {
                // navigate(`/response/${ticketId}`);
            }
        },

        holdBookingFxn: async () => {
            let {adultPassengers = [], childPassengers = [], infantPassengers = [], contactState = {}} = passengerState;
            let allPassenger = [];
            if (adultPassengers && adultPassengers.length) {
                _.each(adultPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 1})
                })
            }
            if (childPassengers && childPassengers.length) {
                _.each(childPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 2})
                })
            }
            if (infantPassengers && infantPassengers.length) {
                _.each(infantPassengers, (item) => {
                    allPassenger.push({...item, passengerType: 3})
                })
            }

            let obj = {
                traceId: traceId,
                resultIndex: selectedFlight['ResultIndex'],
                allPassenger,
                fareQuote: fareObject,
                isLcc: selectedFlight['IsLCC'],
                ...passengerState.state,
                ...contactState
            }
            let {data, ticketId, sessionId, success} = await dispatch(holdFlightBookFxn(obj));

            if (success) {
                setTicketId(ticketId);
                dispatch({type: "UPDATE_SESSION_ID", sessionId: sessionId})
                // navigate(`/response/${ticketId}`);
                navigate(`/response/${ticketId}`, {state: {params: {sessionId}, data, passengerState}});
            } else {
                // navigate(`/response/${ticketId}`);
            }
        },

        goBackToPreviousTab: () => {
            if (activeTab > 0) {
                setActiveTab(activeTab - 1);
            }
        }
    }
    useEffect(() => {
        events.fareQuote()
    }, [])
    return (
        <>
            <div className="checkout-box bg-gray">
                <div className="flight-booking bg-white spacing-40">
                    <div className="container position-relative">
                        <div className="col-lg-12">
                            <ul className="nav nav-tabs border-0 gap-3" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 1 ? "active" : ""}`}
                                        id="flight-itinerary-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#flight-itinerary"
                                        type="button"
                                        role="tab"
                                        aria-controls="flight-itinerary"
                                        disabled={activeTab !== 1}
                                        aria-selected="true">
                                        <img src={ItenaryIcon} alt={'arrow'}/>

                                        Flight Itinerary
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 2 ? "active" : ""}`}
                                        id="passenger-details-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#passenger-details"
                                        type="button"
                                        role="tab"
                                        disabled={activeTab !== 2}
                                        aria-controls="passenger-details"
                                        aria-selected="false">
                                        <img src={PassengerIcon} alt={'arrow'}/>
                                        Passenger Details

                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 3 ? "active" : ""}`}
                                        id="review-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#review"
                                        type="button"
                                        role="tab"
                                        disabled={activeTab !== 3}
                                        aria-controls="review"
                                        aria-selected="false"
                                    >
                                        <img src={ReviewIcon} alt={'arrow'}/>
                                        Review
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link  rounded-pill border-0 ${activeTab == 4 ? "active" : ""}`}
                                        id="payment-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#payment"
                                        type="button"
                                        role="tab"
                                        disabled={activeTab !== 4}
                                        aria-controls="payment"
                                        aria-selected="false">
                                        <img src={PaymentIcon} alt={'arrow'}/>
                                        Payment
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="spacing-40 container">

                    <div className="row row-gap-3">
                        <div className="col-lg-9">

                            <div className="tab-content" id="myTabContent">
                                <div className={`tab-pane fade ${activeTab == 1 ? "show active" : ""}`}
                                     id="flight-itinerary" role="tabpanel"
                                     aria-labelledby="flight-itinerary-tab">
                                    <div className="head d-flex gap-3 align-items-center mb-3">
                                        <span>Visa, Passport & Health Regulations</span>
                                        <button
                                            className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2"
                                            onClick={() => window.history.back()}> {/* Use a function to go back */}
                                            Back to Search
                                            <img src={ArrowIcon} alt={'arrow'}/>
                                        </button>
                                    </div>
                                    <div className="find-flight-main">
                                        <FlightInfoComponent {...props} />


                                     {/*   <div className="accordion-flight-track">
                                            <p className="note">
                                                Require to change Plane - Layover Time: 3h 15m
                                            </p>
                                        </div>*/}

                                        <button className="btn btn-primary w-100 mt-3"
                                                onClick={() => events.addPassenger()}>ADD
                                            PASSENGERS DETAILS
                                        </button>
                                    </div>
                                </div>


                                <div className={`tab-pane fade ${activeTab == 2 ? "show active" : ""}`}
                                     id="passenger-details" role="tabpanel"
                                     aria-labelledby="passenger-details-tab">
                                    <div className="head d-flex gap-3 align-items-center mb-3">
                                        <span>Select from History</span>
                                        <button
                                            className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2"
                                            onClick={events.goBackToPreviousTab}>

                                            Back to Search <img src={ArrowIcon} alt={'arrow'}/>
                                        </button>
                                    </div>
                                    {selectedFlight.mappedInBoundFlights || selectedFlight.mappedOutBoundFlights ? (
                                        <>
                                            <div className={'row'}>
                                                <div className={'col-md-6'}>
                                                    {selectedFlight.mappedInBoundFlights && (
                                                        <FlightInfoComponent
                                                            selectedFlight={selectedFlight.mappedInBoundFlights}
                                                            flightObj={flightObj}
                                                        />
                                                    )}
                                                </div>
                                                <div className={'col-md-6'}>
                                                    {selectedFlight.mappedOutBoundFlights && (
                                                        <FlightInfoComponent
                                                            selectedFlight={selectedFlight.mappedOutBoundFlights}
                                                            flightObj={flightObj}
                                                        />
                                                    )}
                                                </div>
                                            </div>

                                        </>
                                    ) : (
                                        <>

                                            <FlightInfoComponent selectedFlight={selectedFlight} flightObj={flightObj}/>
                                        </>
                                    )}

                                    <Passengers {...props} flightObj={flightObj}
                                                selectedFlight={selectedFlight}
                                                ssrResponse={ssrResponse}
                                                callbackState={(resp) => {
                                                    setPassengerState(resp)
                                                    setActiveTab(3)
                                                }}/>
                                </div>


                                <div
                                    className={`tab-pane fade ${activeTab === 3 ? "show active" : ""}`}
                                    id="review"
                                    role="tabpanel"
                                    aria-labelledby="review-tab"
                                >


                                    <>
                                        <div className="head d-flex gap-3 align-items-center mb-3">
                                            <span>Select from History</span>
                                            <button
                                                className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2"
                                                onClick={events.goBackToPreviousTab}>

                                                Back to Search <img src={ArrowIcon} alt={'arrow'}/>
                                            </button>
                                        </div>
                                        <FlightInfoComponent selectedFlight={selectedFlight} flightObj={flightObj}/>
                                        <ReviewBookingComponent
                                            passengerState={passengerState}
                                            selectedFlight={selectedFlight}
                                            flightObj={flightObj}
                                            callbackState={() => {
                                                setActiveTab(4)
                                            }}
                                            bookState={events.holdBookingFxn}
                                        />
                                    </>


                                </div>


                                <div className={`tab-pane fade ${activeTab == 4 ? "show active" : ""}`} id="payment"
                                     role="tabpanel"
                                     aria-labelledby="payment-tab">
                                    <div className="head d-flex gap-3 align-items-center mb-3">
                                        <button
                                            className="btn p-0 bg-transparent ms-auto d-flex align-items-center gap-2"
                                            onClick={events.goBackToPreviousTab}>

                                            Back to Search <img src={ArrowIcon} alt={'arrow'}/>
                                        </button>
                                    </div>
                                    {/*{ticketId !== null && (*/}
                                    {/*    <>*/}
                                    {/*        <TicketResponse ticketId={ticketId} passengerState={passengerState}*/}
                                    {/*                        fareObject={fareObject} flightObj={flightObj}/>*/}
                                    {/*    </>*/}
                                    {/*)}*/}
                                    {ticketId === null && (
                                        <>
                                            <FlightInfoComponent selectedFlight={selectedFlight} flightObj={flightObj}/>

                                            <PaymentComponent passengerState={passengerState}
                                                              fareObject={fareObject}
                                                              traceId={traceId}
                                                              state={state}
                                                              selectedFlight={selectedFlight}
                                                              callbackState={events.bookTicket}
                                                // setTicketId={setTicketId}

                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {
                                inboundFareObj.ResultIndex &&
                                outboundFareObj.ResultIndex ? (
                                    <ReturnFlightSummary
                                        inboundData={inboundFareObj}
                                        outboundData={outboundFareObj}
                                    />
                                ) : fareObject && fareObject.ResultIndex ? (
                                    <FlightSummary flightData={fareObject}
                                                   passengerState={passengerState}
                                    />
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OneWayBooking
