import React from "react";
import {Outlet, Navigate} from "react-router-dom";

const FullPageLayout = (props) => {
    let {currentUser} = props;
    return (
        currentUser && currentUser._id ? <>
                <Outlet/>
            </> :
            <Navigate replace to="/login"/>
    );
}

export default FullPageLayout;
