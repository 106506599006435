import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {listAllTransferFunctionFxn2} from "../flightBooking/actions";
import moment from "moment";

const DepositList = () => {
    const dispatch = useDispatch();
    const [depositList, setDepositList] = useState([]);
    const [filters, setFilters] = useState({date: "", agent: "", transactionType: ""});
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [totalItems, setTotalItems] = useState(0);

    const fetchDeposits = async (page = 1, filters = {}) => {
        try {
            const params = {
                page,
                results: 50,
                count: 50
            };
            if (filters.date) {
                params.date = {
                    $gte: moment(filters.date).startOf('day').toDate(),
                    $lte: moment(filters.date).endOf('day').toDate()
                };
            }
            if (filters.agent) {
                params.agentName = filters.agent
            }
            if (filters.transactionType) {
                params.transactionType = filters.transactionType
            }
            const resp = await dispatch(listAllTransferFunctionFxn2({
                ...params, regExFilters: ['agentName']
            }));
            setDepositList(resp.data);
            setTotalItems(resp.data.total);
        } catch (error) {
            console.error("Error fetching deposits:", error);
        }
    };

    const handleFilterChange = (e) => {
        const {name, value} = e.target;
        setFilters((prev) => ({...prev, [name]: value}));
    };

    const applyFilters = () => {
        setCurrentPage(1); // Reset to the first page
        fetchDeposits(1, filters); // Fetch data with filters
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        fetchDeposits(pageNumber, filters); // Fetch data for the selected page
    };

    useEffect(() => {
        fetchDeposits(currentPage, filters);
    }, []); // Fetch data on component mount

    return (
        <div className="row">
            <div className="col-lg-12">
                <h4 className="mb-4">Deposit List</h4>

                {/* Filter Section */}
                <div className="mb-4 col-12 d-flex">
                    <div className={"col-3 ms-2 mb-2"}>
                        <input
                            type="date"
                            name="date"
                            className="form-control"
                            value={filters.date}
                            onChange={handleFilterChange}
                            placeholder="Filter by Date"
                        />
                    </div>

                    <div className={"col-3 ms-2 mb-2"}>
                        <input
                            type="text"
                            name="agent"
                            className="form-control"
                            value={filters.agent}
                            onChange={handleFilterChange}
                            placeholder="Filter by Agent Name"
                        />
                    </div>
                    <div className={"col-3 ms-2 mb-2"}>
                        <select
                            name="transactionType"
                            className="form-control"
                            value={filters.transactionType}
                            onChange={handleFilterChange}
                        >
                            <option value="">Transaction Type (Credit & Debit)</option>
                            <option value="Credit">Credit</option>
                            <option value="Debit">Debit</option>
                        </select>
                    </div>
                    <div className={"col-3 ms-2 mb-2"}>
                        <button className="btn btn-primary ms-2" onClick={applyFilters}>
                            Apply Filters
                        </button>
                    </div>
                </div>

                {/* Table Section */}
                <div className="ticket-list">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Company Name</th>
                            <th>Credit</th>
                            <th>Debit</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {depositList.length > 0 ? (
                            depositList.map((deposit, index) => (
                                <tr key={index}>
                                    <td>{deposit.agentName || "N/A"}</td>
                                    <td>{deposit.agentCompanyName || "N/A"}</td>
                                    <td
                                        style={{
                                            color: "lightgreen",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {deposit.transactionType === "Credit"
                                            ? deposit.amount
                                            : ""}
                                    </td>
                                    <td
                                        style={{
                                            color: "red",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {deposit.transactionType === "Debit"
                                            ? deposit.amount
                                            : ""}
                                    </td>
                                    <td>
                                        {moment(deposit.date).format(
                                            "DD-MMM-YYYY"
                                        )}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">
                                    No data available
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Section */}
                <div className="pagination mt-3">
                    {Array.from(
                        {length: Math.ceil(totalItems / itemsPerPage)},
                        (_, index) => (
                            <button
                                key={index}
                                className={`btn ${
                                    currentPage === index + 1
                                        ? "btn-primary"
                                        : "btn-secondary"
                                } mx-1`}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default DepositList;
