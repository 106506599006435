import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash"
import {flightsFearQuoteFxn, lccFlightTicketFxn, ticketBySessionIdFxn} from "../flightBooking/actions";
import {notification} from "../../components/Elements";
import RegisterBanner from "../../components/pageComponents/registerBanner";
import {Link, useLocation, useNavigate} from "react-router-dom";
import ChangePrice from "../ModalComponent/changePrice";
import OneWayBooking from "./oneWayBooking";
import RoundTripBooking from "./roundTripBooking";
import OverseasBooking from "./overseasBooking";
import {bookSeatsFxn} from "../ticketList/action";

const contactInitState = {
    email: "",
    contactNo: ""
}
const Checkout = () => {
    const [bookingType, setBookingType] = useState("Outbound")
    const [ssrResponse, setSsrResponse] = useState({});

    const {flightObj, traceId, selectedFlight, fareQuoteObj, flightBookingType} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj,
        selectedFlight: state.flightsReducer.selectedFlight,
        traceId: state.flightsReducer.traceId,
        fareQuoteObj: state.flightsReducer.fareQuoteObj,
        flightBookingType: state.flightsReducer.flightBookingType,
    }))
    const location = useLocation();
    const stateParams = location.state?.params;
    useEffect(() => {
        if (stateParams && stateParams.bookingType) {
            setBookingType(stateParams.bookingType)
        }
    }, [stateParams])
    const [state, setState] = useState(contactInitState)
    let dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(1)
    const [fareObject, setFareObject] = useState({})
    const [outboundFareObj, setOutboundFareObj] = useState({})


    const [fareState, setFareState] = useState({
        oldFare: {},
        newFare: {},
        quoteFare: {},
        visible: false
    })

    useEffect(() => {
        events.fareQuote();
    }, [traceId, selectedFlight, bookingType])


    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        fareQuote: async () => {
            if (selectedFlight.mappedOutBoundFlights) {
                let bookingTypeValue = bookingType
                if (location.state && location.state.params && location.state.params.bookingType) {
                    bookingTypeValue = location.state.params.bookingType;
                }
                let bookingFlightData = bookingTypeValue == "Inbound" ? selectedFlight['mappedInBoundFlights'] : selectedFlight['mappedOutBoundFlights'];
                if (bookingFlightData) {
                    if (traceId && bookingFlightData['ResultIndex']) {
                        const {data, success} = await dispatch(
                            flightsFearQuoteFxn({
                                traceId: traceId,
                                resultIndex: bookingFlightData['ResultIndex']
                            })
                        );
                        events.getBookSeatsUrl({
                            traceId: traceId,
                            resultIndex: bookingFlightData['ResultIndex']
                        })
                        if (success) {
                            events.checkOutboundPriceDifferent(data);
                        }
                    }
                }
            } else {
                if (traceId && selectedFlight['ResultIndex']) {
                    const {data, success} = await dispatch(flightsFearQuoteFxn({
                        traceId: traceId,
                        resultIndex: selectedFlight['ResultIndex']
                    }));
                    events.getBookSeatsUrl({
                        traceId: traceId,
                        resultIndex: selectedFlight['ResultIndex']
                    })
                    if (success) {
                        events.checkPriceDifferent(data);
                    }
                }
            }
        },
        checkPriceDifferent: (data) => {
            let {Fare: quoteFare} = data;
            if (quoteFare['PublishedFare'] == quoteFare['PublishedFare'] && quoteFare['OfferedFare'] == quoteFare['OfferedFare']) {
                setFareObject(data);
            } else {
                dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data})
                setFareState({
                    oldFare: quoteFare,
                    newFare: quoteFare,
                    quoteFare: data,
                    visible: true
                })
            }
        },
        getBookSeatsUrl: async (obj) => {
            const {data} = await dispatch(bookSeatsFxn(obj));
            setSsrResponse(data);
        },
        checkOutboundPriceDifferent: (data) => {
            let {Fare: quoteFare} = data;
            if (quoteFare['PublishedFare'] == quoteFare['PublishedFare'] && quoteFare['OfferedFare'] == quoteFare['OfferedFare']) {
                setOutboundFareObj(data);
            } else {
                dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data})
                /*    setOutboundFareState({
                        oldFare: quoteFare,
                        newFare: quoteFare,
                        quoteFare: data,
                        visible: true
                    })*/
            }
        },
        closeChangePriceModal: (data) => {
            setFareState({
                oldFare: {},
                newFare: {},
                quoteFare: {},
                visible: false,
            });
        },
        submitChangePriceModal: (data) => {
            setFareObject(data);
            dispatch({type: "UPDATE_FARE_QUOTE", fareQuoteObj: data});
            setFareState({
                oldFare: {},
                newFare: {},
                quoteFare: {},
                visible: false,
            });
        },
        addPassenger: () => {
            setActiveTab(2)
        },

    }


    return (
        <div key={ssrResponse}>
            {flightObj.journeyType == "2" ?
                <>
                    {flightBookingType == "overseasFlight" ?
                        <OverseasBooking flightObj={flightObj} traceId={traceId} selectedFlight={selectedFlight}
                                         fareObject={fareObject}
                                         bookingType={bookingType}
                                         ssrResponse={ssrResponse}
                                         flightBookingType={flightBookingType}
                                         fareQuoteObj={fareQuoteObj}/> :
                        <RoundTripBooking flightObj={flightObj} traceId={traceId} selectedFlight={selectedFlight}
                                          fareObject={fareObject}
                                          ssrResponse={ssrResponse}
                                          bookingType={bookingType}
                                          flightBookingType={flightBookingType}
                                          fareQuoteObj={fareQuoteObj}/>}
                </> : null}
            {flightObj.journeyType == "1" ?
                <OneWayBooking flightObj={flightObj} traceId={traceId} selectedFlight={selectedFlight}
                               fareObject={fareObject}
                               ssrResponse={ssrResponse}
                               flightBookingType={flightBookingType}
                               fareQuoteObj={fareQuoteObj}/> : null}
            <RegisterBanner/>
            {fareState.visible ?
                <ChangePrice {...fareState}
                             onSubmit={events.submitChangePriceModal}
                             onClose={events.closeChangePriceModal}/> : null}
        </div>
    );
}

export default Checkout
