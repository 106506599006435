import React from "react";
import AddMarkup from "../../components/addMarkup/index";

const Dashboard = () => {
    return (
        <div className="row row-gap-3">
            <div className="col-lg-12">
                <div className="find-flight-main">
                    <div className="passenger-details">
                         <AddMarkup/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
