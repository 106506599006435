import React from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import Footer from "../footer";
import LoginHeader from "./loginHeader";
import SideMenu from "./sideMenu";

const InnerLayout = (props) => {
    let {currentUser} = props;

    return (
        currentUser && currentUser._id ? <>
                <LoginHeader  {...props}/>
                <main className="flex-shrink-0">
                    <div className="dashboard-box d-flex gap-3">
                        <SideMenu/>
                        <div className="spacing-80 container">
                            <Outlet/>
                        </div>
                    </div>
                </main>
                {/*<Footer/>*/}
            </> :
            <Navigate replace to="/login"/>
    );
};

export default InnerLayout;
