import React, {useEffect, useState} from "react";
import {CabinObject, CabinTypeOptions} from "../../../components/utils/appUtils";
import {Modal, Tooltip} from "../../../components/Elements";
import {fareTypeList} from "../../../components/utils/appUtils";

const PassengersGridComponent = (props) => {
    let {name, message, onChange, value} = props;
    const events = {
        increaseFxn: () => {
            if (value !== undefined && parseFloat(value) < 10) {
                onChange(parseFloat(value) + 1);
            }
        },
        decreaseFxn: () => {
            if (value && parseFloat(value) > 0) {
                if (name == "Adult") {
                    if (value > 1) {
                        onChange((value ? parseFloat(value) : 1) - 1);
                    }
                } else {
                    onChange((value ? parseFloat(value) : 1) - 1);
                }
            }
        }
    }
    return (
        <>
            <div className="grid-box">
                <h5>
                    <span>{name}</span> {message}
                </h5>
                <div className="input-box">
                    <div className="qty-input">
                        <a
                            className="qty-count qty-count--minus"
                            data-action="minus" onClick={events.decreaseFxn}>
                            -
                        </a>
                        {/*<input
                            className="product-qty"
                            type="number"
                            name="product-qty"
                            value={value}
                            min={0}
                            max={10}
                            defaultValue={1}
                        />*/}
                        <div className="product-qty" style={{width: 50, textAlign: "center"}}>
                            {value}
                        </div>
                        <a
                            className="qty-count qty-count--add"
                            data-action="add"
                            onClick={events.increaseFxn}>
                            +
                        </a>
                    </div>
                </div>
            </div>

        </>
    )
}
const CabinComponent = (props) => {
    let {onChange, state} = props;
    let [cabinState, setCabinState] = useState({
        cabinClass: state.cabinClass
    })
    let [visible, setVisible] = useState(false)
    const events = {
        _updateCabinState: (data) => {
            setCabinState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        submitCabinState: () => {
            onChange(cabinState)
            setVisible(false)
        },
        onChooseCabin: (data) => {
            events._updateCabinState(data)
        }
    }
    useEffect(() => {
        onChange(cabinState)
    }, [cabinState])
    return (
        <>
            <div className="cabin-box">
                <div className="nav-item dropdown custom-dropdown">
                    <a className="nav-link d-flex align-items-center gap-2 dropdown-toggle  form-control"
                       href="#"
                       role="button"
                       data-bs-toggle="dropdown"
                       aria-expanded="false">
                        <img src={'../images2/cabin.png'} className={'cabin-icon'}/>
                        {CabinObject[state.cabinClass]}
                    </a>
                    <div className="dropdown-menu manage-box">
                        <div className={'cabin-type-modal'}>
                            <div className={'custom_title'}>Cabin Type</div>

                            <div className="cabin-option">
                                <ul>
                                    {CabinTypeOptions.map((item) => {
                                        return (
                                            <li key={item.display}>
                                                <div className="box">
                                                    <input
                                                        type="radio"
                                                        value={item.id}
                                                        checked={item.id == cabinState.cabinClass}
                                                        name="cabin-class" onChange={() => {
                                                        events.onChooseCabin({cabinClass: item.id})
                                                    }}/>
                                                    <label>{item.display}</label>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <input
                    type="text"
                    defaultValue="Cabin Class"
                    value={CabinObject[state.cabinClass]}
                    className="form-control cursor-pointer"
                    readOnly={true}
                    onClick={() => setVisible(true)}
                />*/}
            </div>
            {visible ?
                <Modal
                    visible={visible}
                    width={"28%"}
                    onClose={() => setVisible(false)}
                    title={'Cabin Type'}>
                    <div className={'cabin-type-modal'}>
                        <div className="cabin-option">
                            <ul>
                                {CabinTypeOptions.map((item) => {
                                    return (
                                        <li key={item.display}>
                                            <div className="box">
                                                <input
                                                    type="radio"
                                                    value={item.id}
                                                    checked={item.id == cabinState.cabinClass}
                                                    name="cabin-class" onChange={() => {
                                                    events._updateCabinState({cabinClass: item.id})
                                                }}/>
                                                <label>{item.display}</label>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>

                        <div className="modal-footer p-0 border-0">
                            <a onClick={() => {
                                events.submitCabinState()
                            }}
                               className="btn btn-primary w-100 m-0">
                                Update
                            </a>
                        </div>
                    </div>
                </Modal> : null}
        </>
    )
}
const PassengerComponent = (props) => {
    let {onChange, state} = props;
    let [cabinState, setCabinState] = useState({
        childCount: state.childCount,
        adultCount: state.adultCount,
        infantCount: state.infantCount
    })
    let [passengerVisible, setPassengerVisible] = useState(false)
    let [value, setValue] = useState("")
    const events = {
        _updateCabinState: (data) => {
            setCabinState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        submitPassengerState: () => {
            onChange(cabinState)
            setPassengerVisible(false)
        }
    }

    useEffect(() => {
        onChange(cabinState)
    }, [cabinState])

    useEffect(() => {
        let valueD = []
        if (state.adultCount) {
            valueD.push(`${state.adultCount} Adult(s)`)
        }
        if (state.childCount) {
            valueD.push(`${state.childCount} Child(s)`)
        }
        if (state.infantCount) {
            valueD.push(`${state.infantCount} Infant(s)`)
        }
        setValue(valueD.join(', '))
    }, [state])
    return (
        <>
            <div className="cabin-box">
                <div className="nav-item dropdown custom-dropdown">
                    <a className="nav-link d-flex align-items-center gap-2 dropdown-toggle  form-control"
                       href="#"
                       role="button"
                       data-bs-toggle="dropdown"
                       aria-expanded="false">
                        <img src={'../../images/user.png'} className={'passenger-icon'}/>
                        {value}
                    </a>
                    <div className="dropdown-menu manage-box" id={'passenger-box'}
                         onClick={(e) => {
                             e.stopPropagation();
                         }}>
                        <div className={'cabin-type-modal'}>
                            <div className={'custom_title'}>Passenger's</div>

                            <div className="choose-passanger">
                                <PassengersGridComponent name={'Adult'}
                                                         value={cabinState.adultCount}
                                                         message={"12+ Years"} onChange={(value) => {
                                    events._updateCabinState({adultCount: value})
                                }}/>
                                <PassengersGridComponent name={'Children'}
                                                         value={cabinState.childCount}
                                                         message={"2-12 Yrs"} onChange={(value) => {
                                    events._updateCabinState({childCount: value})
                                }}/>
                                <PassengersGridComponent name={'Seat Infant'}
                                                         value={cabinState.infantCount}
                                                         message={"< 2 Yrs"} onChange={(value) => {
                                    events._updateCabinState({infantCount: value})
                                }}/>

                            </div>
                            {/*<div className="modal-footer p-0 border-0">
                                <a onClick={() => {
                                    events.submitPassengerState()
                                }}
                                   className="btn btn-primary w-100 m-0">
                                    Update
                                </a>
                            </div>*/}
                        </div>
                    </div>
                </div>

            </div>
            {/*  <div className="cabin-box">
                <input
                    type="text"
                    defaultValue="Cabin Class"
                    className="form-control cursor-pointer"
                    readOnly={true}
                    value={value}
                    onClick={() => setPassengerVisible(true)}
                />
            </div>*/}
            {passengerVisible ?
                <Modal
                    visible={passengerVisible}
                    width={"28%"}
                    onClose={() => setPassengerVisible(false)}
                    title={"Passenger's"}>
                    <div className={'cabin-type-modal'}>
                        <div className="choose-passanger">
                            <PassengersGridComponent name={'Adult'}
                                                     value={cabinState.adultCount}
                                                     message={"12+ Years"} onChange={(value) => {
                                events._updateCabinState({adultCount: value})
                            }}/>
                            <PassengersGridComponent name={'Children'}
                                                     value={cabinState.childCount}
                                                     message={"2-12 Yrs"} onChange={(value) => {
                                events._updateCabinState({childCount: value})
                            }}/>
                            <PassengersGridComponent name={'Seat Infant'}
                                                     value={cabinState.infantCount}
                                                     message={"< 2 Yrs"} onChange={(value) => {
                                events._updateCabinState({infantCount: value})
                            }}/>

                        </div>
                        <div className="modal-footer p-0 border-0">
                            <a onClick={() => {
                                events.submitPassengerState()
                            }}
                               className="btn btn-primary w-100 m-0">
                                Update
                            </a>
                        </div>
                    </div>
                </Modal> : null}

        </>
    )
}
const FareTypesComponent = (props) => {
    let {value, onClick} = props;
    return (
        <>
            <div className="radio-box  justify-content-center">
                <ul>
                    {fareTypeList.map((item) => {
                        let compLi = (
                            <>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    value={item.value}
                                    checked={item.value == value}
                                    name="flexRadioDefault2"
                                    id="Direct-Flight"/>
                                <span/>
                                <label>{item.name}</label>
                            </>
                        )
                        return (
                            <li onClick={() => onClick(item.value)}>
                                {item.note ?
                                    <Tooltip title={item.note}>
                                        {compLi}
                                    </Tooltip> : compLi}
                            </li>
                        )
                    })}


                    {/*        <li>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="Direct-Flight"
                        />
                        <span/>
                        <label>Direct Flight</label>
                    </li>
                    <li>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="Regular-Fares"
                        />
                        <span/>
                        <label>Regular Fares</label>
                    </li>
                    <li>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="Student-Fares"
                        />
                        <span/>
                        <label>Student Fares</label>
                    </li>
                    <li>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault2"
                            id="Senior-Citizen-Fares"
                        />
                        <span/>
                        <label>Senior Citizen Fares</label>
                    </li>*/}
                </ul>
            </div>
        </>
    )
}
export {CabinComponent, PassengerComponent, FareTypesComponent}
