import React from "react"
import Tabs from "rc-tabs";
import "rc-tabs/assets/index.css";
import "./index.css";

const TabsComponent = (props) => {
    let {children, position = 'top', defaultTabKey = 0, onChange = {}, className = ''} = props;
    return (
        <Tabs tabPosition={position} defaultActiveKey={defaultTabKey} onChange={onChange} className={className}>
            {children}
        </Tabs>
    )
}

export default Tabs
// TabsComponent.TabPane = TabPane;
